import './MainContent.css'
import { sessionUserState } from '../hooks/SessionUserState'

import React from 'react'
import { useRecoilValue } from 'recoil'

const MainContent = (props) => {
  const userData = useRecoilValue(sessionUserState)

  let style = props.style ? props.style : {}
  if (!userData.personalSetting) {
    style.backgroundColor = "gray"
  }

  return (
    <div className={"MainContent " + props.className} style={{ ...style }}>
      {userData.personalSetting ? props.children : null}
    </div>
  )
}

export default MainContent