import CellInput from '../../common/CellInput'
import JpnDate from '../../common/JpnDate'
import ConstStatus from '../../common/ConstStatus'

import React, { useState } from 'react'

import 'react-responsive-tabs/styles.css'
import {
  CardContent,
  CardMedia,
  Tooltip
} from '@mui/material'

const ConstPanel = (props) => {
  const [inputTermFrom, setInputTermFrom] = useState(false)
  const [inputTermTo, setInputTermTo] = useState(false)

  if (!props.value || !props.value.constId) {
    return (<React.Fragment />)
  }

  const termFrom = props.quote.termFrom ? new Date(props.quote.termFrom).toLocaleDateString('sv-SE') : ""
  const termTo = props.quote.termTo ? new Date(props.quote.termTo).toLocaleDateString('sv-SE') : ""

  let imgPath = "/ConstManage/v1/constphotos/projectPhoto?constId=" + props.value.constId
  return (
    <div id={'ANCHOR-' + props.value.constId} className="ConstPanel" style={props.selected ? { ...props.style, backgroundColor: "#cfc" } : { ...props.style }}>
      <div className="ConstImageContainer">
        <CardMedia className="ConstImage"
          image={imgPath} alt="image"
          onClick={() => {
            if (props.onClickPhoto) {
              props.onClickPhoto(props.value.constId)
            }
          }} />
      </div>
      <div >
        <CardContent style={{ padding: "0px 2px 2px 10px", width: "400px", maxWidth: "400px" }}>
          <div className="ConstId">{props.value.constId}</div>
          <Tooltip title={props.value.name}>
            <div className="ConstName">{props.value.name}</div>
          </Tooltip>
          <hr style={{ marginTop: "2px", marginBottom: "4px" }} />
          <div className="ConstTerm">
            {inputTermFrom ?
              <CellInput id={"constTermFrom"} type="date" value={termFrom} style={{ backgroundColor: termFrom ? "transparent" : "red" }}
                onChange={(event) => {
                  props.onChangeQuote({ ...props.quote, termFrom: new Date(event.target.value) })
                }}
                onBlur={() => {
                  setInputTermFrom(false)
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setInputTermFrom(false)
                  }
                }}
              />
              : <JpnDate value={termFrom}
                required={true}
                style={{ color: "black", cursor: "pointer" }}
                onClick={() => {
                  setInputTermFrom(true)
                  setTimeout(() => {
                    document.getElementById("constTermFrom").focus()
                  }, 100)
                }}
              />
            }
            <p>　〜　</p>
            {inputTermTo ?
              <CellInput id={"constTermTo"} type="date" value={termTo} style={{ backgroundColor: termTo ? "transparent" : "red" }}
                onChange={(event) => {
                  props.onChangeQuote({ ...props.quote, termTo: new Date(event.target.value) })
                }}
                onBlur={() => {
                  setInputTermTo(false)
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setInputTermTo(false)
                  }
                }}
              />
              : <JpnDate value={termTo}
                required={true}
                style={{ color: "black", cursor: "pointer" }}
                onClick={() => {
                  setInputTermTo(true)
                  setTimeout(() => {
                    document.getElementById("constTermTo").focus()
                  }, 100)
                }} />
            }
          </div>
          <div style={{ display: "flex", marginTop: "5px" }}>
            <div className="SalesStaff">
              <div className="Label">営業担当</div>
              <div className="Name">{props.value.salesStaff}</div>
            </div>
            <div className="ConstructionStaff">
              <div className="Label">工事担当</div>
              <div className="Name">{props.value.staff}</div>
            </div>
          </div>
          <ConstStatus value={props.value.process} />
        </CardContent>
      </div>
    </div>
  )
}

export default ConstPanel
