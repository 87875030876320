const cache = new Map()

const createSVGBands = (startDate, endDate) => {

  const svgGenerator = (dataString) => {
    const svgWidth = dataString.length
    const svgHeight = 1
    console.log("svgGenerator", dataString)
    return (
      <svg xmlns="http://www.w3.org/2000/svg"
        width={svgWidth}
        height={svgHeight}
        preserveAspectRatio="none"
        style={{ width: "100%", height: "15px", marginTop: "5px" }}
        viewBox={"0 0 " + svgWidth + " " + svgHeight}>
        {dataString.split('').map((char, index) => (
          <rect
            key={index}
            x={index}
            y={0}
            width={1.5}
            height={1}
            fill={char === '1' ? '#a0a0ff' : 'transparent'}
          />
        ))}
      </svg>
    )
  }

  let fixed = svgGenerator("1")

  // startDateの月の１日目を作成
  const s = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
  const e = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)

  // データ処理部分は既存のロジックを流用
  let strs = new Map()

  // 月ごとのデータ文字列を生成
  for (let date = s; date <= e; date.setDate(date.getDate() + 1)) {
    let key = getYm(date)
    let str = strs.get(key) || ""
    str += isDateInRange(startDate, endDate, date) ? "1" : "0"
    strs.set(key, str)
  }

  // SVGに変換
  const svgs = new Map()
  for (let [key, flgs] of strs.entries()) {
    if (cache.get(flgs)) {
      svgs.set(key, cache.get(flgs))
    } else if (flgs.indexOf("0") < 0) {
      svgs.set(key, fixed)
      cache.set(flgs, fixed)
    } else if (flgs.indexOf("1") >= 0) {
      let s = svgGenerator(flgs)
      svgs.set(key, s)
      cache.set(flgs, s)
    }
  }

  return svgs
}

const isDateInRange = (startDate, endDate, targetDate) => {  // タイムスタンプを使用して正確に比較
  let result = targetDate.getTime() >= startDate.getTime() &&
    targetDate.getTime() <= endDate.getTime();
  return result
}

const getYm = (date) => {
  return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0')
}

export { createSVGBands }