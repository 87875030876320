const getTotal = (plan) => {
  let s = plan.filter((t) => {
    return t.leaf && !t.discount
  }).reduce((p1, p2) => {
    let vols = p1.vols

    p2.vols.forEach((v, k) => {
      let price = v.price ? v.price : 0
      if (p1.vols.has(k)) {
        vols.set(k, { price: price + p1.vols.get(k).price })
      } else {
        vols.set(k, { price: price })
      }
    })

    return {
      price: p1.price + p2.price,
      vols: vols
    }
  }, { price: 0, vols: new Map() })

  let total = {
    name: "合計",
    readOnly: true,
    price: s.price,
    vols: s.vols
  }

  return total
}
const getDiscount = (plan) => {
  let discountRow = plan.find((t) => t.leaf && t.discount === true)
  if (discountRow) {
    return { ...discountRow, readOnly: true }
  } else {
    return null
  }
}

/*
* 消費税額を算出する
*/
const getTaxedTotal = (plan) => {
  let taxMap = new Map()
  plan.filter((t) => t.leaf).forEach((p) => {
    let targetRate = taxMap.get(p.taxRate)
    if (!taxMap.has(p.taxRate)) {
      targetRate = { price: 0, vols: new Map() }
    }

    targetRate.price += p.price
    p.vols.forEach((v, k) => {
      let price = targetRate.vols.get(k) ? targetRate.vols.get(k).price : 0
      targetRate.vols.set(k, { price: (price ? price : 0) + v.price })
    })
    taxMap.set(p.taxRate, targetRate)
  })

  let t = { price: 0, vols: new Map() }

  taxMap.forEach((v, k) => {
    t.price += v.price * (k ? k : 0)

    v.vols.forEach((vv, vk) => {
      let volPrice = t.vols.get(vk)
      if (!volPrice) {
        volPrice = { price: 0 }
      }
      t.vols.set(vk, { price: volPrice.price + (vv.price * (k ? k : 0)) })
    })
  })

  t.price = Math.floor(t.price)
  t.vols.forEach((v, k) => {
    t.vols.set(k, { price: Math.floor(v.price) })
  })

  let taxRow = {
    name: "消費税",
    readOnly: true,
    price: t.price,
    vols: t.vols
  }

  return taxRow
}

const getResult = (result, total) => {
  console.log("getResult", total)
  let vols = new Map()
  if (!total) {
    return { ...result, readOnly: true }
  }

  result.price = total.price

  return {
    ...p(result),
    readOnly: true
  }
}

const sumSummary = (name, totalRow1, totalRow2) => {
  let t = { name: name, price: (totalRow1 ? totalRow1.price : 0) + (totalRow2 ? totalRow2.price : 0), vols: new Map() }

  if (totalRow1 && totalRow1.vols) {
    totalRow1.vols.forEach((v, k) => {
      let price = t.vols.get(k) ? t.vols.get(k).price : 0
      t.vols.set(k, { price: (price ? price : 0) + v.price })
    })
  }
  if (totalRow2 && totalRow2.vols) {
    totalRow2.vols.forEach((v, k) => {
      let price = t.vols.get(k) ? t.vols.get(k).price : 0
      t.vols.set(k, { price: (price ? price : 0) + v.price })
    })
  }

  return {
    ...t,
    readOnly: true
  }
}

/*
* トータル金額、月別金額から％を再計算する
*/
const p = (row) => {
  if (!row || !row.price) {
    return row
  }
  row.vols.forEach((v, k) => {
    v.vol = Math.round(v.price / row.price * 100)
  })
  return row
}

/*
* 金額を累計にする
*/
const r = (name, row) => {
  let p = 0
  let vols = new Map()

  row.vols.forEach((v, k) => {
    let price = (v.price ? v.price : 0) + p
    let vol = Math.floor(price / row.price * 100)
    vols.set(k, { vol: vol, price: price })

    p = price
  })

  return { name: name, readOnly: true, vols: vols, price: row.price }
}

/*
* 月別の金額から、合計金額を再計算する
*/
const t = (row) => {
  let price = 0
  row.vols.forEach((v, k) => {
    price += v.price ? v.price : 0
  })

  return { ...row, price: price }
}

const summary = (plan) => {
  if (!plan || !plan.details || !plan.details.length) {
    return []
  }
  let total = p(getTotal(plan.details));
  let discount = getDiscount(plan.details);
  let totalByDiscount = p(sumSummary("差引合計", total, discount));
  let taxTotal = p(getTaxedTotal(plan.details))
  let plusTaxTotal = { ...p(sumSummary("改計", totalByDiscount, taxTotal)), readOnly: true }
  let result = getResult(plan.result, total)

  return [
    total, discount, totalByDiscount, taxTotal, plusTaxTotal, result, r("ご請求累計", result)
  ].filter(data => data)
}

export { summary }
