import { isSmartPhone } from "../../common/Responsive"

const SESSION_KEY = "resultManage.listType"
const loadListType = () => {
  const ls = localStorage.getItem(SESSION_KEY)
  return ls ? ls : isSmartPhone() ? "list" : "grid"
}

const saveListType = (listType) => {
  localStorage.setItem(SESSION_KEY, listType)
}

export { loadListType, saveListType }
