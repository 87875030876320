import './WorkReportApprove.css'

import { ROLES } from './utils/Utils'

import Header from './common/Header'
import Footer from './common/Footer'
import Content from './common/Content'
import MainContent from './common/MainContent'
import WorkReport from './report/WorkReport'
import InfoBox from './common/InfoBox'
import DateTime from './common/DateTime'
import UserChoicer from './common/UserChoicer'
import UserName from './common/UserName'
import WorkReportHistory from './report/WorkReportHistory'
import CommentDialog from './common/CommentDialog'
import ConfirmationDialog from './common/ConfirmationDialog'
import CellInput from './common/CellInput'
import { MediaPc } from './common/Responsive'
import { formatDate, getInitialWorkRow, getInitialPersonalSetting, STATUS } from './utils/Utils'
import { MediaSmartPhone } from './common/Responsive'

import WorkReportService from '../services/WorkReportService'
import WorkApproveService from '../services/WorkApproveService'
import MeService from '../services/MeService'

import { sessionUserState } from './hooks/SessionUserState'
import { constListData } from './hooks/ConstListData'
import { userListData } from './hooks/UserListData'
import { errorState } from './hooks/ErrorState'

import React, { useState, useEffect, useMemo } from 'react'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { IconButton, Box, RadioGroup, Radio, FormControlLabel, Button, Checkbox } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import styled from '@emotion/styled'
import { Transition } from 'react-transition-group'

const fmt = (dt) => {
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    return `${y}-${m}-${d}`
}

const dateAdd = (dt, day) => {
    dt.setDate(dt.getDate() + day)
    return dt
}

const background = {
    approve: "#dfd",
    disapprove: "#fdd",
    hold: "#fff",
    none: "#fff"
}

const searchConditionState = atom({
    key: 'workApprove.searchCondition',
    default: { userId: '', start: fmt(dateAdd(new Date(), -2)), end: fmt(new Date()), targetOnly: false },
})

const WorkReportApprove = (props) => {
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
    const [workApproves, setWorkApproves] = useState([])
    const [condition, setCondition] = useRecoilState(searchConditionState)
    const [approveId, setApproveId] = useState(null)
    const [disapproveId, setDisapproveId] = useState(null)
    const [workReportBulkUpdate, setWorkReportBulkUpdate] = useState(false)
    const [personalSetting, setPersonalSetting] = useState(getInitialPersonalSetting());
    const [page, setPage] = useState("today")
    const constList = useRecoilValue(constListData) // 案件一覧データ（マップ構造）
    const userList = useRecoilValue(userListData) // アカウント一覧データ（マップ構造）
    const userData = useRecoilValue(sessionUserState)

    const initialCondition = useMemo(() => {
        return { userId: '', start: fmt(dateAdd(new Date(), -2)), end: fmt(new Date()), targetOnly: false }
    }, [])

    useEffect(() => {
        setCondition(initialCondition)
    }, [initialCondition]);

    const loadWorkApprove = (key) => {
        setWorkApproves([])
        Promise.all([
            WorkApproveService.load(key),
            MeService.findPersonalSetting(),
        ])
            .then(
                response => {
                    let personalSettingData = response[1].data ? response[1].data : getInitialPersonalSetting()
                    setPersonalSetting(personalSettingData)

                    let workApproves = response[0].data.objects
                    if (key.targetOnly) {
                        workApproves = workApproves.filter((r) => { return r.stat === 1 && r.currentApprover === personalSettingData.userId })
                    }

                    if (personalSettingData.bulkUpdate) {
                        workApproves = workApproves.map((row) => {
                            return { ...row, mode: row.stat === 1 && row.currentApprover === personalSettingData.userId ? "approve" : "none" }
                        })
                    }
                    setWorkApproves(workApproves)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const approveWorkApprove = (id, comment) => {
        WorkApproveService.approve(id, encodeURIComponent(comment))
            .then(
                response => {
                    loadWorkApprove(condition)
                    setApproveId(null)
                },
                error => {
                    props.onError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                props.onError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const disapproveWorkApprove = (id, comment) => {
        WorkApproveService.disapprove(id, encodeURIComponent(comment))
            .then(
                response => {
                    loadWorkApprove(condition)
                    setDisapproveId(null)
                },
                error => {
                    props.onError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                props.onError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    return (
        <Content role={ROLES.ADMINISTRATOR}>
            <Header title="業務日報承認" />
            <ConfirmationDialog
                dialogTitle="業務日報承認"
                bodyText={"業務日報を一括で承認／差戻しします。よろしいですか？"}
                open={workReportBulkUpdate}
                onOk={() => {
                    workApproves.forEach((row) => {
                        if (row.mode === "approve") {
                            approveWorkApprove(row._id, row.approvalComment ? row.approvalComment : "承認します。")
                        } else if (row.mode === "disapprove") {
                            disapproveWorkApprove(row._id, row.approvalComment ? row.approvalComment : "差戻しします。")
                        }
                        setWorkReportBulkUpdate(false)
                    })
                }}
                onCancel={() => { setWorkReportBulkUpdate(false) }}
                onClose={() => { setWorkReportBulkUpdate(false) }}
            />
            <CommentDialog open={approveId}
                dialogTitle="業務日報承認"
                bodyText={"業務日報を承認します。よろしいですか？"}
                onComment={(comment) => {
                    approveWorkApprove(approveId, comment)
                }}
                onClose={() => { setApproveId(null) }}
            />
            <CommentDialog open={disapproveId}
                dialogTitle="業務日報否認"
                bodyText={"業務日報を否認します。よろしいですか？"}
                onComment={(comment) => {
                    disapproveWorkApprove(disapproveId, comment)
                }}
                onClose={() => { setDisapproveId(null) }}
            />
            <MainContent className="WorkReportApprove">
                <SearchCondition value={condition} onChangeSearchCondition={(condition) => {
                    setCondition(condition)
                    loadWorkApprove(condition)
                }} />
                <div className="list">
                    {
                        workApproves.map((r) => {
                            if (r.stat === 1 && r.currentApprover !== userData.userId) {
                                let user = userList.get(r.currentApprover)
                                r.statusMessage = user?.name + "さんが承認処理中です。"
                            } else if (r.stat === 2) {
                                r.statusMessage = "全ての承認処理が完了済みです。"
                            }

                            return (
                                r.workReportId !== null && (r.stat === 0 || r.stat === 1 || r.stat === 2) ? (
                                    <React.Fragment>
                                        <WorkReportRow readOnly={r.currentApprover !== userData.userId} page={page} key={workApproves._id} bulkMode={personalSetting?.bulkUpdate} constList={constList} value={r}
                                            onError={(e) => {
                                                setError(e)
                                            }}
                                            onChangeComment={(value) => {
                                                let approves = workApproves.map((row) => {
                                                    if (r._id === row._id) {
                                                        row.approvalComment = value
                                                    }
                                                    return row
                                                })
                                                setWorkApproves(approves)
                                            }}
                                            onChangeChoice={(value) => {
                                                let approves = workApproves.map((row) => {
                                                    if (r._id === row._id) {
                                                        row.mode = value
                                                    }
                                                    return row
                                                })
                                                setWorkApproves(approves)
                                            }}
                                            onApprove={(target) => {
                                                setApproveId(target._id)
                                            }}
                                            onDisapprove={(target) => {
                                                setDisapproveId(target._id)
                                            }}
                                        />
                                        <hr style={{ border: "2px solid #0000", margin: "0px", width: "calc(100% - 20px)" }} />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div key={workApproves._id} style={{ position: "relative", margin: "10px", display: "flex", flexDirection: "row" }}>
                                            <div style={{ width: "100px", backgroundColor: "white" }} />
                                            <div variant="outlined" style={{ padding: "5px", position: "relative", width: "auto", height: "auto", backgroundColor: "white" }}>
                                                <div style={{ width: "100%", height: "200px", padding: "5px" }}>
                                                    <InfoBox title="氏名"><UserName userId={r.userId} /></InfoBox>
                                                    <InfoBox title="日付">{formatDate(r.date)}</InfoBox>
                                                    {r.statusMessage ? (
                                                        <div style={{ position: "absolute", display: "flex", justifyContent: "center", top: "50px", bottom: 0, left: 0, right: 0, fontSize: "30px", margin: "auto", backgroundColor: "rgba(255,255,255,0.5)" }}>
                                                            <div style={{ color: "darkblue", marginTop: "100px" }}>
                                                                {r.statusMessage}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <React.Fragment />
                                                    )}
                                                </div>
                                            </div>
                                        </div >
                                        <hr style={{ border: "2px solid #0000", margin: "0px", width: "calc(100% - 20px)" }} />
                                    </React.Fragment>
                                )
                            )
                        })
                    }
                </div>
            </MainContent>
            <Footer>
                <MediaSmartPhone>
                    <PrevArrow page={page} onClick={() => {
                        setPage("today")
                    }} />
                </MediaSmartPhone>
                {personalSetting.bulkUpdate ? (
                    <Box style={{ paddingRight: "10px" }}>
                        <Button variant="contained" color="primary" onClick={(event) => {
                            setWorkReportBulkUpdate(true)
                        }}><SaveAltIcon /><MediaPc>一括処理</MediaPc></Button>
                    </Box>
                ) : (
                    <React.Fragment />
                )}
                <MediaSmartPhone>
                    <NextArrow page={page} onClick={() => {
                        setPage("next")
                    }} />
                </MediaSmartPhone>
            </Footer>
        </Content >
    )
}

const SearchCondition = (props) => {
    const [searchCondition, setSearchCondition] = useState(props.value)
    return (
        < div style={{ backgroundColor: "white", padding: "5px" }}>
            <InfoBox title={"日付範囲"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <CellInput key={"start"} type="date" value={searchCondition.start}
                        onChange={(event) => {
                            setSearchCondition({ ...searchCondition, start: event.target.value })
                        }}
                    />
                    <span style={{ verticalAlign: "middle" }}>〜</span>
                    <CellInput key={"end"} type="date" value={searchCondition.end}
                        onChange={(event) => {
                            setSearchCondition({ ...searchCondition, end: event.target.value })
                        }}
                    />
                </div>
            </InfoBox>
            <InfoBox title={"社員"} style={{ width: "400px" }}>
                <UserChoicer value={searchCondition.userId}
                    onChoiceUser={(userId) => {
                        setSearchCondition({ ...searchCondition, userId: userId })
                    }} />
            </InfoBox>
            <Checkbox color="secondary" size="small" id="targetOnly" checked={searchCondition.targetOnly} inputProps={{ 'ariaLabel': 'controlled' }} onChange={(event) => {
                setSearchCondition({ ...searchCondition, targetOnly: event.target.checked })
            }} />
            <label for="targetOnly">承認対象データのみ</label>
            <Button
                style={{ marginLeft: "10px" }}
                variant="contained"
                color="inherit"
                onClick={() => {
                    props.onChangeSearchCondition(searchCondition)
                }} >
                検索
            </Button>
        </div >
    )
}

const WorkReportRow = (props) => {
    const [workReportColors, setWorkReportColors] = useState(new Map()) // 勤怠実績データ
    let [workReport, setWorkReport] = useState({
        times: [], dates: { today: null, next: null },
    })
    const [controlState, setControlState] = useState(false)
    const constList = props.constList // 案件一覧データ（マップ構造）
    const buildDisplayWorkReport = (data) => {
        let min = 9
        let max = 16
        let a = new Array(0)

        // 時刻の枠を計る為に一旦ループ
        a.concat(data.today ? data.today.rows : [])
            .concat(data.next ? data.next.rows : []).forEach((r) => {
                let s = r.time.split(":")
                min = Math.min(parseInt(s[0]), min)
                max = Math.max(parseInt(s[0]), max)
            })

        // 時刻の枠分、空のオブジェクトを用意
        let t = {}
        for (let idx = min; idx <= max; idx++) {
            t[idx] = getInitialWorkRow(idx)
        }

        // 当日実績の中身の反映
        if (data.today) {
            data.today.rows.forEach((r) => {
                let s = r.time.split(":")
                let hour = parseInt(s[0])
                t[hour].today.text = r.text
                t[hour].today.const = {
                    constId: r.constId,
                    name: constList.get(r.constId) ? constList.get(r.constId).name : ''
                }
            })
        }

        // 次の日の予定の中身の反映
        if (data.next) {
            data.next.rows.forEach((r) => {
                let s = r.time.split(":")
                let hour = parseInt(s[0])
                t[hour].next.text = r.text
                t[hour].next.const = {
                    constId: r.constId,
                    name: constList.get(r.constId) ? constList.get(r.constId).name : ''
                }
            })
        }

        t = Object.keys(t).map((key) => {
            return t[key]
        })

        let tmp = {
            todayId: data.today ? data.today._id : null,
            nextId: data.next ? data.next._id : null,
            accountId: data.today ? data.today.accountId : '',
            superiorId: data.today ? data.today.superiorId : '',    // TODO:ロードできない場合、デフォルトの上長をセットする必要有り
            status: data.today ? data.today.status : STATUS.EDITTING,
            sendDate: data.today ? data.today.sendDate : null,
            dates: {
                today: data.today ? new Date(data.today.date) : null,
                next: data.next ? new Date(data.next.date) : null
            },
            todayOvertime: data.today.overtime,
            nextOvertime: data.next.overtime,
            times: t
        }
        return tmp
    }

    const loadWorkReport = (id) => {
        WorkReportService.loadById(id)
            .then(
                response => {
                    let workReportData = buildDisplayWorkReport(response.data)
                    WorkReportService.colors({ userId: response.data.today.accountId, date: fmt(new Date(response.data.today.date)) })
                        .then(
                            response => {
                                setWorkReport(workReportData)
                                setWorkReportColors(buildDisplayColors(response.data))
                            },
                            error => {
                                props.onError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                            }
                        )
                },
                error => {
                    props.onError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                props.onError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const buildDisplayColors = (colors) => {
        let result = new Map()
        if (colors) {
            Object.keys(colors).forEach((r) => {
                let s = r.split(":")
                let hour = parseInt(s[0])

                let rec = result.get(hour)
                if (!rec) {
                    rec = null
                }

                rec = colors[r]
                result.set(hour, rec)
            })
        }
        return result
    }

    useEffect(() => {
        loadWorkReport(props.value.workReportId)
    }, [])

    const transitionStyles = {
        entering: { left: '0px', transition: 'all 100ms ease' },
        entered: { left: '0px' },
        exiting: { left: '-100%', transition: 'all 100ms ease' },
        exited: { left: '-100%' },
    }
    return (
        <div style={{ position: "relative", margin: "5px", display: "flex", flexDirection: "row" }}>
            <MediaPc>
                <div style={{ position: "relative", minHeight: "100%", display: "flex", alignItems: "center", width: "80px", minWidth: "80px", backgroundColor: background[props.value.mode] ? background[props.value.mode] : "#fff" }}>
                    <ApproveControl {...props} />
                </div>
            </MediaPc>
            <MediaSmartPhone>
                <Transition in={controlState} timeout={1500}>
                    {(state) => (
                        <div style={{ width: "100px", position: "absolute", display: "flex", justifyContent: "center", alignItems: "center", top: "0", bottom: "0", paddingTop: "auto", paddingBottom: "auto", backgroundColor: "rgba(255,255,255,0.9)", zIndex: 10, ...transitionStyles[state] }}>
                            <ApproveControl {...props} style={{ ...props.style }} />
                        </div>
                    )}
                </Transition>
            </MediaSmartPhone>

            <div className="outlinePanel" variant="outlined" style={{ backgroundColor: props.bulkMode ? background[props.value.mode] : "white" }}>
                <div className="submitDate">
                    <InfoBox title="提出日時">
                        <DateTime value={workReport.sendDate} />
                    </InfoBox>
                </div>
                <div style={{ position: "relative" }}>
                    <WorkReport colors={workReportColors} value={workReport} readOnly={true} approve={true} page={props.page}
                        onClick={() => {
                            setControlState(!controlState)
                        }}
                    />
                    <MediaSmartPhone>
                        <Pager />
                    </MediaSmartPhone>
                </div>
                <WorkReportHistory value={props.value} />
                {props.value.statusMessage ? (
                    <div style={{ position: "absolute", display: "flex", justifyContent: "center", top: "85px", left: 0, right: 0, bottom: 0, fontSize: "30px", margin: "auto", backgroundColor: "rgba(255,255,255,0.5)" }}>
                        <div style={{ color: "darkblue", marginTop: "100px" }}>
                            {props.value.statusMessage}
                        </div>
                    </div>
                ) : (
                    <React.Fragment />
                )}
                {props.bulkMode && !props.readOnly ? (
                    <div style={{ margin: "5px", backgroundColor: "lightgray", maxWidth: "600px", width: "calc(100% - 10px)" }}>
                        <div>コメント</div>
                        <CellInput line={5} value={props.value.approvalComment} style={{ margin: "5px", width: "calc(100% - 10px)" }} onChange={(event) => {
                            props.onChangeComment(event.target.value)
                        }} />
                    </div>
                ) : (
                    <React.Fragment />
                )}
            </div>
        </div >
    )
}

const Pager = (props) => {
    return (
        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, pointerEvents: "none" }}
            onClick={(event) => {
                props.onClick()
            }}
        />
    )
}

const ApproveControl = (props) => {
    return (
        props.bulkMode ? (
            <ApproveChoiceByBulk {...props}
                onChangeChoice={(value) => {
                    props.onChangeChoice(value)
                }}
            />
        ) : (
            <ApproveChoice {...props}
                onApprove={(target) => {
                    props.onApprove(target)
                }}
                onDisapprove={(target) => {
                    props.onDisapprove(target)
                }}
            />
        )
    )
}

const ApproveChoiceByBulk = (props) => {
    const userData = useRecoilValue(sessionUserState)
    const SmallRadio = styled(Radio)`
        svg {
            width: 0.7em;
            height: 0.7em;
        }
    `

    return (
        // <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "80px", minWidth: "80px", backgroundColor: background[props.value.mode], minHeight: "50px" }}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "80px", minWidth: "80px", minHeight: "50px" }}>
            {props.value.stat === 1 && props.value.currentApprover === userData.userId ? (
                <RadioGroup style={{ fontSize: "10px" }} defaultValue={props.value.mode}
                    onChange={(event) => {
                        props.onChangeChoice(event.target.value)
                        event.preventDefault()
                    }}>
                    <FormControlLabel value="hold" control={<SmallRadio />} label={<span style={{ fontSize: "12px" }} >保留</span>} />
                    <FormControlLabel value="approve" control={<SmallRadio />} label={<span style={{ fontSize: "12px" }} >承認</span>} />
                    <FormControlLabel value="disapprove" control={<SmallRadio />} label={<span style={{ fontSize: "12px" }} >差戻し</span>} />
                </RadioGroup>
            ) : (
                <React.Fragment />
            )}
        </div>
    )
}

const ApproveChoice = (props) => {
    const userData = useRecoilValue(sessionUserState)

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "80px", minWidth: "80px", backgroundColor: "white", minHeight: "50px" }}>
            {props.value.stat === 1 && props.value.currentApprover === userData.userId ? (
                <React.Fragment>
                    <Button style={{ display: "block" }} variant="contained" color="primary"
                        onClick={(e) => {
                            props.onApprove(props.value)
                            e.preventDefault()
                        }}
                    >承認</Button>
                    <div style={{ height: "5px" }} />
                    <Button style={{ display: "block" }} variant="contained" color="secondary"
                        onClick={(e) => {
                            props.onDisapprove(props.value)
                            e.preventDefault()
                        }}
                    >差戻し</Button>
                </React.Fragment>
            ) : (
                <React.Fragment />
            )}
        </div>
    )
}

const NextArrow = (props) => {
    return (
        <IconButton className={" Pager"} style={{ position: "absolute", display: (props.page === "today" ? "block" : "none"), width: "40px", top: "0px", right: "5px", color: "white" }} ariaLabel="edit"
            onClick={props.onClick}
        >
            <ArrowForwardIosIcon />
        </IconButton>
    )
}

const PrevArrow = (props) => {
    return (
        <IconButton className={" Pager"} style={{ position: "absolute", display: (props.page === "next" ? "block" : "none"), width: "40px", top: "0px", left: "5px", color: "white" }} ariaLabel="edit"
            onClick={props.onClick}
        >
            <ArrowBackIosIcon />
        </IconButton>
    )
}

export default WorkReportApprove
