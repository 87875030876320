import ConstSelector from '../common/ConstSelector'
import ConstIdDialog from '../common/ConstIdDialog'

import { constPhotoState } from '../hooks/ConstPhotoState'
import { errorState } from '../hooks/ErrorState'

import ConstPhotoService from '../../services/ConstPhotoService'

import React, { useState, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
const Const = (props) => {
    const [constId, setConstId] = useState('')
    const setData = useSetRecoilState(constPhotoState)
    const [constDialogOpen, setConstDialogOpen] = useState(true)  // 案件選択ダイアログの表示コントロール
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用

    const loadConstPhoto = (constId) => {
        ConstPhotoService.load(constId) // 該当工事の写真を全件取得
            .then(
                response => {
                    let data = { ...response.data }

                    let photos = data.allPhotos
                    // 表示用のデータ設定
                    data.allPhotos = {
                        ...data.allPhotos,
                        title: data.allPhotos.name,
                        window: true,
                        windowState: { x: 0, y: 0, width: 100, height: 100 },
                        key: "allPhotos"
                    }
                    data.categories = data.categories.map((row) => {
                        let rowData = {
                            ...row,
                            title: row.name,
                            key: "category-" + row.constCategory,
                            winddow: false,
                            windowState: { x: 0, y: 0, width: 100, height: 100 },
                        }
                        props.connector[rowData.key] = {}
                        return rowData
                    })
                    data.userAllocated = data.userAllocated.map((row) => {
                        let rowData = {
                            ...row,
                            title: row.name,
                            key: row.constCategory,
                            window: false,
                            windowState: { x: 0, y: 0, width: 100, height: 100 },
                            rename: true,
                            deletable: true
                        }
                        props.connector[rowData.key] = {}
                        return rowData
                    })

                    setData(data)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).then(
        ).catch(error => {
            setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
    }

    useEffect(() => {
        if (constId)
            loadConstPhoto(constId)
        else
            setData({ constId: '', allPhotos: { photos: [] }, categories: [], userAllocated: [] })
    }, [constId])

    return (
        <div className="Const">
            <div className="constCell today" style={{ display: "flex", flexDirection: "row", padding: "3px", width: "820px" }}>
                <span style={{ lineHeight: "26px", verticalAlign: "middle" }}>工事番号</span>
                <div style={{ marginLeft: "5px" }}>
                    <ConstSelector value={constId}
                        onChangeConstId={(constId) => {
                            setConstId(constId)
                        }} />
                </div>
                <ConstIdDialog open={constDialogOpen}
                    onChoice={(project) => {
                        setConstId(project.constId)
                        setConstDialogOpen(false)
                    }}
                    onClose={() => {
                        setConstDialogOpen(false)
                    }}
                />
            </div>
        </div>
    )
}

export default Const
