import { http } from "./Config"

const requestPath = "/v1/laborcosts"


const get = (constId) => {
  return http.get(`${requestPath}/?constId=${constId}`)
}

const getAll = (fiscalYear) => {
  return http.get(`${requestPath}/all?fiscalYear=${fiscalYear}`)
}

export const save = (data, constId) => {
  return http.post(`${requestPath}/?constId=${constId}`, data, {
    headers: {
      "content-type": "application/json",
    },
  })
}

const exports = {
  get,
  getAll,
  save,
}

export default exports
