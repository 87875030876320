import './ReportBody.css'
import ConstReport from './ConstReport'
import ConstReport2 from './ConstReport2'
import ConstReportComments from './ConstReportComments'

import WorkReport from './WorkReport'
import WorkReportHistory from './WorkReportHistory'
import WorkReportService from '../../services/WorkReportService'
import WorkApproveService from '../../services/WorkApproveService'
import ConstReportService from '../../services/ConstReportService'
import ConstReportService2 from '../../services/ConstReportService2'
import ConstDiscussionService from '../../services/ConstDiscussionService'

import CameraService from '../../services/CameraService'
import PdfService from '../../services/PdfService'

import { getInitialWorkReport, getInitialWorkRow, getInitialConstReport, convMongoIsoDate, STATUS } from '../utils/Utils'
import { constListData } from '../hooks/ConstListData'

import { constReportPrintState } from '../hooks/ConstReportPrintState'
import { constReport2PrintState } from '../hooks/ConstReport2PrintState'
import { constReportSaveState } from '../hooks/ConstReportSaveState'
import { constReportSentState } from '../hooks/ConstReportSentState'
import { constReportCheckState } from '../hooks/ConstReportCheckState'

import { kyReportPrintState } from '../hooks/KyReportPrintState'

import { workReportPrintState } from '../hooks/WorkReportPrintState'
import { workReportSaveState } from '../hooks/WorkReportSaveState'
import { workReportSentState } from '../hooks/WorkReportSentState'
import { workReportCheckState } from '../hooks/WorkReportCheckState'
import { sessionUserState } from '../hooks/SessionUserState'

import { specialFooterState, WORK_REPORT, CONST_REPORT } from '../hooks/SpecialFooterState'
import { reportKeyDataState } from '../hooks/ReportKeyDataState'

import DateInputDialog from '../common/DateInputDialog'
import ConstIdDialog from '../common/ConstIdDialog'
import ConfirmationDialog from '../common/ConfirmationDialog'
import CommentDialog from '../common/CommentDialog'
import { errorState } from '../hooks/ErrorState'
import { messageState } from '../hooks/MessageState'

import 'react-responsive-tabs/styles.css'
import React, { useState, useEffect } from 'react'
import { Tabs, Tab } from '@mui/material'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import DataListInput from 'react-datalist-input'

const ReportBody = (props) => {
    const [keyData, setKeyData] = useRecoilState(reportKeyDataState)   // データのキー項目を保持

    const [workReport, setWorkReport] = useState(null) // 業務日報データ
    const [workReportColors, setWorkReportColors] = useState(new Map()) // 勤怠実績データ
    const [workReportHistory, setWorkReportHistory] = useState(null) // 業務日報承認履歴
    const [workReportSave, setWorkReportSave] = useRecoilState(workReportSaveState)  // 業務日報の一時保存
    const [workReportPrint, setWorkReportPrint] = useRecoilState(workReportPrintState) // 業務日報の印刷
    const [workReportCheck, setWorkReportCheck] = useRecoilState(workReportCheckState)  // 業務日報のチェック
    const [workReportSend, setWorkReportSend] = useRecoilState(workReportSentState)  // 業務日報の提出
    const setFooterState = useSetRecoilState(specialFooterState)

    const [constReport, setConstReport] = useState(null) // 工事日報データ
    const [constReportCheck, setConstReportCheck] = useRecoilState(constReportCheckState) // 工事日報のチェック
    const [constReportSave, setConstReportSave] = useRecoilState(constReportSaveState) // 工事日報の一時保存
    const [constReportPrint, setConstReportPrint] = useRecoilState(constReportPrintState) // 工事日報の印刷
    const [constReport2Print, setConstReport2Print] = useRecoilState(constReport2PrintState) // 工事日報(Ver.2)の印刷
    const [constReportSend, setConstReportSend] = useRecoilState(constReportSentState)  // 工事日報の提出
    const [kyReportPrint, setKyReportPrint] = useRecoilState(kyReportPrintState) // 本日のKY活動の印刷
    const [constReportDiscussion, setConstReportDiscussion] = useState(null) // 工事日報ディスカッションデータ

    const constList = useRecoilValue(constListData) // 案件一覧データ（マップ構造）
    const [tabIndex, setTabIndex] = useState(0) // タブの切り替え

    const [dateDialogOpen, setDateDialogOpen] = useState(keyData.dates.today === null)  // 日付入力ダイアログの表示コントロール
    const [constDateDialogOpen, setConstDateDialogOpen] = useState(false)  // 日付入力（工事日報側）ダイアログの表示コントロール
    const [constDialogOpen, setConstDialogOpen] = useState(false)  // 案件選択ダイアログの表示コントロール
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
    const setMessage = useSetRecoilState(messageState) // メッセージ表示用
    const userState = useRecoilValue(sessionUserState)

    // 業務日報の表示モード（編集／読み込みのみ）を決定する。提出前 or 否認 かつ 編集者が自分の時のみ編集可能
    let workReportReadOnly = (workReport?.status !== STATUS.EDITTING && workReport?.status !== STATUS.DISAPPROVAL) || (workReport?.accountId !== userState.userId)

    // 工事日報の表示モード（編集／読み込みのみ）を決定する。編集者かメンバーに自分が含まれている時のみ編集可能
    let constReportReadOnly = (constReport?.accountId !== userState.userId)
        && (constReport?.version != 2 && constReport?.members && constReport?.members.findIndex((member) => { return member.userId === userState.userId }) < 0)

    let constReportReadOnly2 = false

    const displayMessage = (title, msg) => {
        setMessage({ msg: msg, open: true, title: title })
    }

    const resetFooter = () => {
        let fs = {}

        if (keyData.mode === "workReport") {
            fs = { mode: WORK_REPORT, readOnly: workReportReadOnly }
        } else if (keyData.mode === "constReport") {
            fs = {
                mode: CONST_REPORT,
                version: constReport ? constReport.version : null,
                readOnly: (constReport && constReport.version) !== 2 ? constReportReadOnly : constReportReadOnly2,
                status: (constReport && constReport?.version === 2) ? constReport.status : null
            }
        } else if (tabIndex === 0) {
            fs = { mode: WORK_REPORT, readOnly: workReportReadOnly }
        } else if (tabIndex === 1) {
            fs = {
                mode: CONST_REPORT,
                version: constReport ? constReport.version : null,
                readOnly: (constReport && constReport.version !== 2) ? constReportReadOnly : constReportReadOnly2,
                status: (constReport && constReport?.version === 2) ? constReport.status : null
            }
        }

        setFooterState(fs)
    }

    useEffect(() => {
        resetFooter()
    }, [tabIndex, workReport, constReport])

    useEffect(() => {
        if (workReportCheck) {
            checkWorkReport(workReport, STATUS.HOLDING)
        }
    }, [workReportCheck])

    useEffect(() => {
        if (constReportCheck) {
            checkConstReport(constReport, STATUS.HOLDING)
        }
    }, [constReportCheck])

    useEffect(() => {
        if (workReportPrint) {
            downloadPdfWorkReport(workReport, "", STATUS.EDITTING)
            setWorkReportPrint(null)
        }
    }, [workReportPrint])

    useEffect(() => {
        if (constReportPrint) {
            downloadPdfConstReport(constReport, "", STATUS.EDITTING)
            setConstReportPrint(null)
        }
    }, [constReportPrint])

    useEffect(() => {
        if (constReport2Print) {
            downloadPdfConstReport2(constReport, "")
            setConstReport2Print(null)
        }
    }, [constReport2Print])

    useEffect(() => {
        if (kyReportPrint) {
            downloadPdfKyReport(constReport, "", STATUS.EDITTING)
            setKyReportPrint(null)
        }
    }, [kyReportPrint])

    useEffect(() => {
        if (!keyData.userId) {
            return
        }

        // 業務日報（日付が変更されている場合のみ取り直す）
        if (keyData.dates.today
            && (keyData.dates.today !== workReport?.dates.today)) {
            loadWorkReport(keyData)
        } else {
            setWorkReport(getInitialWorkReport(9, 8))
        }

        // 工事日報（日付か工番が変更されている場合のみ取り直す）
        if (keyData.constDates.today
            && keyData.constId) {
            loadConstReport(keyData)
        } else {
            setConstReport({ constId: '', today: getInitialConstReport(''), next: getInitialConstReport(''), members: [], photos: [], dates: {} })
        }
    }, [keyData])

    useEffect(() => {
        setTabIndex(0)
    }, [keyData.userId, keyData.dates.today])

    useEffect(() => {
        setDateDialogOpen(keyData.dates.today === null)
        setConstDialogOpen(!keyData.constId && tabIndex === 1)
    }, [keyData, tabIndex])

    useEffect(() => {
        if (((!keyData.mode && tabIndex === 1) || (keyData.mode === "constReport")) && constReport && constReport.version === 2 && constReport._id) {
            checkUpdate(constReport, userState.userId)
        }
    }, [keyData, tabIndex, constReport, userState])

    // 既読チェックを行う
    const checkUpdate = (constReport, userId) => {
        const c = { ...constReport }
        let f = false

        if (!c.members.isCheckDirector && c.members.director === userId) {
            c.members.isCheckDirector = true
            f = true
        }
        if (!c.members.isCheckManager && c.members.manager === userId) {
            c.members.isCheckManager = true
            f = true
        }
        if (!c.members.isCheckSupervisor && c.members.supervisor === userId) {
            c.members.isCheckSupervisor = true
            f = true
        }
        if (!c.members.isCheckWorker && c.members.worker === userId) {
            c.members.isCheckWorker = true
            f = true
        }
        if (f) {
            ConstReportService2.check(c._id)
            setConstReport(c)
        }
    }


    const contents = []

    if (!keyData.mode || keyData.mode === "workReport") {
        contents.push(
            <div key={props.key} style={{ backgroundColor: 'white' }}>
                <WorkReport
                    key={props.key + "-workReport"}
                    value={workReport}
                    colors={workReportColors}
                    readOnly={workReportReadOnly}
                    page={props.workReportPage}
                    onChoiceDate={(event) => {
                        setDateDialogOpen(true)
                    }}
                    onChangeWorkReport={(v) => {
                        setWorkReport(v)
                    }}
                >
                    {workReportHistory ? (<WorkReportHistory value={workReportHistory} />) : (<React.Fragment />)}
                </WorkReport>
            </div>

        )
    }

    if ((!keyData.mode || keyData.mode === "constReport") && constReport && constReport.version === 1) {
        contents.push(
            <div style={{ backgroundColor: 'white' }}>
                <ConstReport
                    key={props.key + "-constReport"}
                    value={constReport}
                    readOnly={constReportReadOnly}
                    page={props.constReportPage}
                    onConstIdSelect={(v) => {
                        setConstDialogOpen(v)
                    }}
                    onChoiceDate={(event) => {
                        setConstDateDialogOpen(true)
                    }}
                    onChangeConstReport={(v) => {
                        setConstReport(v)
                    }}
                    onChangeConstId={(constId) => {
                        setKeyData({ ...keyData, constId: constId })
                    }}
                >
                    {constReportDiscussion ? (<ConstReportComments initialValue={constReportDiscussion} />) : (<React.Fragment />)}
                </ConstReport>
            </div>
        )
    }

    if ((!keyData.mode || keyData.mode === "constReport") && constReport && constReport.version === 2) {
        contents.push(
            <ConstReport2
                key={props.key + "-constReport2"}
                value={constReport}
                readOnly={constReportReadOnly2}
                page={props.constReportPage}
                onConstIdSelect={(v) => {
                    setConstDialogOpen(v)
                }}
                onChoiceDate={(event) => {
                    setConstDateDialogOpen(true)
                }}
                onChangeConstReport={(v) => {
                    setConstReport(v)
                }}
                onChangeConstId={(constId) => {
                    setKeyData({ ...keyData, constId: constId })
                }}
            />
        )
    }

    //
    // 業務日報関連の関数定義
    //
    const buildSaveTodayWorkReport = (data, mode) => {
        let d = {
            _id: data.todayId,
            date: data.dates.today,
            next: data.dates.next,
            accountId: keyData.userId,
            superiorId: data.superiorId,
            sendDate: data.sendDate,
            overtime: data.todayOvertime,
            status: mode === STATUS.EDITTING ? data.status : mode,  // 編集中なら現在のstatusを温存する
            rows: []
        }

        // 初の提出の時、提出日時をセット
        if (data.status === STATUS.EDITTING && mode !== STATUS.EDITTING) {
            d.sendDate = new Date()
        }

        data.times.forEach((r, idx) => {
            const o = r.today
            d.rows.push(
                {
                    time: ('000' + r.time).slice(-2) + ":00",
                    text: o.text,
                    constId: o.const ? o.const.constId : null,
                }
            )
        })

        return d
    }

    const buildSaveNextWorkReport = (data) => {
        let d = {
            _id: data.nextId,
            accountId: keyData.userId,
            date: data.dates.next,
            overtime: data.nextOvertime,
            rows: []
        }

        data.times.forEach((r, idx) => {
            const o = r.next
            d.rows.push(
                {
                    time: ('000' + r.time).slice(-2) + ":00",
                    text: o.text,
                    constId: o.const ? o.const.constId : null,
                }
            )
        })

        return d
    }

    const buildDisplayColors = (colors) => {
        let result = new Map()
        if (colors) {
            Object.keys(colors).forEach((r) => {
                let s = r.split(":")
                let hour = parseInt(s[0])

                let rec = result.get(hour)
                if (!rec) {
                    rec = null
                }

                rec = colors[r]
                result.set(hour, rec)
            })
        }
        return result
    }

    const buildDisplayWorkReport = (data, colors, dates) => {
        let min = 9
        let max = 16
        let a = new Array(0)

        // 時刻の枠を計る為に一旦ループ
        a.concat(data.today ? data.today.rows : [])
            .concat(data.next ? data.next.rows : []).forEach((r) => {
                let s = r.time.split(":")
                min = Math.min(parseInt(s[0]), min)
                max = Math.max(parseInt(s[0]), max)
            })

        // 勤怠の時間枠も計算
        Object.keys(colors).forEach((key) => {
            let s = key.split(":")
            min = Math.min(parseInt(s[0]), min)
            max = Math.max(parseInt(s[0]), max)
        })

        // 時刻の枠分、空のオブジェクトを用意
        let t = {}
        for (let idx = min; idx <= max; idx++) {
            t[idx] = getInitialWorkRow(idx)
        }

        // 当日実績の中身の反映
        if (data.today) {
            data.today.rows.forEach((r) => {
                let s = r.time.split(":")
                let hour = parseInt(s[0])
                t[hour].today.text = r.text
                t[hour].today.const = {
                    constId: r.constId,
                    name: constList.get(r.constId) ? constList.get(r.constId).name : ''
                }
            })
        }

        // 次の日の予定の中身の反映
        if (data.next) {
            data.next.rows.forEach((r) => {
                let s = r.time.split(":")
                let hour = parseInt(s[0])
                t[hour].next.text = r.text
                t[hour].next.const = {
                    constId: r.constId,
                    name: constList.get(r.constId) ? constList.get(r.constId).name : ''
                }
            })
        }

        t = Object.keys(t).map((key) => {
            return t[key]
        })

        let tmp = {
            todayId: data.today ? data.today._id : null,
            nextId: data.next ? data.next._id : null,
            accountId: data.today.accountId,
            superiorId: data.today.superiorId,
            status: data.today ? data.today.status : STATUS.EDITTING,
            sendDate: data.today ? data.today.sendDate : null,
            dates: {
                today: data.today.date,
                next: data.next.date
            },
            todayOvertime: data.today.overtime,
            nextOvertime: data.next.overtime,
            times: t
        }

        return tmp
    }

    const loadWorkReport = (keyData) => {
        Promise.all([
            WorkReportService.load({ userId: keyData.userId, today: fmt(keyData.dates.today), next: fmt(keyData.dates.next) }),
            WorkReportService.colors({ userId: keyData.userId, date: fmt(keyData.dates.today) }),
        ])
            .then(
                response => {
                    let workReportData = buildDisplayWorkReport(
                        response[0].data,
                        response[1].data,
                        {
                            today: keyData.dates.today,
                            next: keyData.dates.next
                        }
                    )

                    setWorkReportColors(buildDisplayColors(response[1].data))

                    setWorkReport(workReportData)
                    if (workReportData.todayId) {
                        loadWorkReportHistory(workReportData.todayId)
                    } else {
                        setWorkReportHistory(null)
                    }
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).then(
        ).catch(error => {
            setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
    }

    const loadWorkReportHistory = (id) => {
        WorkApproveService.findByWorkReportId(id)
            .then(
                response => {
                    setWorkReportHistory(response.data)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).then(
        ).catch(error => {
            setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
    }

    const checkWorkReport = (data, mode) => {
        if (!data.superiorId) {
            setWorkReport({ ...workReport, superiorIdError: "上長が指定されていません。総務課に連絡して下さい。" })
            setWorkReportCheck(false)
            return
        }

        if (!data.nextOvertime.startTime || !data.nextOvertime.endTime) {
            setError({ msg: "勤務時間は必ず入力してください", open: true, title: "エラー" })
            setWorkReportCheck(false)
            return false
        }
        if ((data.nextOvertime.startTime !== data.nextOvertime.defaultStartTime || data.nextOvertime.endTime !== data.nextOvertime.defaultEndTime) && !data.nextOvertime.overtimeComment) {
            setError({ msg: "残業の理由は必ず入力してください", open: true, title: "エラー" })
            setWorkReportCheck(false)
            return false
        }
        let firstRow = data.times.find((row) => {
            return row.today.text
        })
        if (firstRow && !firstRow.today.const.constId) {
            setError({ msg: "一行目の工番は必ず入力してください", open: true, title: "エラー" })
            setWorkReportCheck(false)
            return false
        }

        let today = buildSaveTodayWorkReport(data, mode)
        let next = buildSaveNextWorkReport(data)

        let d = { today: today, next: next }
        WorkReportService.check(d)
            .then(
                response => {
                    if (response.data.status === "ERROR") {
                        setError({ msg: response.data.message, open: true, title: "エラー" })
                    } else {
                        setWorkReportSend(true)
                    }
                    setWorkReportCheck(false)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const saveWorkReport = (data, comment, mode) => {
        let today = buildSaveTodayWorkReport(data, mode)
        let next = buildSaveNextWorkReport(data)

        let d = { today: today, next: next, comment: comment }
        WorkReportService.create(d)
            .then(
                response => {
                    loadWorkReport(keyData)
                    let msg = (mode === STATUS.EDITTING) ? '一時保存' : '提出処理'
                    displayMessage('作業日報', `${msg}を完了しました。`)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const downloadPdfWorkReport = (data, comment, mode) => {
        let today = buildSaveTodayWorkReport(data, mode)
        let next = buildSaveNextWorkReport(data)

        let d = { data: [{ today: today, next: next, comment: comment }] }
        PdfService.download("workreport", d)
            .catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const downloadPdfConstReport = (data, comment, mode) => {
        let today = buildSaveTodayConstReport(data, mode)
        let next = buildSaveNextConstReport(data)

        let d = { data: [{ version: 1, v1data: { today: today, next: next } }] }
        PdfService.download("constreport", d)
            .catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const downloadPdfConstReport2 = (data) => {
        let d = { data: [{ version: 2, v2data: data }] }
        PdfService.download("constreport", d)
            .catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const downloadPdfKyReport = (data, comment, mode) => {
        let today = buildSaveTodayConstReport(data, mode)
        let next = buildSaveNextConstReport(data)

        let d = { data: [{ version: 1, v1data: { today: today, next: next } }] }
        PdfService.download("kyreport", d)
            .catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    //
    // 工事日報関連の関数定義
    //
    const buildSaveTodayConstReport = (data, mode) => {
        let today = {
            ...data.today,
            sendDate: data.sendDate,
            status: mode,
            date: data.dates.today,
            next: data.dates.next,
            members: data.members,
            constId: data.constId,
            superiorId: data.superiorId,
            photos: data.photos,
        }

        // 初の提出の時、提出日時をセット
        if (data.status === STATUS.EDITTING && mode !== STATUS.EDITTING) {
            today.sendDate = new Date()
        }

        return today
    }

    const buildSaveNextConstReport = (data) => {
        let next = {
            ...data.next,
            date: data.dates.next,
            constId: data.constId,
            members: data.members,
        }

        return next
    }

    const buildDisplayConstReport = (data, key) => {
        let d = {
            id: null,
            version: 1,
            constId: key.constId,
            accountId: null,
            sendDate: null,
            status: STATUS.EDITTING,
            members: [],
            dates: { today: data.today.date, next: data.next.date },
            today: getInitialConstReport(key.constDates.today),
            next: getInitialConstReport(key.constDates.next),
        }

        if (data.today) {
            d.id = data.today._id
            d.superiorId = data.today.superiorId ? data.today.superiorId : d.superiorId
            d.accountId = data.today.accountId
            d.status = data.today.status
            d.sendDate = data.today.sendDate
            d.today = { ...data.today, date: data.today.date }
            d.members = data.today.members
        }

        if (data.next) {
            d.next = { ...data.next, date: data.next.date }
        }
        return d
    }

    const loadConstReport = (key) => {
        Promise.all([
            ConstReportService.load({ today: fmt(key.constDates.today), next: fmt(key.constDates.next), constId: key.constId, userId: key.userId }),
            ConstReportService2.load({ date: fmt(key.constDates.today), constId: key.constId, userId: key.userId }),
            CameraService.getPhotos(`{ constId: "${key.constId}", day: ${convMongoIsoDate(key.constDates.today)}, imagePath: {$exists: true} }`)
        ])
            .then(
                response => {
                    let constReportData
                    if (response[0].data) {
                        constReportData = buildDisplayConstReport(response[0].data, key)
                        constReportData.photos = response[2].data.objects
                    } else {
                        constReportData = response[1].data
                        constReportData.todayPhotos = response[2].data.objects
                    }

                    setConstReport(constReportData)
                    if (constReportData.id) {
                        loadConstDiscussion(constReportData.id)
                    } else {
                        setConstReportDiscussion(null)
                    }
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const loadConstDiscussion = (id) => {
        ConstDiscussionService.loadByConstReportId(id)
            .then(
                response => {
                    setConstReportDiscussion(response.data)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).then(
        ).catch(error => {
            setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
    }

    const checkConstReport = (data, mode) => {
        if (data.version === 1) {
            return checkConstReport1(data, mode)
        } else {
            return checkConstReport2(data, mode)
        }
    }
    const checkConstReport1 = (data, mode) => {
        if (!data.superiorId) {
            setConstReport({ ...constReport, superiorIdError: "上長が指定されていません。総務課に連絡して下さい。" })
            setConstReportCheck(false)
            return
        }
        let today = buildSaveTodayConstReport(data, mode)
        let next = buildSaveNextConstReport(data)

        let d = { today: today, next: next }
        ConstReportService.check(d)
            .then(
                response => {
                    if (response.data.status === "ERROR") {
                        setError({ msg: response.data.message, open: true, title: "エラー" })
                    } else {
                        setConstReportSend(true)
                    }
                    setConstReportCheck(false)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const checkConstReport2 = (data, mode) => {
        ConstReportService.check(data)
            .then(
                response => {
                    if (response.data.status === "ERROR") {
                        setError({ msg: response.data.message, open: true, title: "エラー" })
                    } else {
                        setConstReportSend(true)
                    }
                    setConstReportCheck(false)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const saveConstReport = (data, mode) => {
        if (data.version === 1) {
            return saveConstReport1(data, mode)
        } else {
            return saveConstReport2(data, mode)
        }
    }

    const saveConstReport1 = (data, mode) => {
        let today = buildSaveTodayConstReport(data, mode)
        let next = buildSaveNextConstReport(data)

        let d = { today: today, next: next }
        ConstReportService.create(d)
            .then(
                response => {
                    loadConstReport(keyData)
                    let msg = (mode === STATUS.EDITTING) ? '一時保存' : '提出処理'
                    displayMessage('工事日報', `${msg}を完了しました。`)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }
    const saveConstReport2 = (data, mode) => {
        ConstReportService2.save({ ...data, status: mode === STATUS.EDITTING ? 0 : 2, sendDate: mode === STATUS.EDITTING ? null : new Date() })
            .then(
                response => {
                    loadConstReport(keyData)
                    let msg = (mode === STATUS.EDITTING) ? '一時保存' : '提出処理'
                    displayMessage('工事日報', `${msg}を完了しました。`)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const fmt = (dt) => {
        if (!dt) {
            return null
        }
        let y = dt.getFullYear()
        let m = ("00" + (dt.getMonth() + 1)).slice(-2)
        let d = ("00" + dt.getDate()).slice(-2)
        return `${y}/${m}/${d}`
    }

    return (
        <React.Fragment>
            <DateInputDialog open={dateDialogOpen}
                canCancel={(workReport?.dates.today)}
                onClose={() => {
                    setDateDialogOpen(false)
                }}
                onOk={(d) => {
                    const key = { ...keyData, dates: d, constDates: d }
                    setKeyData(key)
                    setDateDialogOpen(false)
                }} />

            <DateInputDialog open={constDateDialogOpen}
                canCancel={(constReport?.dates?.today)}
                onClose={() => {
                    setConstDateDialogOpen(false)
                }}
                onOk={(d) => {
                    const key = { ...keyData, constDates: d }
                    setKeyData(key)
                    setConstDateDialogOpen(false)
                }} />

            <ConstIdDialog open={constDialogOpen}
                onChoice={(project) => {
                    const key = { ...keyData, constId: project.constId }
                    setKeyData(key)
                    setConstDialogOpen(false)
                }}
                onClose={() => {
                    setConstDialogOpen(false)
                }}
            />

            <ConfirmationDialog
                dialogTitle="業務日報保存"
                bodyText={"業務日報を一時保存します。よろしいですか？"}
                open={workReportSave}
                onOk={() => {
                    saveWorkReport(workReport, "", STATUS.EDITTING)
                    setWorkReportSave(null)
                }}
                onCancel={() => { setWorkReportSave(null) }}
                onClose={() => { setWorkReportSave(null) }}
            />

            <CommentDialog
                dialogTitle="業務日報提出"
                bodyText={"業務日報を提出します。よろしいですか？"}
                open={workReportSend}
                onComment={(comment) => {
                    saveWorkReport(workReport, comment, STATUS.HOLDING)
                    setWorkReportSend(null)
                }}
                onClose={() => { setWorkReportSend(null) }}
            />

            <ConfirmationDialog
                dialogTitle="工事日報保存"
                bodyText={"工事日報を一時保存します。よろしいですか？"}
                open={constReportSave}
                onOk={() => {
                    saveConstReport(constReport, STATUS.EDITTING)
                    setConstReportSave(null)
                }}
                onCancel={() => { setConstReportSave(null) }}
                onClose={() => { setConstReportSave(null) }}
            />

            <ConfirmationDialog
                dialogTitle="工事日報保存"
                bodyText={"工事日報を提出します。よろしいですか？"}
                open={constReportSend}
                onOk={() => {
                    saveConstReport(constReport, STATUS.SENDED)
                    setConstReportSend(null)
                }}
                onCancel={() => { setConstReportSend(null) }}
                onClose={() => { setConstReportSend(null) }}
            />

            <div className="ReportBody">
                <div className="outline">
                    <Tabs
                        style={{ position: "relative", }}
                        indicatorColor="primary"
                        textColor="primary"
                        selectionFollowsFocus={true}
                        value={tabIndex}
                        onChange={(event, v) => {
                            setTabIndex(v)
                        }}
                    >
                        {
                            (!keyData.mode || keyData.mode === "workReport") ?
                                (<Tab label="業務日報" style={{ backgroundColor: "white", }} />) : null
                        }
                        {
                            (!keyData.mode || keyData.mode === "constReport") ?
                                (<Tab label="工事日報" style={{ backgroundColor: "white", }} />) : null
                        }
                    </Tabs>
                    <div style={{ position: "relative" }}>
                        {contents[tabIndex]}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ReportBody
