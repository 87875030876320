import './FinancialStatements.css'

import { ROLES } from './utils/Utils'

import Header from './common/Header'
import SearchCondition from './financial/condition/SearchCondition'
import ConstList from './financial/ConstList'
import FinancialFooter from './financial/FinancialFooter'
import Content from './common/Content'

import { constListData } from './hooks/ConstListData'
import { sessionUserState } from './hooks/SessionUserState'

import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'

const FinancialStatements = (props) => {
    const [condition, setCondition] = useState({ searchKey: '', process: true, salesStaff: '', constStaff: '' })
    const [constList, setConstList] = useState([])

    const constMap = useRecoilValue(constListData) // 工事情報データ（マップ構造）
    const userState = useRecoilValue(sessionUserState)

    useEffect(() => {
        setConstList(getConsts())
    }, [constMap, condition])

    // 画面で入力された検索条件により、工事を絞り込む
    const getConsts = () => {
        let list = Array.from(constMap.values()).filter((v) => {
            return (v.constId.includes(condition.searchKey) || v.name.includes(condition.searchKey))
                && (v.constId.startsWith("O-") || v.constId.startsWith("T-"))
                && ((userState.personalSetting?.area === "大阪" && v.constId.startsWith("O"))
                    || (userState.personalSetting?.area === "東京" && v.constId.startsWith("T"))
                    || userState.personalSetting?.area === "指定無し")
                && (condition.salesStaff ? v.salesStaff === condition.salesStaff : true)
                && (condition.constStaff ? v.staff === condition.constStaff : true)
                && (condition.process ? v.process === "工事進行中" : true)
        })
        return list
    }

    return (
        <Content role={ROLES.FINANCIAL_STATEMENTS}>
            <Header title="現場収支表" />
            <div style={{ position: "absolute", display: "block", width: "100%", top: "40px", bottom: "5px", backgroundColor: "white" }}>
                <SearchCondition value={condition} onChangeSearchCondition={(c) => {
                    setCondition(c)
                }} />
                <ConstList
                    searchCondition={condition}
                    constList={constList}
                    style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }} props={props} />
            </div>
            <FinancialFooter />
        </Content>
    )
}

export default FinancialStatements
