// 設定値
const FIRST_FISCAL_YEAR = 1994
const FISCAL_YEAR_START_MONTH = 7 // 8月（0始まりのため7）

/**
 * 指定された日付が何期目に当たるかを計算
 * @param {Date} date - 計算対象の日付
 * @returns {number} 期数
 */
const calculateFiscalYear = (date) => {
  const year = date.getFullYear()
  const month = date.getMonth()

  // 8月以降は次の年度として計算
  if (month >= FISCAL_YEAR_START_MONTH) {
    return year - FIRST_FISCAL_YEAR + 1
  }
  return year - FIRST_FISCAL_YEAR
}

/**
 * 現在の日付から期数を計算
 * @returns {number} 期数
 */
const getCurrentFiscalYear = () => calculateFiscalYear(new Date())

/**
 * 日付のフォーマット
 * @param {Date} date - フォーマットする日付
 * @returns {string} フォーマットされた日付文字列
 */
const formatMonth = (date) => {
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  return `${year}年${month}月`
}

/**
 * 指定された期の開始日と終了日を返す
 * @param {number} fiscalYear - 期数
 * @returns {{startDate: Date, endDate: Date, toString: () => string}} 開始日と終了日のオブジェクト
 */
const getFiscalPeriodDates = (fiscalYear) => {
  // 期から年を計算
  const year = FIRST_FISCAL_YEAR + fiscalYear - 1

  // 期の開始日を計算（8月1日）
  const startDate = new Date(year, FISCAL_YEAR_START_MONTH, 1)

  // 期の終了日を計算（翌年7月31日）
  const endDate = new Date(year + 1, FISCAL_YEAR_START_MONTH, 0)

  return {
    startDate,
    endDate,
    toString: () => `${formatMonth(startDate)}〜${formatMonth(endDate)}`
  }
}

/**
 * 指定された日付が指定された期間内かどうかをチェック
 * @param {Date} targetDate - チェックする日付
 * @param {number} period - 期数
 * @returns {boolean} 期間内の場合true
 */
const isWithinFiscalPeriod = (targetDate, period) => {
  const { startDate, endDate } = getFiscalPeriodDates(period)
  return targetDate >= startDate && targetDate <= endDate
}

const getFiscalYears = (startDate, endDate) => {
  const startFiscalYear = calculateFiscalYear(startDate)
  const endFiscalYear = calculateFiscalYear(endDate)
  return Array.from({ length: endFiscalYear - startFiscalYear + 1 }, (_, i) => startFiscalYear + i)
}

// ESモジュールとしてエクスポート
export {
  calculateFiscalYear,
  getCurrentFiscalYear,
  getFiscalPeriodDates,
  isWithinFiscalPeriod,
  getFiscalYears
}
