import './ConstReports.css'

import { errorState } from '../../hooks/ErrorState'
import ConstReportService2 from '../../../services/ConstReportService2'
import ReportListService from '../../../services/ReportListService'

import ConstReportRow from '../../constReportList/ConstReportRow'

import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { useSetRecoilState } from 'recoil'
import 'react-calendar/dist/Calendar.css'
import ReactCalendar from 'react-calendar'

const ReportCalendar = styled(ReactCalendar)`
    button {
        padding: 5px;
    }
`

const ConstReports = (props) => {
  const [key, setKey] = useState({ constId: props.constId, date: new Date(), minDate: new Date(), maxDate: new Date() })
  const [dates, setDates] = useState([])
  const [report, setReport] = useState(null)
  const setError = useSetRecoilState(errorState)

  const fmt = (dt) => {
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    return `${y}-${m}-${d}`
  }

  const loadDates = () => {
    ConstReportService2.loadDates(props.constId)
      .then(response => {
        setDates(response.data.map((d) => {
          return new Date(d)
        }))
      }, error => {
        console.log(error)
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      })
      .catch(error => {
        console.log(error)
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      })
  }
  const loadConstReports = (key) => {
    ReportListService.loadConstReport({ constId: key.constId, start: fmt(key.date), end: fmt(key.date), userId: '', area: '' })
      .then(
        response => {
          let reportListDatas = response.data.objects.map((rowData) => {
            if (rowData.constReport.version !== 2) {
              return {
                version: 1,
                today: {
                  ...rowData.constReport.constReportResult,
                  photos: rowData.constReport.photo
                },
                next: rowData.constReport.constReportPlan,
                discussion: rowData.constDiscussion
              }
            } else {
              return rowData.constReport.constReport2
            }
          })
          if (reportListDatas && reportListDatas.length > 0) {
            setReport(reportListDatas[0])
          }
        },
        error => {
          setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        }
      ).catch(error => {
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      })
  }

  const isDateAvailable = (date) => {
    return dates.some(
      availableDate =>
        availableDate.getFullYear() === date.getFullYear() &&
        availableDate.getMonth() === date.getMonth() &&
        availableDate.getDate() === date.getDate()
    )
  }
  useEffect(() => {
    loadDates()
  }, [props.constId])

  useEffect(() => {
    setKey({ constId: props.constId, date: dates.at(-1), minDate: dates.at(0), maxDate: dates.at(-1) })
  }, [dates])

  useEffect(() => {
    if (key.constId && key.date) {
      loadConstReports(key)
    }
  }, [key])

  return (
    <div className="ConstReports">
      <div className="Calendar">
        <ReportCalendar value={key.date}
          calendarType="gregory"
          minDate={key.minDate}
          maxDate={key.maxDate}
          formatDay={(locale, date) => { return '' + date.getDate() }}
          tileDisabled={({ date }) => !isDateAvailable(date)}
          tileClassName={({ date, view }) => {
            if (view === 'month') {
              if (isDateAvailable(date)) {
                return 'AvailableDate'
              } else {
                return 'UnavailableDate'
              }
            }
            return null;
          }}
          view="month"
          onChange={(date) => {
            setKey({ ...key, date: date })
          }
          } locale="ja-JP" />
      </div>
      <div className="Reports">
        {report ? <ConstReportRow key={key} value={report} /> : null}
      </div>
    </div>
  )
}

export default ConstReports
