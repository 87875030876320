import './ConstListRow.css'

import { formatDate } from '../../utils/Utils'

import {
  TableRow,
  TableCell,
} from '@mui/material'

import React from 'react'


const ConstListRow = (props) => {
  return (
    <TableRow id={'ANCHOR-' + props.value.constId} key={props.value.constId} className="ConstListRow" style={props.selected ? { backgroundColor: "#cfc" } : {}} onClick={() => {
      props.onChoiceConstId(props.value.constId)
    }}>
      <TableCell className="constId">{props.value.constId}</TableCell>
      <TableCell className="name">{props.value.name}</TableCell>
      <TableCell className="termFrom">{formatDate(props.value.termFrom)}　〜　{formatDate(props.value.termTo)}</TableCell>
      <TableCell className="salesStaff">{props.value.salesStaff}</TableCell>
      <TableCell className="staff">{props.value.staff}</TableCell>
    </TableRow>
  )
}

export default ConstListRow