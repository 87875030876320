import { http } from "./Config"
const requestPath = "/v1/excel"

/**
 * Excelファイルを作成してダウンロードする
 * @param  templateName テンプレート名 stage
 * @param  data テンプレート変数として利用するデータ
 */
const download = (templateName, data) => {
  if (window.matchMedia && window.matchMedia('(max-device-width: 480px)').matches) {
    return downloadSmartPhone(templateName, data)
  } else {
    return downloadPc(templateName, data)
  }
}

const downloadSmartPhone = (templateName, data) => {
  return http.post(`${requestPath}/${templateName}`, data, {
    headers: {
      "content-type": "application/vnd.ms-excel",
    },
    responseType: "blob",
  })
    .then(
      response => {
        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel"
        });

        var url = URL.createObjectURL(blob)

        var a = document.createElement("a")
        document.body.appendChild(a)

        a.style = "display: none"
        a.href = url
        a.download = templateName + ".xlsx"
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(a.href)
      },
      error => {
        throw error;
      }
    )
}

const downloadPc = (templateName, data) => {
  return http.post(`${requestPath}/${templateName}`, data, {
    headers: {
      "content-type": "application/json",
      "accept": "application/vnd.ms-excel"
    },
    responseType: "blob"
  })
    .then(
      response => {
        const blob = new Blob([response.data], {
          type: "application/vnd.ms-excel"
        })

        // Content-Dispositionヘッダーからファイル名を抽出
        const contentDisposition = response.headers["content-disposition"]
        let fileName = "download.xlsx" // デフォルトのファイル名

        if (contentDisposition) {
          // filename*=UTF-8''の場合
          const filenameRegex = /filename\*=UTF-8''([\w%.-]+)/i
          const matches = filenameRegex.exec(contentDisposition)
          if (matches != null && matches[1]) {
            fileName = decodeURIComponent(matches[1])
          } else {
            // filename=の場合
            const filenameRegex = /filename="?(.+)"?/i
            const matches = filenameRegex.exec(contentDisposition)
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '')
              // 必要に応じてデコード
              try {
                fileName = decodeURIComponent(escape(fileName))
              } catch (e) {
                console.warn("Failed to decode filename", e)
              }
            }
          }
        }

        const a = document.createElement('a')
        a.href = URL.createObjectURL(blob)
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(a.href)  // メモリリークを防ぐ
      },
      error => {
        console.error("Download failed", error)
        throw error
      }
    )
}
const exports = {
  download,
};

export default exports;
