import React from 'react'

const JpnDate = (props) => {
    let v = props.value
    if (!v) {
        return (<span {...props} style={{ height: "23px", backgroundColor: props.required ? "red" : "transparent" }} />)
    }
    let dt = new Date(v)
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    let idx = dt.getDay()
    let w = '日月火水木金土'[idx]
    return (
        <span {...props} style={{ height: "23px", ...props.style }}>
            {y + "/" + m + "/" + d}
            (<span style={(idx === 0 || idx === 6) ? { color: "#FF3F3F" } : {}}>{w}</span>)
        </span>
    )
}

export default JpnDate