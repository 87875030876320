import './SideMenu.css'

import ListItemLink from './ListItemLink'
import { ROLES } from '../utils/Utils'

import { displayMenuState } from '../hooks/DisplayMenuState'
import { sessionUserState } from '../hooks/SessionUserState'

import React from 'react'
import DescriptionIcon from '@mui/icons-material/Description'
import HomeIcon from '@mui/icons-material/Home'
import ListAltIcon from '@mui/icons-material/ListAlt'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import CommentIcon from '@mui/icons-material/Comment'
import SettingsIcon from '@mui/icons-material/Settings'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import InsightsIcon from '@mui/icons-material/Insights'
import PrintIcon from '@mui/icons-material/Print'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import Diversity3Icon from '@mui/icons-material/Diversity3'

import { Paper, List, ListItemText, ListItemIcon } from '@mui/material'
import { useRecoilValue, useRecoilState } from 'recoil'
import { Transition } from 'react-transition-group'
import { useNavigate } from 'react-router-dom'

const SideMenu = (props) => {
    const navigate = useNavigate()
    const [menuState, setMenuState] = useRecoilState(displayMenuState)
    const userData = useRecoilValue(sessionUserState)

    const transitionStyles = {
        entering: { left: '0px', transition: 'all 300ms ease' },
        entered: { left: '0px' },
        exiting: { left: '-200px', transition: 'all 300ms ease' },
        exited: { left: '-200px' },
    }

    const roles = userData.roles
    return (
        <Transition in={menuState} timeout={1500}>
            {(state) => (
                <Paper style={transitionStyles[state]} className="SideMenu">
                    <List style={{ paddingTop: 0 }}>
                        <ListItemLink style={{ borderBottom: '1px solid lightgray' }} onClick={() => {
                            setMenuState(false)
                        }}>
                            <ListItemIcon><KeyboardArrowLeftIcon /></ListItemIcon><ListItemText primary="メニュー" />
                        </ListItemLink>
                        <ListItemLink onClick={() => {
                            navigate('/task')
                            setMenuState(false)
                        }}>
                            <ListItemIcon><HomeIcon /></ListItemIcon><ListItemText primary="トップ" />
                        </ListItemLink>
                        {roles.includes(ROLES.CONST_ADMINISTRATOR) ? (
                            <ListItemLink onClick={() => {
                                navigate('/resultmanage')
                                setMenuState(false)
                            }}>
                                <ListItemIcon><ShowChartIcon /></ListItemIcon><ListItemText primary="工事進捗管理" />
                            </ListItemLink>
                        ) : (
                            <React.Fragment />
                        )}
                        {roles.includes(ROLES.FINANCIAL_STATEMENTS) ? (
                            <ListItemLink onClick={() => {
                                navigate('/financial')
                                setMenuState(false)
                            }}>
                                <ListItemIcon><InsightsIcon /></ListItemIcon><ListItemText primary="現場収支表" />
                            </ListItemLink>
                        ) : (
                            <React.Fragment />
                        )}
                        {roles.includes(ROLES.LABOR_COSTS) ? (
                            <ListItemLink onClick={() => {
                                navigate('/laborcosts')
                                setMenuState(false)
                            }}>
                                <ListItemIcon><Diversity3Icon /></ListItemIcon><ListItemText primary="人件費" />
                            </ListItemLink>
                        ) : (
                            <React.Fragment />
                        )}
                        <ListItemLink onClick={() => {
                            navigate('/report')
                            setMenuState(false)
                        }}>
                            <ListItemIcon><DescriptionIcon /></ListItemIcon><ListItemText primary="日報入力" />
                        </ListItemLink>
                        <ListItemLink onClick={() => {
                            navigate('/photo')
                            setMenuState(false)
                        }}>
                            <ListItemIcon><MenuBookIcon /></ListItemIcon><ListItemText primary="工事写真編集" />
                        </ListItemLink>
                        <ListItemLink onClick={() => {
                            navigate('/reportlist')
                            setMenuState(false)
                        }}>
                            <ListItemIcon><ListAltIcon /></ListItemIcon><ListItemText primary="日報一覧" />
                        </ListItemLink>
                        {roles.includes(ROLES.ADMINISTRATOR) ? (
                            <ListItemLink onClick={() => {
                                navigate('/workapprove')
                                setMenuState(false)
                            }}>
                                <ListItemIcon><ThumbUpIcon /></ListItemIcon><ListItemText primary="業務日報承認" />
                            </ListItemLink>
                        ) : (
                            <React.Fragment />
                        )}
                        {roles.includes(ROLES.ADMINISTRATOR) ? (
                            <ListItemLink onClick={() => {
                                navigate('/constdiscus')
                                setMenuState(false)
                            }}>
                                <ListItemIcon><CommentIcon /></ListItemIcon><ListItemText primary="工事日報確認" />
                            </ListItemLink>
                        ) : (
                            <React.Fragment />
                        )}
                        {roles.includes(ROLES.WORKREPORT_PRINT) ? (
                            <ListItemLink onClick={() => {
                                navigate('/worklist')
                                setMenuState(false)
                            }}>
                                <ListItemIcon><PrintIcon /></ListItemIcon><ListItemText primary="業務日報印刷" />
                            </ListItemLink>
                        ) : (
                            <React.Fragment />
                        )}
                        {roles.includes(ROLES.CONSTREPORT_PRINT) ? (
                            <ListItemLink onClick={() => {
                                navigate('/constlist')
                                setMenuState(false)
                            }}>
                                <ListItemIcon><PrintIcon /></ListItemIcon><ListItemText primary="工事日報印刷" />
                            </ListItemLink>
                        ) : (
                            <React.Fragment />
                        )}
                        {roles.includes(ROLES.SYSTEM_ADMINISTRATOR) ? (
                            <ListItemLink onClick={() => {
                                navigate('/setting')
                                setMenuState(false)
                            }}>
                                <ListItemIcon><SettingsIcon /></ListItemIcon><ListItemText primary="システム設定" />
                            </ListItemLink>
                        ) : (
                            <React.Fragment />
                        )}
                    </List>
                </Paper>
            )}
        </Transition>
    )
}

export default SideMenu
