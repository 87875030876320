import React from 'react'

import { Select, MenuItem } from '@mui/material'
import './AreaChoicer.css'

const AreaChoicer = (props) => {
  const areaList = [
    "指定無し",
    "大阪",
    "東京"
  ]
  return (
    <Select
      className='AreaChoicer'
      disableunderline="true"
      value={props.value ? props.value : ""}
      style={{ fontSize: "13px" }}
      onChange={(event) => {
        if (props.onChange) {
          props.onChange(event)
        }
      }}>
      {areaList.map((area) => {
        return (
          <MenuItem key={area} value={area} selected={area === props.value}>{area}</MenuItem>
        )
      })}
    </Select>
  )
}

export default AreaChoicer
