import "./ConstSchedule.css"

import { getInitialVols } from '../common/ResultManageUtil'
import ExpandControl from './ExpandControl'
import Actions from './Actions'
import ViewModeChoicer from './ViewModeChoicer'
import CellInput from '../../common/CellInput'

import { constListData } from '../../hooks/ConstListData'
import { parseDate, isValidDate } from '../../utils/Utils'

import { Gantt, ViewMode } from 'gantt-task-react'
import "gantt-task-react/dist/index.css"

import { v4 } from 'uuid'

import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'

const ConstSchedule = (props) => {
  const [view, setView] = useState(ViewMode.Month)
  const [tasks, setTasks] = useState([])
  const constMap = useRecoilValue(constListData) // 工事一覧データ（マップ構造）
  const details = props.value.details

  // 見積りデータに、スケジュールのデータを合成する
  const buildTasks = (constInfo, schedule) => {
    if (!schedule) return []
    return schedule.map((row) => {
      let task = {
        start: parseDate(row.startDate ? row.startDate : props.value.termFrom),
        end: parseDate(row.endDate ? row.endDate : props.value.termTo),
        name: row.name,
        id: row.guid,
        level: row.level,
        type: row.leaf ? 'task' : 'project',
        parent: row.parent,
        leaf: row.leaf,
        expandChild: row.expandChild,
        progress: 0,
        //              styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
        isDisabled: false
      }
      return task
    })
  }

  const handleNameChange = (task) => {
    let tmpSchedule = details.concat()

    tmpSchedule.forEach((row) => {
      if (row.guid === task.id) {
        row.name = task.name
        row.discount = task.discount
      }
    })

    props.onChangeSchedule({ ...props.value, details: tmpSchedule }, false)
  }

  const handleDateChange = (task) => {
    let tmpSchedule = details.concat()
    console.log(task.id)

    let org = { start: null, end: null }
    tmpSchedule.forEach((row) => {
      console.log(row)
      if (row.guid === task.id) {
        org.start = row.startDate
        org.end = row.endDate
        row.startDate = task.start
        row.endDate = task.end
      }
    })

    alignChild(tmpSchedule, org, task)

    alignAll(tmpSchedule, 5)

    alignAll(tmpSchedule, 3)

    props.onChangeSchedule({ ...props.value, details: tmpSchedule }, true)
  }

  const handleChangeExpandState = (task, v) => {
    let tmpSchedule = details.concat()

    tmpSchedule.forEach((row) => {
      if (row.guid === task.id) {
        row.expandChild = v
      }
    })

    props.onChangeSchedule({ ...props.value, details: tmpSchedule }, false)
  }

  const handleDelete = (task) => {
    let parent = task.parent
    let tmpSchedule = details.concat()
    let delqueue = [task.id]  // 削除するタスクのIDを格納する配列

    // ターゲットの明細も含め、ツリーを辿って削除する
    while (delqueue.length !== 0) {
      let targetId = delqueue.shift()

      tmpSchedule = tmpSchedule.map((row) => {
        if (row.parent === targetId) {  // ターゲットを親としているタスクならそれも削除
          delqueue.push(row.guid)
        }
        return row
      }).filter((row) => {
        return row.guid !== targetId && row.parrent !== targetId
      })
    }

    // 親があるタスクなら、親の子タスクがあるか確認。なければleafをtrueにする。
    if (parent) {
      let sameParents = tmpSchedule.find((row) => {
        return row.parent === parent
      })

      // 同じ親を持つ子タスクがいないなら、親のleafを立てる。
      if (!sameParents) {
        tmpSchedule.find((row) => {
          return row.guid === parent
        }).leaf = true
      }
    }
    resetIndex(tmpSchedule)

    props.onChangeSchedule({ ...props.value, details: tmpSchedule }, true)
  }

  const handleAddRow = (parentTask) => {
    let t = {
      constId: props.value.constId,
      name: '',
      startDate: parseDate(parentTask ? parentTask.start : props.value.termFrom),
      endDate: parseDate(parentTask ? parentTask.end : props.value.termTo),
      line1: 0,
      line3: 0,
      line5: 0,
      level: parentTask ? parentTask.level + 2 : 1,
      price: 0,
      taxType: 0,
      guid: v4().toUpperCase(),
      parent: parentTask ? parentTask.id : null,
      leaf: true,
      expandChild: false,
      vols: getInitialVols(props.value)
    }

    details.filter((row) => {
      return row.parent === (parentTask ? parentTask.id : null)
    }).forEach((row) => {
      t.line1 = Math.max(t.line1, row.line1)
      t.line3 = row.line3 < 0 ? row.line3 : Math.max(t.line3, row.line3)
      t.line5 = row.line5 < 0 ? row.line5 : Math.max(t.line5, row.line5)
    })

    if (t.level === 1) {
      t.line1 = t.line1 + 1
    } else if (t.level === 3) {
      t.line3 = t.line3 + 1
    } else if (t.level === 5) {
      t.line5 = t.line5 + 1
    }

    let tmpSchedule = details.concat()
    let idx = 0

    if (parentTask) {
      let parentIds = [parentTask.id]

      tmpSchedule.forEach((row, i) => {
        if (row.guid === parentTask.id) {
          row.leaf = false
          row.expandChild = true
          idx = i
        } else if (parentIds.indexOf(row.parent) >= 0) {
          parentIds.push(row.guid)
          idx = i
        }
      })
      tmpSchedule.splice(idx + 1, 0, t)
    } else {
      tmpSchedule.push(t)
    }
    resetIndex(tmpSchedule)

    props.onChangeSchedule({ ...props.value, details: tmpSchedule }, true)
  }

  const handleUp = (task) => {
    let tmpSchedule = details.concat()

    // 同一の親タスクを持つタスクを探す
    let parrarels = tmpSchedule.filter((row) => {
      return row.parent === task.parent
    })

    // 同一の親の何番目のアイテムかを取得する
    let idx = parrarels.findIndex((row) => {
      return row.guid === task.id
    })

    if (idx === 0) {
      return
    }

    // 交代するターゲットのタスクを取得する
    let tmp1 = parrarels[idx - 1]
    let tmp2 = parrarels[idx]

    tmpSchedule = exchangeTask(tmpSchedule, tmp1, tmp2)
    resetIndex(tmpSchedule)

    props.onChangeSchedule({ ...props.value, details: tmpSchedule }, true)
  }


  const handleDown = (task) => {
    let tmpSchedule = details.concat()

    // 同一の親タスクを持つタスクを探す
    let parrarels = tmpSchedule.filter((row) => {
      return row.parent === task.parent
    })

    // 同一の親の何番目もアイテムかを取得する
    let idx = parrarels.findIndex((row) => {
      return row.guid === task.id
    })

    if (idx === parrarels.length - 1) {
      return
    }

    // 交代するターゲットのタスクを取得する
    let tmp1 = parrarels[idx]
    let tmp2 = parrarels[idx + 1]

    tmpSchedule = exchangeTask(tmpSchedule, tmp1, tmp2)
    resetIndex(tmpSchedule)

    props.onChangeSchedule({ ...props.value, details: tmpSchedule }, true)
  }

  // ２つのタスクの表示順を、子タスクも含めて入れ替える
  const exchangeTask = (schedule, t1, t2) => {
    let before = []
    let after = []
    let t1s = []
    let t2s = []

    schedule.forEach((row) => {
      if (isChild(t1, row)) {
        t1s.push(row)
      } else if (isChild(t2, row)) {
        t2s.push(row)
      } else if (t1s.length === 0 && t1s.length === 0) {
        before.push(row)
      } else {
        after.push(row)
      }
    })

    let newSchedule = before.concat(t2s).concat(t1s).concat(after)

    resetIndex(newSchedule)
    return newSchedule
  }

  const resetIndex = (schedule) => {
    schedule.forEach((row, idx) => {
      row.idx = idx
    })
  }

  // targetがtaskの子にあたるかどうかを返す
  const isChild = (task, target) => {
    if (task.level === 1) {
      return task.line1 === target.line1
    } else if (task.level === 3) {
      return task.line1 === target.line1 && task.line3 === target.line3
    } else if (task.level === 5) {
      return task.line1 === target.line1 && task.line3 === target.line3 && task.line5 === target.line5
    }
  }

  // 親のスケジュールに合わせて、小のはみ出した部分を揃える
  const alignChild = (schedule, org, task) => {
    schedule.forEach((row) => {
      if (row.parent === task.id) {
        if (row.startDate < task.start) { // 開始日を後退させた場合、各タスクの開始日を後退した日に合わせる
          row.startDate = task.start
        }
        if (row.endDate < task.start) { // 開始日を後退させた場合、各タスクの終了日を後退した日に合わせる
          row.endDate = task.start
        }
        if (row.startDate > task.start && row.startDate === org.start) {  // 開始日を前倒した場合、同じ日に開始予定だったものを前倒しにする
          row.startDate = task.start
        }

        if (row.startDate > task.end) { // 終了日を前倒した場合、各タスクの開始日を後退した日に合わせる
          row.startDate = task.end
        }
        if (row.endDate > task.end) { // 終了日を前倒した場合、各タスクの終了日を後退した日に合わせる
          row.endDate = task.end
        }
        if (row.endDate < task.end && row.endDate === org.end) {  // 終了日を後退させた場合、同じ日に終了予定だったものを後退させる
          row.endDate = task.end
        }

        if (!row.leaf) {
          alignChild(schedule, org, { id: row.guid, start: row.startDate, end: row.endDate })
        }
      }
    })
  }

  const alignAll = (schedule, level) => {
    let map = new Map()

    schedule.forEach((row) => {
      if (row.level === level) {
        map.set(row.parent, {
          start: !map.get(row.parent) || map.get(row.parent).start > row.startDate ? row.startDate : map.get(row.parent).start,
          end: !map.get(row.parent) || map.get(row.parent).end < row.endDate ? row.endDate : map.get(row.parent).end
        })
      }
    })

    schedule.forEach((row) => {
      let v = map.get(row.guid)
      if (v) {
        row.startDate = v.start
        row.endDate = v.end
      }
    })
  }

  const TaskListHeader = () => {
    return (
      <div style={{ display: "table-row", textAlign: "center", height: "50px", textOverflow: "ellipsis", fontSize: "12px", fontWeight: "bold" }}>
        <div className="ScheduleNameCell" style={{ borderTop: "1px solid RGB(230, 228, 228)", borderBottom: "1px solid RGB(230, 228, 228)", borderLeft: "1px solid RGB(230, 228, 228)" }}>
          <p style={{ position: "absolute", margin: 0, bottom: "5PX", left: "5px" }}>
            <ExpandControl style={{ display: "inline-block" }} value={
              details && details.find((t) => {
                return t.expandChild === true
              })
            } onChangeState={(v) => {
              let schedules = details.concat()
              schedules.filter((t) => !t.leaf).forEach((t) => {
                t.expandChild = v
              })
              props.onChangeSchedule({ ...props.value, details: schedules }, false)
            }} />
            工事分類
          </p>
          <button className="AddConstType" onClick={() => {
            handleAddRow(null)
          }}><AddIcon style={{ fontSize: "14px" }} /></button>
        </div>
        <div className="ScheduleDateCell" style={{ borderTop: "1px solid RGB(230, 228, 228)", borderBottom: "1px solid RGB(230, 228, 228)", }}><p style={{ position: "absolute", margin: 0, bottom: "5PX", left: "30px" }}>開始日</p></div>
        <div className="ScheduleDateCell" style={{ borderTop: "1px solid RGB(230, 228, 228)", borderBottom: "1px solid RGB(230, 228, 228)", }}><p style={{ position: "absolute", margin: 0, bottom: "5PX", left: "30px" }}>完了日</p></div>
      </div>
    )
  }

  const TaskListTable = (props) => {
    return (
      <React.Fragment>
        {displayControl(tasks).map((row) => {
          return (
            <TaskRowHeader key={row.id} task={row}
              onDateChange={handleDateChange}
              onNameChange={handleNameChange}
              onChangeExpandState={handleChangeExpandState}
              onDelete={() => { handleDelete(row) }}
              onAddRow={() => { handleAddRow(row) }}
              onUp={() => { handleUp(row) }}
              onDown={() => { handleDown(row) }}
            />
          )
        })}
      </React.Fragment>
    )
  }
  const displayControl = (tasks) => {
    tasks = tasks.concat()

    // 一旦全て表示するモードに
    tasks.forEach((t) => {
      t.display = "table-row"
    })

    // expandChildに従って、子供の行の表示を切り替える
    tasks.forEach((t) => {
      tasks.forEach((t2) => {
        if (t.id === t2.parent) {
          t2.display = t.expandChild ? "table-row" : "none"
          if (!t.expandChild) {
            t2.expandChild = false
          }
        }
      })
    })

    return tasks.filter((t) => t.display === "table-row")
  }

  const handleViewMode = (viewMode) => {
    setView(viewMode)
  }

  useEffect(() => {
    setTasks(buildTasks(constMap.get(props.constId), details))
  }, [details])

  return (
    <div className="ConstSchedule">
      <ViewModeChoicer onChangeViewMode={handleViewMode} />
      {(tasks && tasks.length) > 0 ? (
        <div className="Gantt">
          <Gantt
            tasks={displayControl(tasks)}
            TaskListHeader={TaskListHeader}
            TaskListTable={TaskListTable}
            rowHeight={32}
            onDateChange={handleDateChange}
            viewMode={view}
            timeStep={24 * 60 * 60 * 1000}
            fontSize="12px"
            locale="ja" />
        </div>
      ) : (
        <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <div>
              {details && details.length === 0 ? "この工事の見積データが見つかりません" : ""}
            </div>
            <Button onClick={() => {
              handleAddRow(null)
            }}><AddIcon style={{ fontSize: "14px" }} />進捗表を作成する</Button>
          </div>
        </div>
      )}
    </div>
  )
}

const TaskRowHeader = (props) => {
  const [row, setRow] = useState(props.task)
  const [err, setErr] = useState(false)
  const [editMode, setEditMode] = useState(false)

  const fmt = (dt) => {
    dt = parseDate(dt)
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    return `${y}-${m}-${d}`
  }

  useEffect(() => {
    setRow(props.task)
    if (!props.task.name) {
      setEditMode(true)
    }
  }, [props.task])

  useEffect(() => {
    // CellInputにフォーカスを当てる
    if (editMode) {
      setTimeout(() => {
        let elm = document.getElementById("name-" + row.id)
        elm.focus()
        elm.select()
      }, 100)
    }
  }, [editMode])
  return (
    <div style={{ display: "table-row", height: "32px", textOverflow: "ellipsis", fontSize: "12px" }}>
      <div className="ScheduleNameCell" style={{ border: "1px solid RGB(230, 228, 228)", }} onClick={(row) => {
      }}>
        <div style={{ marginLeft: "" + ((row.level - 1) * 10 + 5) + "px", display: "flex", alignItems: "center" }}>
          <div style={{ display: "inline-block", width: "20px" }}>
            {(!row.leaf) ? <ExpandControl value={row.expandChild} onChangeState={(v) => {
              props.onChangeExpandState(row, v)
            }} /> : null}
          </div>
          <CellInput id={"name-" + row.id} required={true} type="text" style={{ backgroundColor: err ? "red" : "transparent", display: "inline", width: "150px", cursor: editMode ? "auto" : "pointer" }} line={1} readOnly={!editMode} defaultValue={row.name}
            onKeyDown={(e) => {
              if (err) {
                setErr(false)
              }
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                const newRow = { ...row, name: e.target.value, discount: e.target.value.indexOf("値引") >= 0 }
                props.onNameChange(newRow)
                setEditMode(false)
              }
            }}
            onClick={(e) => {
              setEditMode(true)
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
            }}
            onBlur={(e) => {
              if (e.target.value === "") {
                setErr(true)
                e.target.focus()
                return
              }
              const newRow = { ...row, name: e.target.value, discount: e.target.value.indexOf("値引") >= 0 }
              props.onNameChange(newRow)
              setEditMode(false)
            }}
          />
          <Actions
            task={row}
            onAddRow={() => {
              props.onAddRow()
            }}
            onDelete={() => {
              props.onDelete()
            }}
            onStartEdit={() => {
              setEditMode(true)
            }}
            onUp={() => {
              props.onUp()
            }}
            onDown={() => {
              props.onDown()
            }}
          />
        </div>
      </div>
      <div className="ScheduleDateCell" style={{ border: "1px solid RGB(230, 228, 228)", }}>
        <CellInput type="date" style={{ width: "100px" }} defaultValue={fmt(row.start)}
          onKeyDown={(e) => {
            e.stopPropagation()
            e.nativeEvent.stopImmediatePropagation()
          }}
          onBlur={(e) => {
            if (isValidDate(e.target.value)) {
              let d = new Date(e.target.value)
              props.onDateChange({ ...row, start: d })
            }
          }} />
      </div>
      <div className="ScheduleDateCell" style={{ border: "1px solid RGB(230, 228, 228)", }}>
        <CellInput type="date" style={{ width: "100px" }} defaultValue={fmt(row.end)}
          onKeyDown={(e) => {
            e.stopPropagation()
            e.nativeEvent.stopImmediatePropagation()
          }}
          onBlur={(e) => {
            if (isValidDate(e.target.value)) {
              let d = new Date(e.target.value)
              props.onDateChange({ ...row, end: d })
            }
          }} />
      </div>
    </div>
  )
}

export default ConstSchedule
