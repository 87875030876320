import { ROLES } from './Utils'

export const roleList = [
    { id: ROLES.SYSTEM_ADMINISTRATOR, name: 'システム管理者' },
    { id: ROLES.WORK_REPORT_REQUIRED, name: '業務日報提出対象者' },
    { id: ROLES.FINANCIAL_STATEMENTS, name: '現場収支表閲覧権限' },
    { id: ROLES.LABOR_COSTS, name: '人件費閲覧権限' },
    { id: ROLES.ADMINISTRATOR, name: '管理職者' },
    { id: ROLES.CONST_ADMINISTRATOR, name: '工事進捗責任者' },
    { id: ROLES.WORKREPORT_PRINT, name: '業務日報印刷' },
    { id: ROLES.CONSTREPORT_PRINT, name: '工事日報印刷' },
]