import './ConstReportList.css'

import { ROLES } from './utils/Utils'

import ConstReportRow from './constReportList/ConstReportRow'
import Header from './common/Header'
import Footer from './common/Footer'
import Content from './common/Content'
import MainContent from './common/MainContent'
import InfoBox from './common/InfoBox'
import CellInput from './common/CellInput'
import UserChoicer from './common/UserChoicer'
import ConstSelector from './common/ConstSelector'
import { MediaPc, MediaSmartPhone } from './common/Responsive'
import AreaChoicer from './common/AreaChoicer'

import ReportListService from '../services/ReportListService'
import PdfService from '../services/PdfService'

import { sessionUserState } from './hooks/SessionUserState'
import { errorState } from './hooks/ErrorState'

import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { atom, useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import queryString from 'query-string'

import { Box, IconButton, Button } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

const fmt = (dt) => {
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    return `${y}-${m}-${d}`
}

const dateAdd = (dt, day) => {
    dt.setDate(dt.getDate() + day)
    return dt
}

const searchConditionState = atom({
    key: 'constReportList.searchCondition',
    default: { constId: '', userId: '', start: fmt(dateAdd(new Date(), -2)), end: fmt(new Date()) },
})

const ConstReportList = (props) => {
    const [constReportList, setConstReportList] = useState([])
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
    const [page, setPage] = useState("today")

    const [condition, setCondition] = useRecoilState(searchConditionState)
    const userState = useRecoilValue(sessionUserState)

    const search = useLocation().search
    const queryParameter = queryString.parse(search)

    useEffect(() => {
        setCondition({
            constId: queryParameter.constId ? queryParameter.constId : '',
            userId: queryParameter.userId ? queryParameter.userId : '',
            start: queryParameter.startDate ? fmt(new Date(queryParameter.startDate)) : fmt(dateAdd(new Date(), -2)),
            end: queryParameter.endDate ? fmt(new Date(queryParameter.endDate)) : fmt(new Date()),
            area: queryParameter.area ? queryParameter.area : userState.personalSetting.area
        })
    }, [])

    const containerRef = useRef(null)

    const loadConstReportList = (key) => {
        ReportListService.loadConstReport(key)
            .then(
                response => {
                    let reportListDatas = response.data.objects.map((rowData) => {
                        if (rowData.constReport.version !== 2) {
                            return {
                                version: 1,
                                today: {
                                    ...rowData.constReport.constReportResult,
                                    photos: rowData.constReport.photo
                                },
                                next: rowData.constReport.constReportPlan,
                                discussion: rowData.constDiscussion
                            }
                        } else {
                            return rowData.constReport.constReport2
                        }
                    })
                    setConstReportList(reportListDatas)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const printPdf = () => {
        const report = constReportList.map((row) => {
            if (row.version !== 2) {
                return {
                    version: 1,
                    v1data: {
                        today: row.today,
                        next: row.next,
                    },
                    discussion: row.discussion
                }
            } else {
                return {
                    version: 2,
                    v2data: row
                }
            }
        })
        PdfService.download("constreport", { data: report })
            .catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    return (
        <Content role={ROLES.CONSTREPORT_PRINT}>
            <Header title="工事日報印刷" />
            <MainContent className="ConstReportList">
                <SearchCondition value={condition} onChangeSearchCondition={(condition) => {
                    setConstReportList([])
                    loadConstReportList(condition)
                }} />
                <div className="list" ref={containerRef}>
                    {constReportList.map((r) => {
                        return (
                            <React.Fragment>
                                <ConstReportRow key={r._id} page={page} value={r} container={containerRef.current} onError={(e) => {
                                    setError(e)
                                }} />
                                <hr style={{ border: "2px solid #0000", margin: "0px", width: "calc(100% - 20px)" }} />
                            </React.Fragment>
                        )
                    })}
                </div>
            </MainContent>
            <Footer>
                <Box style={{ paddingRight: "10px" }}>
                    <Button color="inherit" variant="contained" onClick={(event) => {
                        printPdf()
                    }}><PictureAsPdfIcon /><MediaPc>工事日報(PDF)</MediaPc></Button>
                </Box>
                <MediaSmartPhone>
                    <PrevArrow page={page} onClick={() => {
                        setPage("today")
                    }} />

                    <NextArrow page={page} onClick={() => {
                        setPage("next")
                    }} />
                </MediaSmartPhone>
            </Footer>
        </Content>
    )
}

const SearchCondition = (props) => {
    const [searchCondition, setSearchCondition] = useState(props.value)
    return (
        < div style={{ backgroundColor: "white", padding: "5px" }}>
            <InfoBox title={"日付範囲"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <CellInput key={"start"} type="date" value={searchCondition.start}
                        onChange={(event) => {
                            setSearchCondition({ ...searchCondition, start: event.target.value })
                        }}
                    />
                    <span style={{ verticalAlign: "middle" }}>〜</span>
                    <CellInput key={"end"} type="date" value={searchCondition.end}
                        onChange={(event) => {
                            setSearchCondition({ ...searchCondition, end: event.target.value })
                        }}
                    />
                </div>
            </InfoBox>
            <InfoBox title={"社員"} style={{ width: "400px" }}>
                <UserChoicer value={searchCondition.userId}
                    onChoiceUser={(userId) => {
                        setSearchCondition({ ...searchCondition, userId: userId })
                    }} />
            </InfoBox>
            <InfoBox title={"工事番号"} style={{ width: "600px" }}>
                <ConstSelector value={searchCondition.constId}
                    onChangeConstId={(constId) => {
                        setSearchCondition({ ...searchCondition, constId: constId })
                    }} />
            </InfoBox>
            <InfoBox title={"エリア"}>
                <AreaChoicer value={searchCondition.area} onChange={(event) => {
                    setSearchCondition({ ...searchCondition, area: event.target.value })
                }} />
            </InfoBox>
            <Button
                style={{ marginLeft: "10px" }}
                variant="contained"
                color="inherit"
                onClick={() => {
                    props.onChangeSearchCondition(searchCondition)
                }} >
                検索
            </Button>
        </div >
    )
}

const NextArrow = (props) => {
    return (
        <IconButton className={" Pager"} style={{ position: "absolute", display: (props.page === "today" ? "block" : "none"), width: "40px", top: "0px", right: "5px", color: "white" }} ariaLabel="edit"
            onClick={props.onClick}
        >
            <ArrowForwardIosIcon />
        </IconButton>
    )
}

const PrevArrow = (props) => {
    return (
        <IconButton className={" Pager"} style={{ position: "absolute", display: (props.page === "next" ? "block" : "none"), width: "40px", top: "0px", left: "5px", color: "white" }} ariaLabel="edit"
            onClick={props.onClick}
        >
            <ArrowBackIosIcon />
        </IconButton>
    )
}

export default ConstReportList
