import './MemberChoicer.css'

import UserDialog from './UserDialog'
import { RedIconButton } from './ColorButton'
import { userListData } from '../hooks/UserListData'

import React, { useState } from 'react'
import Select from 'react-select'
import AddIcon from '@mui/icons-material/Add'
import { useRecoilValue } from 'recoil'

const MemberChoicer = (props) => {
    const [userDialogOpen, setUserDialogOpen] = useState(false)
    const userMap = useRecoilValue(userListData) // 案件一覧データ（マップ構造）

    let values = (props.value ? props.value : []).filter((user) => {
        return (user && user.userId)
    }).map((user) => {
        return { value: user.userId, label: user.name }
    })
    let lists = Array.from(userMap.values()).filter((user) => {
        return (user && user.userId)
    }).map((user) => {
        return { value: user.userId, label: user.name }
    })

    return (
        <div className="MemberChoicer">
            <UserDialog open={userDialogOpen} canCancel={true}
                onChoice={
                    (user) => {
                        if (props.onChange) {
                            let newValues = values.filter((v) => {
                                return v.value !== user.userId
                            }).map((v) => {
                                return userMap.get(v.value)
                            })
                            newValues.push(user)
                            props.onChange(newValues)
                        }
                        setUserDialogOpen(false)
                    }
                }
                onClose={
                    (event) => {
                        setUserDialogOpen(false)
                    }
                }
            />
            <Select
                isMulti
                isDisabled={props.readOnly}
                isSearchable={true}
                value={values} options={lists}
                style={{ fontSize: "13px" }}
                onChange={(v) => {
                    if (props.onChange) {
                        let newValues = v.map((v) => {
                            return userMap.get(v.value)
                        })
                        props.onChange(newValues)
                    }
                }}
            />
            <div style={{ position: "absolute", height: "30px", bottom: 0, right: 0, left: 0 }}>
                {!props.readOnly ? (
                    <RedIconButton size="small" style={{ display: "inline-block" }} ariaLabel="edit" color="primary"
                        onClick={(event) => {
                            setUserDialogOpen(true)
                        }}
                    >
                        <AddIcon style={{ color: "white" }} />
                    </RedIconButton>
                ) : (
                    <React.Fragment />
                )}
            </div>
        </div >
    )
}

export default MemberChoicer
