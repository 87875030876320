import './FileUploadDialog.css'

import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Dropzone from 'react-dropzone'

const FileUploadDialog = (props) => {
  const [choiseFiles, setChoiceFiles] = useState(null)

  useEffect(() => {
    if (!props.open) {
      setTimeout(() => {
        setChoiceFiles(null)
      }, 500)
    }
  }, [props.open])

  return (
    <div className="FileUploadDialog" style={{ display: "inline", ...props.style }}>
      {/* <Button variant="contained" size="small" color={props.buttonColor} onClick={props.open}>{props.buttonText}</Button> */}
      <Dialog
        open={props.open}
        onClose={(event) => {
          if (props.onClose) {
            props.onClose(event)
          }
        }}
      >
        <DialogTitle style={{ padding: "3px" }} className={"label"}>{props.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "black" }}>
            {
              !choiseFiles ?
                <Dropzone onDrop={acceptedFiles => {
                  setChoiceFiles(acceptedFiles)
                }}
                  maxFiles={1}
                  maxSize={1 * 1024 * 1024}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className="DropZone">ここにファイルをドラッグ＆ドロップするか、<br />クリックしてファイルを選択して下さい</p>
                      </div>
                    </section>
                  )}
                </Dropzone> : <div className="DropZone">{
                  choiseFiles.map((file) => <div>{file.name}</div>)
                }
                </div>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small"
            onClick={() => {
              if (props.onCancel) {
                props.onCancel()
              }
            }} color="inherit">
            キャンセル
          </Button>
          <Button variant="contained" size="small"
            disabled={!choiseFiles}
            onClick={() => {
              if (props.onChoiceFile) {
                props.onChoiceFile(choiseFiles[0])
              }
            }} color="primary" autoFocus>
            インポート
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  )
}

export default FileUploadDialog