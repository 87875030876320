import { http } from "./Config";

const requestPath = "/v2/constreports";

export const save = (constData) => {
    return http.post(`${requestPath}`, constData, {
        headers: {
            "content-type": "application/json",
        },
    })
}

export const check = (id) => {
    return http.put(`${requestPath}/check?id=${id}`, '', {
        headers: {
            "content-type": "application/json",
        },
    })
}

export const loadById = (id, readOnly) => {
    return http.get(`${requestPath}/${id}?readOnly=${readOnly ? 'true' : 'false'}`)
}

export const load = (condition) => {
    return http.get(`${requestPath}?date=${condition.date}&constId=${condition.constId}&userId=${condition.userId}`)
}

export const loadDates = (constId) => {
    return http.get(`${requestPath}/dates?constId=${constId}`)
}

const exports = {
    save,
    check,
    load,
    loadById,
    loadDates,
}

export default exports;
