import './Setting.css'
import { displaySettingState } from "../hooks/DisplaySettingState"
import InfoBox from "./InfoBox"
import CellInput from "./CellInput"
import AreaChoicer from "./AreaChoicer"

import { GreenIconButton } from './ColorButton'
import { getInitialPersonalSetting, ROLES } from '../utils/Utils'

import { sessionUserState } from '../hooks/SessionUserState'
import { errorState } from '../hooks/ErrorState'
import { messageState } from '../hooks/MessageState'

import MeService from "../../services/MeService"

import React, { useState, useEffect } from 'react'
import { Paper, Checkbox } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import { Transition } from 'react-transition-group'
import { useRecoilState, useSetRecoilState } from 'recoil'

const Setting = (props) => {
    const [settingPanel, setSettingPanel] = useRecoilState(displaySettingState)
    const transitionStyles = {
        entering: { top: '40px', opacity: 1.0, transition: 'all 300ms ease' },
        entered: { top: '40px', opacity: 1.0 },
        exiting: { top: '-1000px', opacity: 0.0, transition: 'all 300ms ease' },
        exited: { top: '-1000px', opacity: 0.0 },
    }

    const [userData, setUserData] = useRecoilState(sessionUserState)
    const [required, setRequired] = useState(false)
    const [personalSetting, setPersonalSetting] = useState(getInitialPersonalSetting())
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
    const setMessage = useSetRecoilState(messageState) // メッセージ表示用

    const displayMessage = (title, msg) => {
        setMessage({ msg: msg, open: true, title: title })
    }

    const load = () => {
        Promise.all([
            MeService.findPersonalSetting()
        ])
            .then(
                response => {
                    let data = response[0].data
                    setRequired(!data)
                    setPersonalSetting(data ? data : getInitialPersonalSetting(userData.userId))
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const put = () => {
        MeService.putPersonalSetting(personalSetting || required)
            .then(
                response => {
                    setUserData({ ...userData, personalSetting: personalSetting })
                    displayMessage("登録完了", "登録が完了しました。")
                    setRequired(false)
                    setSettingPanel(false)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    useEffect(
        () => {
            if (userData.userId) {
                load()
            }
        }
        , [userData])

    return (
        <Transition in={settingPanel || required} timeout={1500}>
            {(state) => (
                <Paper style={transitionStyles[state]} className="Setting" onClick={(event) => { event.stopPropagation() }}>
                    <div style={{ margin: "5px" }}>個人設定</div>
                    <div style={{ display: required ? "block" : "none", color: "red", fontWeight: "bold", textAlign: "center", fontSize: "18px" }}>初期設定を行ってください。</div>
                    <div style={{ margin: "10px" }}>
                        <InfoBox title="エリア">
                            <AreaChoicer value={personalSetting.area} onChange={(event) => {
                                setPersonalSetting({ ...personalSetting, area: event.target.value })
                            }} />
                        </InfoBox>
                    </div>
                    {/* <div style={{ margin: "10px" }}>
                        <InfoBox title={"業務日報上長"}>
                            <UserChoicer value={personalSetting.workSuperiorId}
                                onChoiceUser={(userId) => {
                                    setPersonalSetting({ ...personalSetting, workSuperiorId: userId });
                                }} />
                        </InfoBox>
                    </div>
                    <div style={{ margin: "10px" }}>
                        <InfoBox title={"工事日報上長"} >
                            <UserChoicer value={personalSetting.constSuperiorId}
                                onChoiceUser={(userId) => {
                                    setPersonalSetting({ ...personalSetting, constSuperiorId: userId });
                                }} />
                        </InfoBox>
                    </div> */}
                    {/* <div style={{ margin: "5px" }}>通知設定</div>
                    <div style={{ margin: "10px" }}>
                        <InfoBox title={"案内"}>
                            <Checkbox size="small" style={{ padding: "3px" }} id="guidanceNotice" checked={personalSetting.guidanceNotice} inputProps={{ 'aria-label': 'controlled' }} onChange={(event) => {
                                setPersonalSetting({ ...personalSetting, guidanceNotice: event.target.checked })
                            }} />
                            <label for="guidanceNotice">案内を通知する</label>
                        </InfoBox>
                    </div>

                    <div style={{ margin: "10px" }}>
                        <InfoBox title={"業務日報エラー"}>
                            <Checkbox size="small" style={{ padding: "3px" }} id="workReportErrorNotice" checked={personalSetting.workReportErrorNotice} inputProps={{ 'aria-label': 'controlled' }} onChange={(event) => {
                                setPersonalSetting({ ...personalSetting, workReportErrorNotice: event.target.checked })
                            }} />
                            <label for="workReportErrorNotice">業務日報のエラーを通知する</label>
                        </InfoBox>
                    </div>

                    <div style={{ margin: "10px" }}>
                        <InfoBox title={"業務日報否認"}>
                            <Checkbox size="small" style={{ padding: "3px" }} id="workReportDisapproveNotice" checked={personalSetting.workReportDisapproveNotice} inputProps={{ 'aria-label': 'controlled' }} onChange={(event) => {
                                setPersonalSetting({ ...personalSetting, workReportDisapproveNotice: event.target.checked })
                            }} />
                            <label for="workReportDisapproveNotice">業務日報の否認を通知する</label>
                        </InfoBox>
                    </div>

                    <div style={{ margin: "10px" }}>
                        <InfoBox title={"工事日報コメント"}>
                            <Checkbox size="small" style={{ padding: "3px" }} id="constReportCommentNotice" checked={personalSetting.constReportCommentNotice} inputProps={{ 'aria-label': 'controlled' }} onChange={(event) => {
                                setPersonalSetting({ ...personalSetting, constReportCommentNotice: event.target.checked })
                            }} />
                            <label for="constReportCommentNotice">工事日報のコメントを通知する</label>
                        </InfoBox>
                    </div> */}

                    {userData.roles.includes(ROLES.ADMINISTRATOR) ? (
                        <React.Fragment>
                            <div style={{ margin: "5px" }}>承認設定</div>
                            <div style={{ margin: "10px" }}>
                                <InfoBox title="一括承認">
                                    <Checkbox size="small"
                                        style={{ padding: "3px" }}
                                        id="targetOnly"
                                        color="secondary"
                                        checked={personalSetting.bulkUpdate}
                                        onChange={(event) => {
                                            setPersonalSetting({ ...personalSetting, bulkUpdate: event.target.checked })
                                        }} />
                                    <label htmlFor="targetOnly">一括承認を行う</label>
                                </InfoBox>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment />
                    )}
                    <div style={{ margin: "5px" }}>直近データ記憶数</div>
                    <div style={{ margin: "10px" }}>
                        <InfoBox title="工番">
                            <span style={{ display: "flex" }}>
                                <CellInput value={personalSetting.memoryConstId} type="number" style={{ width: "100px", textAlign: "right", }} onChange={(event) => {
                                    setPersonalSetting({ ...personalSetting, memoryConstId: event.target.value })
                                }} />
                                <div>個</div>
                            </span>
                        </InfoBox>
                    </div>
                    <div style={{ margin: "10px", height: "30px" }}>
                        <GreenIconButton size="small"
                            style={{ position: "absolute", right: "14px", bottom: "18px", height: "auto" }}
                            disabled={!personalSetting.area}
                            // disabled={!}
                            color="primary"
                            onClick={(e) => {
                                put()
                            }}>
                            <DoneIcon style={{ color: "white" }} />
                        </GreenIconButton>
                    </div>
                </Paper>
            )}
        </Transition>
    )
}

export default Setting