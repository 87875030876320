import './Footer.css'

import React from 'react'

const Footer = (props) => {
    return (
        <div className={"Footer" + (props.className ? " " + props.className : "")}>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                {props.children}
            </div>
        </div>
    )
}

export default Footer