import './WorkReport.css'

import CellInput from '../common/CellInput'
import ConstIdDialog from '../common/ConstIdDialog'
import InfoBox from '../common/InfoBox'
import UserName from '../common/UserName'
import Overtime from './workReport/Overtime'

import { RedIconButton, BlueIconButton } from '../common/ColorButton'
import Good from '../common/Good'

import { formatDate, getInitialWorkRow } from '../utils/Utils'
import { constListData } from '../hooks/ConstListData'

import { sessionUserState } from '../hooks/SessionUserState'

import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import DateRangeIcon from '@mui/icons-material/DateRange'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { IconButton } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { MediaPc, MediaSmartPhone } from '../common/Responsive'

const WorkReport = (props) => {
    const constMap = useRecoilValue(constListData)
    const [constIdRowIdx, setConstIdRowIdx] = useState(null)
    const userData = useRecoilValue(sessionUserState)

    let workReport = props.value
    let page = props.page

    if (!workReport) {
        return null
    }
    return (
        <div onClick={(event) => {
            if (props.onClick) {
                props.onClick(event)
            }
        }}>
            <ConstIdDialog
                canCancel={true}
                open={constIdRowIdx != null}
                onChoice={(project) => {
                    let row = constIdRowIdx.mode === "today" ? workReport.times[constIdRowIdx.row].today : workReport.times[constIdRowIdx.row].next
                    // 工番を手入力した場合、工事名称が未設定になる為、設定する
                    row.const = project
                    if (constIdRowIdx.mode === "today") {
                        workReport.times[constIdRowIdx.row].today = row
                    } else {
                        workReport.times[constIdRowIdx.row].next = row
                    }
                    props.onChangeWorkReport({ ...workReport })
                    setConstIdRowIdx(null)
                }}
                onClose={(event) => {
                    setConstIdRowIdx(null)
                }}
            />

            <div style={{ maxWidth: "900px", display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <InfoBox title={"氏名"} >
                    <UserName userId={workReport.accountId} />
                </InfoBox>
                {/* <InfoBox title={"上長"} >
                    <UserChoicer error={workReport?.superiorIdError} value={workReport.superiorId} readOnly={props.readOnly} onChoiceUser={(userId) => {
                        props.onChangeWorkReport({ ...workReport, superiorId: userId })
                    }} />
                </InfoBox> */}
                <InfoBox title={"上長"} >
                    <UserName userId={workReport.superiorId} />
                </InfoBox>
            </div>

            <div className="WorkReport" {...props} style={{ width: "100%", position: "relative" }}>
                <div className="workHeader">
                    <MediaPc>
                        <div className="constTitle time">
                            <span>項目</span>
                        </div>
                        <div className="constHeader today" style={{ display: "block", textAlign: "center" }}>
                            <span>当日実績 / 工番</span><br />
                        </div>
                        <div className="constHeader tomorrow" style={{ display: "block", textAlign: "center" }}>
                            <span>次の日の予定</span><br />
                        </div>
                    </MediaPc>
                    <MediaSmartPhone>
                        {page === "today" ? (
                            <div className="constHeader today" style={{ display: "block", textAlign: "center", minWidth: "100%" }}>
                                <span style={{ fontSize: "30px" }}>当日実績 / 工番</span><br />
                            </div>
                        ) : (
                            <div className="constHeader tomorrow" style={{ display: "block", textAlign: "center", minWidth: "100%" }}>
                                <span style={{ fontSize: "30px" }}>次の日の予定</span><br />
                            </div>
                        )}
                    </MediaSmartPhone>

                </div>
                <div className="workDate" >
                    <div className="constTitle time">
                        <span>日付</span>
                    </div>
                    <MediaPc>
                        <div className="workCell today">
                            <div style={{ textAlign: "center", width: "100%" }}>{formatDate(workReport.dates.today)}
                                <span className="mouseOpe">
                                    <DateRangeIcon size="small" style={{ verticalAlign: "bottom" }}
                                        onClick={(event) => {
                                            if (props.onChoiceDate) {
                                                props.onChoiceDate(event)
                                            }
                                        }}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="workCell tomorrow">
                            <div style={{ textAlign: "center", width: "100%" }}>{formatDate(workReport.dates.next)}</div>
                        </div>
                    </MediaPc>
                    <MediaSmartPhone>
                        {page === "today" ? (
                            <div className="workCell today">
                                <div style={{ textAlign: "center", width: "100%" }}>{formatDate(workReport.dates.today)}
                                    <span className="mouseOpe">
                                        <DateRangeIcon size="small" style={{ verticalAlign: "bottom" }}
                                            onClick={(event) => {
                                                if (props.onChoiceDate) {
                                                    props.onChoiceDate(event)
                                                }
                                            }}
                                        />
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className="workCell tomorrow">
                                <div style={{ textAlign: "center", width: "100%" }}>{formatDate(workReport.dates.next)}</div>
                            </div>
                        )}
                    </MediaSmartPhone>
                </div>

                <div className="workOvertime">
                    <div className="constTitle time">
                        <span>残業</span>
                    </div>
                    <MediaPc>
                        <div className="workCell today" style={{ justifyContent: "left" }}>
                            <Overtime value={workReport.todayOvertime} readOnly={true} onChangeOvertime={(o) => {
                                props.onChangeWorkReport({ ...workReport, todayOvertime: o })
                            }} />
                        </div>
                        <div className="workCell tomorrow" style={{ justifyContent: "left" }}>
                            <Overtime value={workReport.nextOvertime} readOnly={props.readOnly} onChangeOvertime={(o) => {
                                props.onChangeWorkReport({ ...workReport, nextOvertime: o })
                            }} />
                        </div>
                    </MediaPc>
                    <MediaSmartPhone>
                        {page === "today" ? (
                            <div className="workCell today" style={{ justifyContent: "left", width: "100%" }}>
                                <Overtime value={workReport.todayOvertime} readOnly={true} onChangeOvertime={(o) => {
                                    props.onChangeWorkReport({ ...workReport, todayOvertime: o })
                                }} />
                            </div>
                        ) : (
                            <div className="workCell tomorrow" style={{ justifyContent: "left", width: "100%" }}>
                                <Overtime value={workReport.nextOvertime} readOnly={props.readOnly} onChangeOvertime={(o) => {
                                    props.onChangeWorkReport({ ...workReport, nextOvertime: o })
                                }} />
                            </div>
                        )}
                    </MediaSmartPhone>
                </div>

                <div>
                    {workReport.times.map((v, idx) => {
                        return (
                            <div key={idx} className="workRow">
                                <div className="constTitle time">
                                    <Time readOnly={idx !== 0 || props.readOnly} value={v.time}
                                        onChange={(value) => {
                                            // 入力された時刻から始まる連番で更新する
                                            let time = value
                                            let times = workReport.times.map((v, idx2) => {
                                                v.time = time ? time + idx2 : null
                                                return v
                                            })
                                            props.onChangeWorkReport({ ...workReport, times: times })
                                        }}></Time>
                                </div>
                                <MediaPc>
                                    <div className="workCell today">
                                        <WorkReportInput
                                            color={(props.colors && props.colors.get(v.time)) ? props.colors.get(v.time) : null} value={v.today} readOnly={props.readOnly}
                                            onChange={(v) => {
                                                // 工番を手入力した場合、工事名称が未設定になる為、設定する
                                                if (v.const.constId) {
                                                    let c = constMap.get(v.const.constId)
                                                    v.const.name = c ? c.name : ''
                                                }
                                                workReport.times[idx].today = v
                                                props.onChangeWorkReport({ ...workReport })
                                            }}
                                            onConstIdClick={(event) => {
                                                setConstIdRowIdx({ mode: "today", row: idx })
                                            }}
                                        />
                                    </div>
                                    <div className="workCell tomorrow">
                                        <WorkReportInput
                                            value={v.next} readOnly={props.readOnly}
                                            onChange={(v) => {
                                                // 工番を手入力した場合、工事名称が未設定になる為、設定する
                                                if (v.const.constId) {
                                                    let c = constMap.get(v.const.constId)
                                                    v.const.name = c ? c.name : ''
                                                }
                                                workReport.times[idx].next = v
                                                props.onChangeWorkReport({ ...workReport })
                                            }}
                                            onConstIdClick={(event) => {
                                                setConstIdRowIdx({ mode: "next", row: idx })
                                            }}
                                        />
                                    </div>

                                </MediaPc>
                                <MediaSmartPhone>
                                    {page === "today" ? (
                                        <div className="workCell today">
                                            <WorkReportInput
                                                color={(props.colors && props.colors.get(v.time)) ? props.colors.get(v.time) : null} value={v.today} readOnly={props.readOnly}
                                                onChange={(v) => {
                                                    // 工番を手入力した場合、工事名称が未設定になる為、設定する
                                                    if (v.const.constId) {
                                                        let c = constMap.get(v.const.constId)
                                                        v.const.name = c ? c.name : ''
                                                    }
                                                    workReport.times[idx].today = v
                                                    props.onChangeWorkReport({ ...workReport })
                                                }}
                                                onConstIdClick={(event) => {
                                                    setConstIdRowIdx({ mode: "today", row: idx })
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div className="workCell tomorrow">
                                            {/* <CellInput value={v.next.text} readOnly={props.readOnly} onChange={(event) => {
                                                workReport.times[idx].next.text = event.target.value
                                                props.onChangeWorkReport({ ...workReport })
                                            }} /> */}
                                            <WorkReportInput
                                                value={v.next} readOnly={props.readOnly}
                                                onChange={(v) => {
                                                    // 工番を手入力した場合、工事名称が未設定になる為、設定する
                                                    if (v.const.constId) {
                                                        let c = constMap.get(v.const.constId)
                                                        v.const.name = c ? c.name : ''
                                                    }
                                                    workReport.times[idx].next = v
                                                    props.onChangeWorkReport({ ...workReport })
                                                }}
                                                onConstIdClick={(event) => {
                                                    setConstIdRowIdx({ mode: "next", row: idx })
                                                }}
                                            />
                                        </div>
                                    )}
                                </MediaSmartPhone>
                            </div>
                        )
                    })}
                </div>
                {!props.readOnly ? (
                    <div style={{ position: "relative", height: "30px", margin: "10px" }}>
                        <RedIconButton size="small" style={{ display: "inline-block" }} ariaLabel="edit" color="primary" onClick={() => {
                            let row = getInitialWorkRow()
                            let time = workReport.times[workReport.times.length - 1].time
                            row.time = time + 1
                            workReport.times.push(row)
                            props.onChangeWorkReport({ ...workReport })
                        }}>
                            <AddIcon style={{ color: "white" }} />
                        </RedIconButton>
                        <div style={{ width: "10px", display: "inline-block" }} />
                        <BlueIconButton size="small" style={{ display: "inline-block" }} ariaLabel="edit" color="primary" disabled={workReport.times.length <= 1} onClick={() => {
                            workReport.times.pop()
                            props.onChangeWorkReport({ ...workReport })
                        }}>
                            <RemoveIcon style={{ color: "white" }} />
                        </BlueIconButton>
                    </div>
                ) : (
                    <React.Fragment />
                )}
                <Good value={workReport.todayId} readOnly={!props.readOnly} userId={userData.userId} date={workReport.dates.today} targetUserId={workReport.accountId} />
                {props.children}
            </div >
        </div>
    )
}

const WorkReportInput = (props) => {
    let v = props.value
    return (
        <div style={{ display: "flex", flexWrap: "nowrap", width: "100%", alignItems: "stretch" }}>
            <div style={{
                backgroundColor: "white",
                width: "7px",
                top: "0",
                bottom: "0",
                display: "block",
                position: "relative",
                visibility: props.color ? "visible" : "hidden",
                backgroundImage: props.color ? "url(" + props.color + ")" : null,
                backgroundSize: "contain"
            }}>
            </div>

            <div style={{ display: "relative", flex: 1 }}>
                <CellInput value={v.text} readOnly={props.readOnly} onChange={(event) => {
                    v.text = event.target.value
                    props.onChange(v)
                }} />
            </div>
            <div style={{ display: "relative", width: "2px", height: "100%" }} />
            <div style={{ display: "relative", width: "60px" }}>
                <CellInput
                    value={v.const.constId}
                    readOnly={props.readOnly}
                    title={v.const.name}
                    style={{ textAlign: "center", color: (v.const.constId !== "1" && v.const.constId !== "2" && v.const.constId && !v.const.name) ? "red" : "black" }}
                    onChange={(event) => {
                        let constId = event.target.value
                        v.const.constId = constId.toUpperCase()
                        props.onChange(v)
                    }}
                />
            </div>
            <div style={{ display: "relative", width: "5px", height: "100%" }} />
            {!props.readOnly ? (
                <IconButton size="small"
                    style={{ display: "relative", width: "20px" }}
                    ariaLabel="search" color="primary"
                    onClick={(event) => {
                        if (props.onConstIdClick) {
                            props.onConstIdClick(event)
                        }
                    }}>
                    <SearchIcon style={{ fontSize: 14 }} />
                </IconButton>

            ) : (
                <React.Fragment />
            )}
        </div>
    )
}

const Time = (props) => {
    if (props.readOnly) {
        return (
            <div className="timeReadOnly" style={{ display: "block" }}>{props.value}</div>
        )
    } else {
        return (
            <div style={{ textAlign: "right", marginTop: "0px" }}>
                <TextField className="timeInput" type="number" style={{}}
                    InputProps={{ inputProps: { min: 0, max: 24, style: { textAlign: "right", height: "20px", padding: "0px" } } }}
                    value={props.value}
                    onChange={(event) => {
                        if (props.onChange) {
                            props.onChange(parseInt(event.target.value))
                        }
                    }}
                />
            </div>
        )
    }
}

export default WorkReport
