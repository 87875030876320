import './Content.css'

import CheckAuth from './CheckAuth'
import ProjectService from '../../services/ProjectService'
import UserService from '../../services/UserService'
import SafetyInspectionService from '../../services/SafetyInspectionService'

import { displayMenuState } from '../hooks/DisplayMenuState'
import { sessionUserState } from '../hooks/SessionUserState'
import { constListData } from '../hooks/ConstListData'
import { userListData } from '../hooks/UserListData'
import { userBufferData } from '../hooks/UserBufferState'
import { errorState } from '../hooks/ErrorState'
import { displaySettingState } from '../hooks/DisplaySettingState'
import { safetyInspectionState } from '../hooks/SafetyInspectionState'

import React, { useState, useEffect } from 'react'
import { Transition } from 'react-transition-group'
import { useSetRecoilState, useRecoilValue } from 'recoil'

const Content = ({ children, role }) => {
    const userData = useRecoilValue(sessionUserState)
    const [open, setOpen] = useState(false)
    const setMenuState = useSetRecoilState(displayMenuState)
    const setConstList = useSetRecoilState(constListData) // 案件一覧データ（マップ構造）
    const setUserList = useSetRecoilState(userListData) // アカウント一覧データ（マップ構造）
    const setUserBuffer = useSetRecoilState(userBufferData)
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
    const setSettingPanel = useSetRecoilState(displaySettingState)
    const setSafetyInspection = useSetRecoilState(safetyInspectionState)

    useEffect(() => {
        const fields = []
        Promise.all(
            [
                ProjectService.findAll(null, null, fields),
                UserService.findAll(null, null, fields),
                UserService.findUserBuffer(),
                SafetyInspectionService.findAll()
            ]
        ).then(
            response => {
                let map = new Map()
                response[0].data.objects.forEach((v) => {
                    map.set(v.constId, v)
                })
                // state更新
                setConstList(map);

                map = new Map()
                response[1].data.objects.forEach((v) => {
                    map.set(v.userId, v)
                })
                // state更新
                setUserList(map);

                let userMap = new Map()
                response[2].data.objects.forEach((v) => {
                    userMap.set(v.userId, v.userName)
                })
                // state更新
                setUserBuffer(userMap)

                setSafetyInspection(response[3].data)

                setOpen(true)
            },
            error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            }
        ).catch(error => {
            setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
    }, [])

    const transitionStyles = {
        entering: { opacity: 1, transition: 'all 1500ms ease' },
        entered: { opacity: 1 },
        exiting: { opacity: 0, transition: 'all 1500ms ease' },
        exited: { opacity: 0 },
    }

    return (
        <div className="Content" onClick={(e) => {
            // 主コンテンツがクリックされた場合、メニューを消す
            setMenuState(false)
            setSettingPanel(false)
            // setSettingPanel(false)
        }}>
            {userData.userId && open ? (
                <Transition in={open} timeout={1500}>
                    {(state) => (
                        <div style={transitionStyles[state]}>
                            <CheckAuth role={role}>
                                {children}
                            </CheckAuth>
                        </div>
                    )}
                </Transition>
            ) : (
                <React.Fragment />
            )}
        </div>
    )
}

export default Content