import './LaborCostList.css'

import Header from './common/Header'
import Content from './common/Content'
import Footer from './common/Footer'
import { MediaPc } from './common/Responsive'
import { createSVGBands } from './common/SVGBand'
import {
  addSeparator,
} from './common/NumberInput'

import { ROLES } from './utils/Utils'
// import { formatDate } from './utils/Utils'
import { constListData } from './hooks/ConstListData'
import { getCurrentFiscalYear, getFiscalPeriodDates } from './utils/FiscalYearUtils'
import { errorState } from './hooks/ErrorState'

import ExcelService from '../services/ExcelService'
import LaborCostsService from '../services/LaborCostsService'

import { Box, Button } from '@mui/material'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import {
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material"

const monthList1st = [8, 9, 10, 11, 12, 1]
const monthList2nd = [2, 3, 4, 5, 6, 7]

const currentFiscalYear = getCurrentFiscalYear()
const years = Array.from({ length: 5 }, (_, i) => currentFiscalYear - 2 + i)

const LaborCostList = (props) => {
  const [key, setKey] = useState({ constId: props.constId, fiscalYear: getCurrentFiscalYear() })

  const constMap = useRecoilValue(constListData) // 工事情報データ（マップ構造）
  const [laborCosts, setLaborCosts] = useState([])
  const setError = useSetRecoilState(errorState) // エラーメッセージ表示用

  useEffect(() => {
    if (key.fiscalYear) {
      loadLaborCosts(key.fiscalYear)
    }
  }, [key])

  const loadLaborCosts = (fiscalYear) => {
    LaborCostsService.getAll(fiscalYear)
      .then(res => {
        Object.values(res.data).forEach((v) => {
          v.details.forEach((detail) => {
            detail.startDate = new Date(detail.startDate)
            detail.endDate = new Date(detail.endDate)
          })
        })
        setLaborCosts(res.data)
      })
      .catch(error => {
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      })
  }

  const exportLaborCosts = () => {
    ExcelService.download("laborCosts", laborCosts)
      .catch(error => {
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      })
  }

  return (
    <Content role={ROLES.LABOR_COSTS}>
      <Header title="人件費管理表" />
      <div className="LaborCostList">
        <div className="control">
          <Select
            value={key.fiscalYear}
            style={{ position: "relative", display: "block", top: "5px", left: "10px", width: "320px" }}
            label="Age"
            onChange={(e) => {
              setKey({ ...key, fiscalYear: e.target.value })
            }}
          >
            {
              years.map((v) => {
                return (<MenuItem value={v}>第{v}期（{getFiscalPeriodDates(v).toString()}）</MenuItem>)
              })
            }
          </Select>
        </div>
        <TableContainer style={{ marginLeft: "5px", maxHeight: "calc(100% - 60px)", maxWidth: "calc(100% - 10px)" }}>
          <Table stickyHeader aria-label="a dense table">
            <TableHead>
              <TableRow >
                <TableCell rowSpan={2} className="constIdTitle">工事</TableCell>
                <TableCell rowSpan={2} className="jobTitle">役職</TableCell>
                <TableCell rowSpan={2} className="user">担当者</TableCell>
                <TableCell rowSpan={2} className="budgetPrice">予算額</TableCell>
                <TableCell rowSpan={2} className="differencePrice">予算額と実績差</TableCell>
                <TableCell rowSpan={2} className="totalOfYear">累計</TableCell>
                <TableCell rowSpan={2} className="totalOEearlyPeriod">前期までの計</TableCell>
                <TableCell rowSpan={2} className="rowTitle"></TableCell>
                <TableCell colSpan={7} className="month1st">上　期</TableCell>
                <TableCell colSpan={7} className="month2nd">下　期</TableCell>
                <TableCell rowSpan={2} className="memo">備考</TableCell>
              </TableRow>
              <TableRow >
                {monthList1st.map((value, index) => (
                  <TableCell className="month1st" key={index}>{value}月</TableCell>
                ))}
                <TableCell className="total1st" key={"total1st"}>上期計</TableCell>
                {monthList2nd.map((value, index) => (
                  <TableCell className="month2nd" key={index}>{value}月</TableCell>
                ))}
                <TableCell className="total2nd" key={"total2nd"}>下期計</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {laborCosts.map((row) => {
                return (<React.Fragment>
                  <TableRow>
                    <TableCell className="constId" colSpan={3}>
                      {row.constId + "：" + (constMap.get(row.constId) ? constMap.get(row.constId).name : "")}
                    </TableCell>
                    <TableCell className="constIdRowSpace" colSpan={20}>
                    </TableCell>
                  </TableRow>
                  {row.details.map((value, index) => {
                    return (<LaborCostListRow key={index} value={value}
                      fiscalYear={key.fiscalYear}
                      index={index}
                      budgetPrice={index === 0 ? row.budgetPrice : null}
                      differencePrice={index === 0 ? row.differencePrice : null}
                      forecastPrice={index === 0 ? row.forecastPrice : null}
                    />)
                  })}
                </React.Fragment>)
              })}

            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Footer>
        <Box style={{ paddingRight: "10px" }}>
          <Button variant="contained" color="primary" onClick={(event) => {
            exportLaborCosts()
          }}><FontAwesomeIcon icon={faFileExcel} size="lg" /><MediaPc>&nbsp;Ｅｘｃｅｌ出力</MediaPc></Button>
        </Box>
      </Footer>
    </Content>
  )
}


const LaborCostListRow = (props) => {
  const [row, setRow] = useState(props.value)
  const [svgBand, setSvgBand] = useState(new Map())

  let monthFirstHalf = row.months.slice(0, 6)
  let monthSecondHalf = row.months.slice(6, 12)

  let totalPlanFirstHalf = monthFirstHalf.reduce((a, b) => { return { workPlanPrice: a.workPlanPrice + b.workPlanPrice } }, { workPlanPrice: 0 }).workPlanPrice
  let totalPlanSecondHalf = monthSecondHalf.reduce((a, b) => { return { workPlanPrice: a.workPlanPrice + b.workPlanPrice } }, { workPlanPrice: 0 }).workPlanPrice
  let totalResultFirstHalf = monthFirstHalf.reduce((a, b) => { return { workResultPrice: a.workResultPrice + b.workResultPrice } }, { workResultPrice: 0 }).workResultPrice
  let totalResultSecondHalf = monthSecondHalf.reduce((a, b) => { return { workResultPrice: a.workResultPrice + b.workResultPrice } }, { workResultPrice: 0 }).workResultPrice

  useEffect(() => {
    setRow(props.value)
  }, [props.value])

  useEffect(() => {
    const p = getFiscalPeriodDates(props.fiscalYear)

    let startDate = p.startDate.getTime() > row.startDate.getTime() ? p.startDate : row.startDate
    let endDate = p.endDate.getTime() < row.endDate.getTime() ? p.endDate : row.endDate

    let s = createSVGBands(startDate, endDate)
    setSvgBand(s)
  }, [props.fiscalYear, row.startDate, row.endDate])

  let caution = props.budgetPrice < props.forecastPrice

  return (
    <React.Fragment>
      <TableRow className="LaborCostListRow">
        <TableCell rowSpan={3} className="constSpace"></TableCell>
        <TableCell rowSpan={3} className="jobTitle">{row.title}</TableCell>
        <TableCell rowSpan={3} className="user">{row.userName}</TableCell>
        <TableCell rowSpan={3} className="budgetPrice">
          <div className="budgetPriceInput">{addSeparator(props.budgetPrice)}</div>
        </TableCell>
        <TableCell rowSpan={3}
          className="differencePrice"
          style={{ backgroundColor: caution ? "#fdd" : "" }}>
          <div className="differencePriceInput" style={{ color: caution ? "red" : "" }}>{addSeparator(props.differencePrice)}</div>
        </TableCell>
        {/* <TableCell rowSpan={3} className="terms">
          {formatDate(row.startDate)} 〜 {formatDate(row.endDate)}
        </TableCell> */}
        <TableCell className="totalOfYear"></TableCell>
        <TableCell className="totalOEearlyPeriod"></TableCell>
        <TableCell className="rowTitle"></TableCell>
        {monthFirstHalf.map((value, index) => {
          let ym = value.year + "-" + value.month.toString().padStart(2, '0')
          let img = svgBand.get(ym)
          return (
            <TableCell className="month1st" key={index}>
              {img ? img : <span></span>}
            </TableCell>
          )
        })}
        <TableCell className="total1st" key={"total1st"}></TableCell>
        {monthSecondHalf.map((value, index) => {
          let ym = value.year + "-" + value.month.toString().padStart(2, '0')
          let img = svgBand.get(ym)
          return (
            <TableCell className="month2nd" key={index}>
              {img ? img : <span></span>}
            </TableCell>
          )
        })}
        <TableCell className="total2nd" key={"total2nd"}></TableCell>
        <TableCell rowSpan={3} className="memo">
          <pre>
            {row.description}
          </pre>
        </TableCell>
      </TableRow>

      <TableRow className="LaborCostListRow">
        <TableCell className="totalOfYear"><span>{addSeparator(row.earlyPeriodPlanPrice + totalPlanFirstHalf + totalPlanSecondHalf)}</span></TableCell>
        <TableCell className="totalOEearlyPeriod"><span>{addSeparator(row.earlyPeriodPlanPrice)}</span></TableCell>
        <TableCell className="rowTitle">予定</TableCell>
        {monthFirstHalf.map((value, index) => (
          <TableCell className="month1st" key={index}><span>{addSeparator(value.workPlanPrice)}</span></TableCell>
        ))}
        <TableCell className="total1st" key={"total1st"}><span>{addSeparator(totalPlanFirstHalf)}</span></TableCell>
        {monthSecondHalf.map((value, index) => (
          <TableCell className="month2nd" key={index}><span>{addSeparator(value.workPlanPrice)}</span></TableCell>
        ))}
        <TableCell className="total2nd" key={"total2nd"}><span>{addSeparator(totalPlanSecondHalf)}</span></TableCell>
      </TableRow>

      <TableRow className="LaborCostListRow">
        <TableCell className="totalOfYear"><span>{addSeparator(row.earlyPeriodPlanPrice + totalResultFirstHalf + totalResultSecondHalf)}</span></TableCell>
        <TableCell className="totalOEearlyPeriod"><span>{addSeparator(row.earlyPeriodResultPrice)}</span></TableCell>
        <TableCell className="rowTitle">実績</TableCell>
        {monthFirstHalf.map((value, index) => (
          <TableCell className="month1st" key={index}><span>{addSeparator(value.workResultPrice)}</span></TableCell>
        ))}
        <TableCell className="total1st" key={"total1st"}><span>{addSeparator(totalResultFirstHalf)}</span></TableCell>
        {monthSecondHalf.map((value, index) => (
          <TableCell className="month2nd" key={index}><span>{addSeparator(value.workResultPrice)}</span></TableCell>
        ))}
        <TableCell className="total2nd" key={"total2nd"}><span>{addSeparator(totalResultSecondHalf)}</span></TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default LaborCostList
