import { IconButton } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'

import React from 'react';

const requestPath = "/ConstManage/v1/constphotos"

const PhotoDownloader = (props) => {
  return (
    <form className="PhotoDownloader"
      action={`${requestPath}/compress?constId=${props.constId}`}
      method="post"
      style={{ display: 'inline' }}
      accept="blob"
      enctype="application/x-www-form-urlencoded"
    >
      <IconButton
        ariaLabel="download"
        fill="none"
        type="submit"
      >
        <DownloadIcon />
      </IconButton>
      <input style={{ display: 'none' }}
        name="photoIds"
        value={JSON.stringify(props.photoIds)} />
    </form>
  )
}

export default PhotoDownloader