import './DateInput.css'

import React, { useState, useEffect } from 'react'

const DateInput = (props) => {
  const [value, setValue] = useState(props.value || '')
  const [error, setError] = useState('')

  // propsの値が変更されたら状態を更新
  useEffect(() => {
    setValue(props.value || '')
  }, [props.value])

  // 日付の検証関数
  const validateDate = (dateString) => {
    if (!dateString) return true

    // 日付形式のチェック（YYYY-MM-DD）
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/
    if (!dateRegex.test(dateString)) {
      setError('正しい日付形式（YYYY-MM-DD）で入力してください')
      return false
    }

    setError('')
    return true
  }

  // 入力変更ハンドラ
  const handleChange = (event) => {
    const newValue = event.target.value
    setValue(newValue)
    validateDate(newValue)

    // propsにonChangeがある場合は呼び出す
    if (props.onChange) {
      props.onChange(event)
    }
  }

  // フォーカスアウトハンドラ
  const handleBlur = (event) => {
    validateDate(value)

    // propsにonBlurがある場合は呼び出す
    if (props.onBlur) {
      props.onBlur(event)
    }
  }

  return (
    <input
      type="date"
      className={"DateInput " + `${props.className || ''} ${error ? 'border-red-500' : ''}`}
      value={value}
      maxLength={props.maxLength || 10}
      readOnly={props.readOnly}

      style={{ ...props.style, backgroundColor: error ? 'red' : 'transparent' }}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}

export default DateInput