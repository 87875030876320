import { MediaPc } from '../common/Responsive'

import Footer from '../common/Footer'

import { financialStatementSaveState } from '../hooks/FinancialStatementSaveState'
import { financialStatementPrintState } from '../hooks/FinancialStatementPrintState'

import { Box, Button } from '@mui/material'
import SaveAltIcon from '@mui/icons-material/SaveAlt'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

import { useSetRecoilState } from 'recoil'

import React from 'react'

const FinancialFooter = (props) => {
  const setFinancialOupputSave = useSetRecoilState(financialStatementSaveState)
  const setFinancialOutputExcel = useSetRecoilState(financialStatementPrintState)

  return (
    <Footer>
      <Box style={{ paddingRight: "10px" }}>
        <Button variant="contained" color="secondary" onClick={(event) => {
          setFinancialOupputSave(true)
        }}><SaveAltIcon /><MediaPc>保存</MediaPc></Button>
      </Box>
      <Box style={{ paddingRight: "10px" }}>
        <Button variant="contained" color="primary" onClick={(event) => {
          setFinancialOutputExcel(true)
        }}><FontAwesomeIcon icon={faFileExcel} size="lg" /><MediaPc>&nbsp;Ｅｘｃｅｌ出力</MediaPc></Button>
      </Box>
    </Footer>
  )
}

export default FinancialFooter