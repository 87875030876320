import './Members.css'

import UserChoicer from '../../common/UserChoicer'

import React, { useEffect, useState } from 'react'

const Members = (props) => {
  const [members, setMembers] = useState(props.value)

  useEffect(() => {
    setMembers(props.value)
  }, [props.value])

  return (
    <div className="Members constRow" >
      <div className="constTitle">
        <span>工事関係者</span>
      </div>
      <div className="constCell">
        <div className="memberContainer" style={{ margin: "3px" }}>
          <div className="userItem director">
            <div className="header">工事部長</div>
            <div>
              <UserChoicer className="constMembers" value={members.director} readOnly={props.readOnly} onChoiceUser={(u) => {
                let m = { ...members, director: u }
                setMembers(m)
                props.onChangeValue(m)
              }} />
            </div>
          </div>
          <div className="userItem manager">
            <div className="header">工事課長</div>
            <div>
              <UserChoicer className="constMembers" value={members.manager} readOnly={props.readOnly} onChoiceUser={(u) => {
                let m = { ...members, manager: u }
                setMembers(m)
                props.onChangeValue(m)
              }} />
            </div>
          </div>
          <div className="userItem supervisor">
            <div className="header">上長</div>
            <div>
              <UserChoicer className="constMembers" value={members.supervisor} readOnly={props.readOnly} onChoiceUser={(u) => {
                let m = { ...members, supervisor: u }
                setMembers(m)
                props.onChangeValue(m)
              }} />
            </div>
          </div>
          <div className="userItem worker">
            <div className="header">工事担当者</div>
            <div>
              <UserChoicer className="constMembers" value={members.worker} readOnly={props.readOnly} onChoiceUser={(u) => {
                let m = { ...members, worker: u }
                setMembers(m)
                props.onChangeValue(m)
              }} />
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Members