import './ConstInformations.css'

import ConstStatus from '../../common/ConstStatus'
import ProjectService from '../../../services/ProjectService'

import Map from '../../common/Map'
import Weather from '../../common/Weather'
import Thumbnail from '../../common/Thumbnail'
import { constListData } from '../../hooks/ConstListData'
import { messageState } from '../../hooks/MessageState'
import { addSeparator } from '../../common/NumberInput'
import JpnDate from '../../common/JpnDate'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import React, { useState, useEffect } from 'react'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  LinearProgress,
  Stack,
} from '@mui/material'
import {
  CalendarToday as CalendarIcon,
  AccessTime as ClockIcon,
  WbSunny as WbSunnyIcon,
  SsidChart as SsidChartIcon,
  CameraAlt as CameraAltIcon,
  LocationOn as MapPinIcon,
  CurrencyYen as CurrencyYenIcon,
  Percent as PercentIcon,
} from '@mui/icons-material'

import 'leaflet/dist/leaflet.css'
const ConstInformations = (props) => {
  const constMap = useRecoilValue(constListData) // 工事情報データ（マップ構造）
  const [constData, setConstData] = useState({})
  const setMessage = useSetRecoilState(messageState) // メッセージ表示用

  useEffect(() => {
    let c = constMap.get(props.constId)
    if (c) {
      ProjectService.overview(props.constId)
        .then(result => {
          let overview = result.data.objects
          setConstData({ ...c, ...overview })
        }, error => {
          setMessage({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })
        .catch(error => {
          setMessage({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        })

    }
  }, [props.constId, constMap])

  return (
    <div className="ConstInformations">
      <Card className="Panel ProjectOverview" >
        <CardContent>
          <Stack spacing={1}>
            <Typography>
              {constData.constId}：{constData.name}
            </Typography>
            <hr />
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <CalendarIcon sx={{ mr: 1, color: 'grey.500' }} />
              契約工期： <JpnDate value={constData.termFrom} /><span>〜</span><JpnDate value={constData.termTo} />
            </Typography>
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <ClockIcon sx={{ mr: 1, color: 'grey.500' }} />
              ステータス：<ConstStatus value={constData.process} />
            </Typography>
            <Typography sx={{ display: 'flex', alignItems: 'center' }}>
              <CurrencyYenIcon sx={{ mr: 1, color: 'grey.500' }} />
              受注額：<ConstStatus value={addSeparator(constData.price)} />
            </Typography>
          </Stack>
        </CardContent>
      </Card>

      <Card className="Panel ProjectPhotos" >
        <CardHeader style={{ height: "30px" }} avatar={<CameraAltIcon />} title={<strong>直近の工事写真</strong>} />
        <CardContent>
          <div style={{ width: "100%", display: "flex", justifyContent: "left", flexWrap: "wrap" }}>
            {constData.photos?.map((photo, index) => (
              <Thumbnail maxHeight="80px" minHeight="80px" index={index} value={{ photoId: photo._id, ...photo }} size="m" onClickPicture={(idx) => {
              }} />
            ))}
          </div>
        </CardContent>
      </Card>

      <Card className="Panel ProjectLocation" >
        <Map address={constData.city} style={{ width: "100%", height: "200px" }} />
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          <MapPinIcon sx={{ mr: 1, color: 'grey.500' }} />
          <div className="OverflowText" style={{ width: "100%" }}>{constData.city}</div>
        </Typography>
      </Card>

      <Card className="Panel Weather" >
        <CardHeader style={{ height: "30px" }} avatar={<WbSunnyIcon />} title={<strong>現場の今の天気</strong>} />
        <Weather address={constData.city} style={{ width: "100%", height: "200px" }} />
      </Card>

      <Card className="Panel ProjectProgress" >
        <CardHeader style={{ height: "30px" }} avatar={<PercentIcon />} title={<strong>現在の出来高</strong>} />
        <CardContent style={{ display: "flex", padding: "30px 0px 0px 0px", width: "100%", justifyContent: "center" }}>
          <div style={{ fontSize: "40px" }}>{constData.progress}%</div>
        </CardContent>
      </Card>

      <Card className="Panel ProjectStatus" >
        <CardHeader style={{ height: "30px" }} avatar={<SsidChartIcon />} title={<strong>収支の状況</strong>} />
        <CardContent>
          <Stack spacing={1}>
            <div style={{ display: "flex", alignItems: "center", height: "25px" }}>
              <Box sx={{ minWidth: "100px" }}>
                <p>予算消化率</p>
              </Box>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={constData.budgetRate * 100} />
              </Box>
              <Box sx={{ minWidth: "35px" }}>
                <div>{Math.round(constData.budgetRate * 100)}%</div>
              </Box>
            </div>
            <div style={{ display: "flex", alignItems: "center", height: "25px" }}>
              <Box sx={{ minWidth: "100px" }}>
                <p>請求済み率</p>
              </Box>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={constData.billingRate * 100} />
              </Box>
              <Box sx={{ minWidth: "35px" }}>
                <div>{Math.round(constData.billingRate * 100)}%</div>
              </Box>
            </div>
            <div style={{ display: "flex", alignItems: "center", height: "25px" }}>
              <Box sx={{ minWidth: "100px" }}>
                <p>入金済み率</p>
              </Box>
              <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={constData.depositRate * 100} />
              </Box>
              <Box sx={{ minWidth: "35px" }}>
                <div>{Math.round(constData.depositRate * 100)}%</div>
              </Box>
            </div>
          </Stack>
        </CardContent>
      </Card>

    </div>
  )
}

export default ConstInformations
