import './WorkReportList.css'

import { ROLES } from './utils/Utils'

import Header from './common/Header'
import Footer from './common/Footer'
import Content from './common/Content'
import MainContent from './common/MainContent'
import WorkReport from './report/WorkReport'
import InfoBox from './common/InfoBox'
import DateTime from './common/DateTime'
import UserChoicer from './common/UserChoicer'
import WorkReportHistory from './report/WorkReportHistory'
import CellInput from './common/CellInput'
import { MediaPc } from './common/Responsive'
import { getInitialWorkRow, STATUS } from './utils/Utils'
import { MediaSmartPhone } from './common/Responsive'

import ReportListService from '../services/ReportListService'
import WorkReportService from '../services/WorkReportService'
import PdfService from '../services/PdfService'

import { constListData } from './hooks/ConstListData'
import { errorState } from './hooks/ErrorState'

import React, { useState, useEffect, useMemo } from 'react'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { IconButton, Box, Button } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'

const fmt = (dt) => {
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    return `${y}-${m}-${d}`
}

const dateAdd = (dt, day) => {
    dt.setDate(dt.getDate() + day)
    return dt
}

const background = {
    approve: "#dfd",
    disapprove: "#fdd",
    hold: "#fff",
    none: "#fff"
}

const searchConditionState = atom({
    key: 'workReportList.searchCondition',
    default: { userId: '', start: fmt(dateAdd(new Date(), -2)), end: fmt(new Date()), targetOnly: false },
})

const WorkReportList = (props) => {
    const [workReportList, setWorkReportList] = useState([])
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
    const [condition, setCondition] = useRecoilState(searchConditionState)
    const [page, setPage] = useState("today")
    const constList = useRecoilValue(constListData) // 案件一覧データ（マップ構造）

    const initialCondition = useMemo(() => {
        return { userId: '', start: fmt(dateAdd(new Date(), -2)), end: fmt(new Date()), targetOnly: false }
    }, [])

    useEffect(() => {
        setCondition(initialCondition)
    }, [initialCondition]);

    const loadWorkReportList = (key) => {
        ReportListService.loadWorkReport(key)
            .then(
                response => {
                    let reportListDatas = response.data.objects.map((rowData) => {
                        return { today: { ...rowData.workReport.workReportResult, }, next: rowData.workReport.workReportPlan, approve: rowData.workApprove }
                    })
                    setWorkReportList(reportListDatas)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const printPdf = () => {
        PdfService.download("workreport", { data: workReportList })
            .catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    return (
        <Content role={ROLES.WORKREPORT_PRINT}>
            <Header title="業務日報印刷" />
            <MainContent className="WorkReportList">
                <SearchCondition value={condition} onChangeSearchCondition={(condition) => {
                    setCondition(condition)
                    setWorkReportList([])
                    loadWorkReportList(condition)
                }} />
                <div className="list">
                    {
                        workReportList.map((r) => {
                            return (
                                <React.Fragment>
                                    <div key={workReportList._id} style={{ position: "relative", margin: "5px", display: "flex", flexDirection: "row" }}>
                                        <div className="outlinePanel" variant="outlined">
                                            <div style={{ position: "relative", width: "100%" }}>
                                                <WorkReportRow constList={constList} key={r._id} page={page} value={r} onError={(e) => {
                                                    setError(e)
                                                }} />
                                                <hr style={{ border: "2px solid #0000", margin: "0px", width: "calc(100% - 20px)" }} />
                                            </div>
                                        </div>
                                    </div >
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </MainContent>
            <Footer>
                <MediaSmartPhone>
                    <PrevArrow page={page} onClick={() => {
                        setPage("today")
                    }} />
                </MediaSmartPhone>
                <Box color="inherit" style={{ paddingRight: "10px" }}>
                    <Button variant="contained" onClick={(event) => {
                        printPdf()
                    }}><PictureAsPdfIcon /><MediaPc>業務日報(PDF)</MediaPc></Button>
                </Box>
                <MediaSmartPhone>
                    <NextArrow page={page} onClick={() => {
                        setPage("next")
                    }} />
                </MediaSmartPhone>
            </Footer>
        </Content >
    )
}

const SearchCondition = (props) => {
    const [searchCondition, setSearchCondition] = useState(props.value)
    return (
        < div style={{ backgroundColor: "white", padding: "5px" }}>
            <InfoBox title={"日付範囲"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <CellInput key={"start"} type="date" value={searchCondition.start}
                        onChange={(event) => {
                            setSearchCondition({ ...searchCondition, start: event.target.value })
                        }}
                    />
                    <span style={{ verticalAlign: "middle" }}>〜</span>
                    <CellInput key={"end"} type="date" value={searchCondition.end}
                        onChange={(event) => {
                            setSearchCondition({ ...searchCondition, end: event.target.value })
                        }}
                    />
                </div>
            </InfoBox>
            <InfoBox title={"社員"} style={{ width: "400px" }}>
                <UserChoicer value={searchCondition.userId}
                    onChoiceUser={(userId) => {
                        setSearchCondition({ ...searchCondition, userId: userId })
                    }} />
            </InfoBox>
            <Button
                style={{ marginLeft: "10px" }}
                variant="contained"
                color="inherit"
                onClick={() => {
                    props.onChangeSearchCondition(searchCondition)
                }} >
                検索
            </Button>
        </div >
    )
}

const WorkReportRow = (props) => {
    const [workReportColors, setWorkReportColors] = useState(new Map()) // 勤怠実績データ
    const [workReport, setWorkReport] = useState({
        times: [], dates: { today: null, next: null },
    })
    const [controlState, setControlState] = useState(false)
    const constList = props.constList // 案件一覧データ（マップ構造）

    const loadWorkReportColor = (userId, date) => {
        WorkReportService.colors({ userId: userId, date: fmt(new Date(date)) })
            .then(
                response => {
                    setWorkReportColors(buildDisplayColors(response.data))
                },
                error => {
                    props.onError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                props.onError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    const buildDisplayWorkReport = (data) => {
        let min = 9
        let max = 16
        let a = new Array(0)

        // 時刻の枠を計る為に一旦ループ
        a.concat(data.today ? data.today.rows : [])
            .concat(data.next ? data.next.rows : []).forEach((r) => {
                let s = r.time.split(":")
                min = Math.min(parseInt(s[0]), min)
                max = Math.max(parseInt(s[0]), max)
            })

        // 時刻の枠分、空のオブジェクトを用意
        let t = {}
        for (let idx = min; idx <= max; idx++) {
            t[idx] = getInitialWorkRow(idx)
        }

        // 当日実績の中身の反映
        if (data.today) {
            data.today.rows.forEach((r) => {
                let s = r.time.split(":")
                let hour = parseInt(s[0])
                t[hour].today.text = r.text
                t[hour].today.const = {
                    constId: r.constId,
                    name: constList.get(r.constId) ? constList.get(r.constId).name : ''
                }
            })
        }

        // 次の日の予定の中身の反映
        if (data.next) {
            data.next.rows.forEach((r) => {
                let s = r.time.split(":")
                let hour = parseInt(s[0])
                t[hour].next.text = r.text
                t[hour].next.const = {
                    constId: r.constId,
                    name: constList.get(r.constId) ? constList.get(r.constId).name : ''
                }
            })
        }

        t = Object.keys(t).map((key) => {
            return t[key]
        })

        let tmp = {
            todayId: data.today ? data.today._id : null,
            nextId: data.next ? data.next._id : null,
            accountId: data.today ? data.today.accountId : '',
            superiorId: data.today ? data.today.superiorId : '',    // TODO:ロードできない場合、デフォルトの上長をセットする必要有り
            status: data.today ? data.today.status : STATUS.EDITTING,
            sendDate: data.today ? data.today.sendDate : null,
            dates: {
                today: data.today ? new Date(data.today.date) : null,
                next: data.next ? new Date(data.next.date) : null
            },
            todayOvertime: data.today.overtime,
            nextOvertime: data.next.overtime,
            times: t
        }
        return tmp
    }

    const buildDisplayColors = (colors) => {
        let result = new Map()
        if (colors) {
            Object.keys(colors).forEach((r) => {
                let s = r.split(":")
                let hour = parseInt(s[0])

                let rec = result.get(hour)
                if (!rec) {
                    rec = null
                }

                rec = colors[r]
                result.set(hour, rec)
            })
        }
        return result
    }

    useEffect(() => {
        let report = buildDisplayWorkReport(props.value)
        setWorkReport(report)
        loadWorkReportColor(report.accountId, report.dates.today)
    }, [props.value])

    return (
        <div style={{ position: "relative", marginTop: "5px", display: "flex", flexDirection: "row" }}>
            <div className="outlinePanel" variant="outlined" style={{ backgroundColor: props.bulkMode ? background[props.value.mode] : "white" }}>
                <div className="submitDate">
                    <InfoBox title="提出日時">
                        <DateTime value={workReport.sendDate} />
                    </InfoBox>
                </div>
                <div style={{ position: "relative" }}>
                    <WorkReport colors={workReportColors} value={workReport} readOnly={true} approve={true} page={props.page}
                        onClick={() => {
                            setControlState(!controlState)
                        }}
                    />
                    <MediaSmartPhone>
                        <Pager />
                    </MediaSmartPhone>
                </div>
                <WorkReportHistory value={props.value.approve} />
            </div>
        </div >
    )
}

const Pager = (props) => {
    return (
        <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, pointerEvents: "none" }}
            onClick={(event) => {
                props.onClick()
            }}
        />
    )
}

const NextArrow = (props) => {
    return (
        <IconButton className={" Pager"} style={{ position: "absolute", display: (props.page === "today" ? "block" : "none"), width: "40px", top: "0px", right: "5px", color: "white" }} ariaLabel="edit"
            onClick={props.onClick}
        >
            <ArrowForwardIosIcon />
        </IconButton>
    )
}

const PrevArrow = (props) => {
    return (
        <IconButton className={" Pager"} style={{ position: "absolute", display: (props.page === "next" ? "block" : "none"), width: "40px", top: "0px", left: "5px", color: "white" }} ariaLabel="edit"
            onClick={props.onClick}
        >
            <ArrowBackIosIcon />
        </IconButton>
    )
}

export default WorkReportList
