import InfoBox from '../common/InfoBox'
import DateTime from '../common/DateTime'
import ConstReport from '../report/ConstReport'
import ConstReport2 from '../report/ConstReport2'
import ConstReportComments from '../report/ConstReportComments'

import { getInitialConstReport } from '../utils/Utils'

import { Card } from '@mui/material'
import React, { useState, useEffect, useRef, useMemo } from 'react'

const ConstReportRow = (props) => {
  let [constReport, setConstReport] = useState({ today: {}, next: {} })

  const buildDisplayConstReport = (data) => {
    let today = new Date(data.today.date)
    let next = new Date(data.next.date)
    let d = {
      id: data.today._id,
      constId: data.today.constId,
      accountId: data.today.accountId,
      sendDate: '',
      updateDates: [],
      superiorId: '',    // TODO:ロードできない場合、デフォルトの上長をセットする必要有り
      members: [],
      dates: { today: today, next: next },
      today: getInitialConstReport(today),
      next: getInitialConstReport(next),
    }

    if (data.today) {
      d.sendDate = data.today.sendDate
      d.updateDates = data.today.updateDates
      d.superiorId = data.today.superiorId
      d.today = { ...data.today, date: today }
      d.members = data.today.members
      d.photos = data.today.photos
    }

    if (data.next) {
      d.next = { ...data.next, date: next }
    }

    if (data.discussion) {
      d.discussion = data.discussion
    }

    return d
  }

  useEffect(() => {
    if (props.value.version !== 2) {
      setConstReport(buildDisplayConstReport(props.value))
    } else {
      setConstReport(props.value)
    }
  }, [props.value])

  const ref = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          console.log("visible=" + props.value?.constId + " " + props.value?.date)
        }
      },
      {
        root: props.container,
        rootMargin: '20px',
        threshold: 0.05, // 要素の10%が表示されたら発火
      }
    )

    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    }
  }, [])

  // const constReportMemo = useMemo(() => { return (<ConstReport value={constReport} readOnly={true} discussion={true} page={props.page} />) }, [constReport])

  const memo = useMemo(() => {
    return (
      <div
        ref={ref}
        key={props.key} style={{ position: "relative", margin: "5px", display: "flex", flexDirection: "row" }}>
        <Card className="outlinePanel" variant="outlined" >
          {constReport.version !== 2 ?
            <React.Fragment>

              <div className="submitDate">
                <InfoBox title="提出日時">
                  <div><DateTime value={constReport.sendDate} /></div>
                  {constReport.updateDates?.map((r) => {
                    return (<div>再提出：<DateTime value={r} /></div>)
                  })}
                </InfoBox>
              </div>
              <div style={{ position: "relative" }}>
                {/* {constReportMemo} */}
                <ConstReport value={constReport} readOnly={true} discussion={true} page={props.page} />
                <Pager />
              </div>
              {
                constReport.discussion && constReport.discussion.discussion && constReport.discussion.discussion.length > 0 ?
                  (<ConstReportComments readOnly={true} initialValue={constReport?.discussion} onError={(error) => {
                    props.onError(error)
                  }} />
                  ) : (null)
              }
            </React.Fragment> :
            <React.Fragment>
              <div className="submitDate">
                <InfoBox title="提出日時">
                  <div><DateTime value={constReport.sendDate} /></div>
                  {constReport.updateDates?.map((r) => {
                    return (<div>再提出：<DateTime value={r} /></div>)
                  })}
                </InfoBox>
              </div>
              <div style={{ position: "relative" }}>
                <ConstReport2 value={constReport}
                  readOnly={true}
                  onChangeConstReport={() => { }} />
                <Pager />
              </div>
            </React.Fragment>
          }
        </Card >
      </div >
    )
  }, [constReport])

  return memo
}

const Pager = (props) => {
  return (
    <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, pointerEvents: "none" }} />
  )
}

export default ConstReportRow
