import { http } from "./Config"

const requestPath = "/v1/fs"

const find = (condition) => {
  return http.get(`${requestPath}/constList?targetDate=${condition.targetDate.toISOString()}&today=${condition.today.toISOString()}&process=${condition.process}&salesStaff=${condition.salesStaff}&constStaff=${condition.constStaff}`)
};

const saveProfits = (profits) => {
  return http.post(`${requestPath}/profit`, profits, {
    headers: {
      "content-type": "application/json",
    },
  })
}

const exports = {
  find,
  saveProfits
};

export default exports
