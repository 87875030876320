import './ConstListRow.css'

import CellInput from '../common/CellInput'
import NumberInput from '../common/NumberInput'

import { formatDate, formatNumber, formatRate } from '../utils/Utils'

import { TableCell, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'

const ConstListRow = (props) => {
  const [row, setRow] = useState(props.value)
  const [profit, setProfit] = useState(0)
  const [profitRate, setProfitRate] = useState(0)
  const [profitWithoutGroupCorp, setProfitWithoutGroupCorp] = useState(0)
  const [profitRateWithoutGroupCorp, setProfitRateWithoutGroupCorp] = useState(0)

  const fmt = (rate) => {
    return rate ? (rate * 100).toFixed(1) : ""
  }

  useEffect(() => {
    setProfit(props.value.profit)
    setProfitRate(fmt(props.value.profitRate))
    setProfitWithoutGroupCorp(props.value.profitWithoutGroupCorp)
    setProfitRateWithoutGroupCorp(fmt(props.value.profitRateWithoutGroupCorp))

    setRow(props.value)
  }, [props.value])
  return (
    <TableRow className={"ConstListRow" + (props.focus ? " focus" : "")}
      key={row.constId}
      onClick={(e) => {
        props.onClick(e)
      }}
      onFocus={(e) => {
        props.onFocus(e)
      }}
    >
      <TableCell className="rh0" component="th" >{row.constId}</TableCell>
      <TableCell className="rh1" component="th" >{row.constName}</TableCell>
      <TableCell>{formatDate(row.constructionStartDate)}〜{formatDate(row.actualCompletionDate ? row.actualCompletionDate : row.scheduledCompletionDate)}</TableCell>
      <TableCell className="m inp">
        <NumberInput className="profit"
          style={{ backgroundColor: "white", height: "22px" }}
          value={profit}
          onChangeValue={(v) => {
            setProfit(v)
            let r = { ...row, profit: v }
            props.onChangeRow(r)
          }}
          readOnly={props.readOnly}
        />
      </TableCell>
      <TableCell className="m inp">
        <CellInput type="number"
          className="profitRate"
          value={profitRate}
          maxValue={100}
          readOnly={props.readOnly}
          step="0.1"
          style={{ width: "40px", textAlign: "right" }}
          onChange={(e) => {
            let value = Number(e.target.value)
            if (value > 100 || value < 0) {
              return
            }
            setProfitRate(value)
          }}
          onBlur={(e) => {
            let r = { ...row, profitRate: profitRate / 100 }
            props.onChangeRow(r)
          }}
        /><span>％</span>
      </TableCell>
      <TableCell className="m inp">
        <NumberInput className="profitWithoutGroupCorp"
          style={{ backgroundColor: "white", height: "22px" }}
          value={profitWithoutGroupCorp}
          onChangeValue={(v) => {
            setProfitWithoutGroupCorp(v)
            let r = { ...row, profitWithoutGroupCorp: v }
            props.onChangeRow(r)
          }}
          readOnly={props.readOnly}
        />
      </TableCell>
      <TableCell className="m inp">
        <CellInput type="number"
          className="profitRateWithoutGroupCorp"
          value={profitRateWithoutGroupCorp}
          maxValue={100}
          readOnly={props.readOnly}
          step="0.1"
          style={{ width: "40px", textAlign: "right" }}
          onChange={(e) => {
            let value = Number(e.target.value)
            if (value > 100 || value < 0) {
              return
            }
            setProfitRateWithoutGroupCorp(value)
          }}
          onBlur={(e) => {
            let r = { ...row, profitRateWithoutGroupCorp: profitRateWithoutGroupCorp / 100 }
            props.onChangeRow(r)
          }}
        /><span>％</span>
      </TableCell>
      <TableCell className="m">{formatNumber(row.salesOrderPriceMain)}</TableCell>
      <TableCell className="m">{formatNumber(row.salesOrderPrice01)}</TableCell>
      <TableCell className="m">{formatNumber(row.salesOrderPrice02)}</TableCell>
      <TableCell className="m">{formatNumber(row.salesOrderPrice03)}</TableCell>
      <TableCell className="m">{formatNumber(row.salesOrderPrice04)}</TableCell>
      <TableCell className="m">{formatNumber(row.salesOrderPrice05)}</TableCell>
      <TableCell className="m">{formatNumber(row.salesOrderPrice)}</TableCell>

      <TableCell className="m">{formatNumber(row.budgetPrice)}</TableCell>
      <TableCell className="m">{formatNumber(row.paidPrice)}</TableCell>
      <TableCell className="m">{formatRate(row.paidPriceRate)}</TableCell>
      <TableCell className="m">{formatNumber(row.balancePaymentPrice)}</TableCell>
      <TableCell className="m"></TableCell>
      <TableCell className="m"></TableCell>
      <TableCell className="m">{formatNumber(row.totalPayablePrice)}</TableCell>
      <TableCell className="m">{formatNumber(row.totalPaymentPrice)}</TableCell>
      <TableCell className="m">{formatRate(row.thisMonthRate)}</TableCell>
      <TableCell className="m">{formatNumber(row.thisMonthPayment)}</TableCell>
      <TableCell className="m">{formatNumber(row.balancePrice)}</TableCell>
      <TableCell className="m">{formatNumber(row.totalProfitResult)}</TableCell>
      <TableCell className="m">{formatRate(row.totalProfitResultRate)}</TableCell>
      <TableCell style={{ width: "50px", textAlign: "center" }}>{
        row.upDown === 0 ? "" : row.upDown > 0 ? <span style={{ color: "green" }}>▲</span> : <span style={{ color: "red" }}>▼</span>
      }</TableCell>
    </TableRow>
  )
}

export default ConstListRow
