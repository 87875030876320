import { MediaPc } from '../common/Responsive'

import Footer from '../common/Footer'

import { resultSaveState } from '../hooks/ResultSaveState'
import { resultOutputExcelState } from '../hooks/ResultOutputExcelState'
import { resultImportCsvState } from '../hooks/ResultImportCsvState'
import { resultExportCsvState } from '../hooks/ResultExportCsvState'
import { laborCostsSaveState } from '../hooks/LaborCostsSaveState'
import { resultTabIndex } from '../hooks/ResultTabIndex'

import { Box, Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import DownloadIcon from '@mui/icons-material/Download'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

import { useRecoilValue, useSetRecoilState } from 'recoil'

import React from 'react'

const requestPath = "/ConstManage/v1/constphotos"

const ResultManageFooter = (props) => {
  const setSave = useSetRecoilState(resultSaveState)
  const setOutputExcel = useSetRecoilState(resultOutputExcelState)
  const setImportCsv = useSetRecoilState(resultImportCsvState)
  const setExportCsv = useSetRecoilState(resultExportCsvState)
  const setLaborCostsSave = useSetRecoilState(laborCostsSaveState)
  const tabIndex = useRecoilValue(resultTabIndex)

  const buttonStagePlan = () => {
    return (
      <React.Fragment >
        <Box style={{ paddingRight: "10px" }}>
          <Button variant="contained" color="secondary" onClick={(event) => {
            setSave(true)
          }}><SaveAltIcon /><MediaPc>保存</MediaPc></Button>
        </Box>
        <Box style={{ paddingRight: "10px" }}>
          <Button variant="contained" color="primary" onClick={(event) => {
            setOutputExcel(true)
          }}><FontAwesomeIcon icon={faFileExcel} size="lg" /><MediaPc>&nbsp;Ｅｘｃｅｌ出力</MediaPc></Button>
        </Box>
        <Box style={{ paddingRight: "10px" }}>
          <Button color="inherit" variant="contained" onClick={(event) => {
            setImportCsv(true)
          }}
          ><FileUploadIcon /><MediaPc>インポート</MediaPc></Button>
        </Box>
        <Box style={{ paddingRight: "10px" }}>
          <Button color="inherit" variant="contained" onClick={(event) => {
            setExportCsv(true)
          }}
          ><FileDownloadIcon /><MediaPc>エクスポート</MediaPc></Button>
        </Box>
      </React.Fragment>
    )
  }

  const buttonLaborCosts = () => {
    return (
      <Box style={{ paddingRight: "10px" }}>
        <Button variant="contained" color="secondary" onClick={(event) => {
          setLaborCostsSave(true)
        }}><SaveAltIcon /><MediaPc>保存</MediaPc></Button>
      </Box>
    )
  }

  const buttonBack = () => {
    return (
      <Box style={{ paddingRight: "10px" }}>
        <Button color="inherit" variant="contained" onClick={(event) => {
          props.onBack()
        }}
        ><ArrowBackIcon /><MediaPc>戻る</MediaPc></Button>
      </Box>
    )
  }

  const buttonPhoto = () => {
    return (
      <Box style={{ paddingRight: "10px" }}>
        <form method="post"
          action={`${requestPath}/compress?constId=${props.constId}`}
          style={{ display: 'inline' }}
          accept="blob"
        >
          <Button variant="contained" color="primary"
            fill="none"
            type="submit"
          ><DownloadIcon /><MediaPc>ダウンロード</MediaPc></Button>
        </form>
      </Box>
    )
  }

  return (
    <Footer className="ResultManageFooter">
      {props.backButton ? buttonBack() : null}
      {props.backButton && (tabIndex === 1 || tabIndex === 2) ? buttonStagePlan() : null}
      {props.backButton && tabIndex === 5 ? buttonLaborCosts() : null}
      {props.backButton && tabIndex === 3 ? buttonPhoto() : null}
    </Footer>
  )
}

export default ResultManageFooter