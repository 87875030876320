import './ReportList.css'
import Header from './common/Header'
import Footer from './common/Footer'
import Content from './common/Content'
import MainContent from './common/MainContent'
import InfoBox from './common/InfoBox'
import CellInput from './common/CellInput'
import UserChoicer from './common/UserChoicer'
import ConstSelector from './common/ConstSelector'
import UserName from './common/UserName'
import ReportBody from './report/ReportBody'
import ConstReportFooter from './report/constReport/ConstReportFooter'
import ConstReportFooter2 from './report/constReport2/ConstReportFooter2'
import WorkReportFooter from './report/workReport/WorkReportFooter'
import { MediaSmartPhone, MediaPc } from './common/Responsive'
import { formatDate, getStatusName } from './utils/Utils'

import ReportListService from '../services/ReportListService'

import { errorState } from './hooks/ErrorState'
import { sessionUserState } from './hooks/SessionUserState'
import { constReportPrintState } from './hooks/ConstReportPrintState'
import { constReport2PrintState } from './hooks/ConstReport2PrintState'
import { kyReportPrintState } from './hooks/KyReportPrintState'
import { constReportSaveState } from './hooks/ConstReportSaveState'
import { constReportCheckState } from './hooks/ConstReportCheckState'
import { workReportSaveState } from './hooks/WorkReportSaveState'
import { workReportPrintState } from './hooks/WorkReportPrintState'
import { workReportCheckState } from './hooks/WorkReportCheckState'
import { specialFooterState } from './hooks/SpecialFooterState'
import { reportKeyDataState } from './hooks/ReportKeyDataState'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { IconButton } from '@mui/material'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import React, { useState, useEffect } from 'react'
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Button, Card, CardContent, Typography } from '@mui/material'
import { Transition } from 'react-transition-group'

const fmt = (dt) => {
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    return `${y}-${m}-${d}`
}

const dateAdd = (dt, day) => {
    dt.setDate(dt.getDate() + day)
    return dt
}

const DEFAULT_KEY_DATA = { start: fmt(dateAdd(new Date(), -7)), end: fmt(new Date()), constId: '', userId: '' }
const searchConditionState = atom({
    key: 'reportList.searchCondition',
    default: DEFAULT_KEY_DATA,
})

const transitionStyles = {
    entering: { left: '-100%', transition: 'all 300ms ease' },
    entered: { left: '-100%' },
    exiting: { left: '0', transition: 'all 300ms ease' },
    exited: { left: '0' },
}

const ReportList = (props) => {
    const [keyData, setKeyData] = useRecoilState(reportKeyDataState)
    const setFooterState = useSetRecoilState(specialFooterState)
    const [reportDisplayState, setReportDisplayState] = useState(false)
    const [workReportPage, setWorkReportPage] = useState("today")   // 業務日報の当日／次の日切り替え
    const [constReportPage, setConstReportPage] = useState("today")   // 工事日報の当日／次の日切り替え
    const setCondition = useSetRecoilState(searchConditionState)

    const initialKeyData = { mode: null, userId: '', constId: '', dates: { today: null, next: null }, constDates: { today: null, next: null } }
    useEffect(() => {
        setKeyData(initialKeyData)
        setCondition(DEFAULT_KEY_DATA)
    }, [])

    useEffect(() => {
        if (keyData.userId) {
            setReportDisplayState(true)
        } else {
            setFooterState(null)
            setReportDisplayState(false)
        }
    }, [keyData])

    return (
        <Content>
            <Header title="日報一覧" />
            <Transition in={reportDisplayState} timeout={1500}>
                {(state) => (
                    <MainContent className="ReportList" style={transitionStyles[state]}>
                        <ReportListListControl {...props} onSelectKey={(key) => {
                            setKeyData(key)
                        }} />
                        {keyData.userId ? (
                            <ReportListReportControl
                                workReportPage={workReportPage}
                                constReportPage={constReportPage}
                            />
                        ) : (
                            <React.Fragment />
                        )}
                    </MainContent>
                )}
            </Transition>
            <ReportListFooter
                workReportPage={workReportPage}
                constReportPage={constReportPage}
                onBackList={() => {
                    setKeyData(initialKeyData)
                }}
                onWorkReportNext={() => {
                    setWorkReportPage("next")
                }}
                onWorkReportToday={() => {
                    setWorkReportPage("today")
                }}
                onConstReportNext={() => {
                    setConstReportPage("next")
                }}
                onConstReportToday={() => {
                    setConstReportPage("today")
                }}
                onBackToList={() => {
                    setFooterState(null)
                    setReportDisplayState(false)
                }}
            />
        </Content>
    )
}

const ReportListReportControl = (props) => {
    return (
        <div className="ReportListReportControl" style={props.style}>
            <ReportBody
                key="reportList"
                workReportPage={props.workReportPage}
                constReportPage={props.constReportPage}
            />
        </div>
    )
}

const ReportListListControl = (props) => {
    const [reportListData, setReportListData] = useState([])
    const [condition, setCondition] = useRecoilState(searchConditionState)
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用

    const loadReportList = (key) => {
        ReportListService.load(key)
            .then(
                response => {
                    let reportListDatas = response.data.objects

                    setReportListData(reportListDatas)
                },
                error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                }
            ).catch(error => {
                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
            })
    }

    return (
        <div className="ReportListListControl" style={props.style}>
            <SearchCondition value={condition} onChangeSearchCondition={(condition) => {
                setCondition(condition)
                loadReportList(condition)
            }} />
            <TableContainer component={Paper} style={{ display: "block",     overscrollBehavior: "none" }}>
                <Table stickyHeader size="small" ariaLabel="a dense table">
                    <TableHead>
                        <TableRow>
                            <MediaPc>
                                <TableCell align="right" width="100px">日付</TableCell>
                                <TableCell width="100px">氏名</TableCell>
                                <TableCell width="300px">業務日報の状況</TableCell>
                                <TableCell width="300px">工事日報の状況</TableCell>
                                <TableCell width="40px">写真の枚数</TableCell>
                                <TableCell width="50px">操作</TableCell>
                            </MediaPc>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportListData.map((row) => {
                            return (
                                <TableRow key={row.id} >
                                    <MediaPc>
                                        <TableCell align="right">{formatDate(row.date)}</TableCell>
                                        <TableCell ><UserName userId={row.accountId} /></TableCell>
                                        <TableCell >{getStatusName(row.workReport?.status)}</TableCell>
                                        <TableCell >{getStatusName(row.constReport?.status)}</TableCell>
                                        <TableCell style={{ textAlign: "center" }}>{row.constReport?.photoCount}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={
                                                () => {
                                                    props.onSelectKey({
                                                        userId: row.accountId,
                                                        constId: row.constReport?.constId,
                                                        constVer: row.version,
                                                        mode: !row.workReport ? "constReport" : (row.constReport || row.constReport2) ? null : "workReport",    // 該当の工事日報が無いなら、業務日報のみ表示
                                                        dates: { today: new Date(row.date), next: null },
                                                        constDates: { today: new Date(row.constReport?.date ? row.constReport.date : row.date), next: null }
                                                    })
                                                }
                                            }
                                                ariaLabel="edit" color="primary">
                                                <ArrowForwardIosIcon />
                                            </IconButton>
                                        </TableCell>
                                    </MediaPc>
                                    <MediaSmartPhone>
                                        <Card>
                                            <CardContent
                                                onClick={
                                                    () => {
                                                        props.onSelectKey({
                                                            userId: row.accountId,
                                                            constId: row.constReport?.constId,
                                                            constVer: row.version,
                                                            mode: !row.workReport ? "constReport" : (row.constReport || row.constReport2) ? null : "workReport",    // 該当の工事日報が無いなら、業務日報のみ表示
                                                            dates: { today: new Date(row.date), next: null },
                                                            constDates: { today: new Date(row.constReport?.date ? row.constReport.date : row.date), next: null }
                                                        })
                                                    }
                                                }
                                            >
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ marginRight: "20px", width: "40%" }}>
                                                        <Typography color="textSecondary" style={{ fontSize: "13px" }}>{formatDate(row.date)}</Typography>
                                                        <Typography color="textPrimary" variant="h5" component="h1"><UserName userId={row.accountId} /></Typography>
                                                    </div>
                                                    <div style={{ width: "60%" }}>
                                                        <Typography color="textPrimary" component="h3">業務日報：<span>{getStatusName(row.workReport?.status)}</span></Typography>
                                                        <Typography color="textPrimary" component="h3">工事日報：<span>{getStatusName(row.constReport?.status)}</span></Typography>
                                                        <Typography color="textPrimary" component="h3">写真：{row.constReport?.photoCount}枚</Typography>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                    </MediaSmartPhone>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const SearchCondition = (props) => {
    const userData = useRecoilValue(sessionUserState)
    const [searchCondition, setSearchCondition] = useState(props.value)
    useEffect(() => {
        let admin = (userData.roles.indexOf("administrator") >= 0)
        let userId = admin ? "" : userData.userId
        setSearchCondition({ ...searchCondition, userId: userId })
        props.onChangeSearchCondition({ ...searchCondition, userId: userId })
    }, [userData])

    return (
        < div style={{ backgroundColor: "white", padding: "5px" }}>
            <InfoBox title={"日付範囲"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <CellInput key={"start"} type="date" value={searchCondition.start} style={{ width: "110px", textAlign: "center" }}
                        onChange={(event) => {
                            setSearchCondition({ ...searchCondition, start: event.target.value })
                        }}
                    />
                    <span style={{ verticalAlign: "middle" }}>〜</span>
                    <CellInput key={"end"} type="date" value={searchCondition.end} style={{ width: "110px", textAlign: "center" }}
                        onChange={(event) => {
                            setSearchCondition({ ...searchCondition, end: event.target.value })
                        }}
                    />
                </div>
            </InfoBox>
            <InfoBox title={"工事番号"} style={{ width: "100%", maxWidth: "600px" }}>
                <ConstSelector value={searchCondition.constId}
                    onChangeConstId={(constId) => {
                        setSearchCondition({ ...searchCondition, constId: constId })
                    }} />
            </InfoBox>
            <InfoBox title={"氏名"} >
                <UserChoicer style={{ width: "100%", maxWidth: "200px" }} value={searchCondition.userId} readOnly={props.readOnly} onChoiceUser={(userId) => {
                    setSearchCondition({ ...searchCondition, userId: userId })
                }} />
            </InfoBox>
            <Button
                style={{ marginLeft: "10px" }}
                variant="contained"
                color="inherit"
                onClick={() => {
                    props.onChangeSearchCondition(searchCondition)
                }} >
                検索
            </Button>
        </div >
    )
}

const ReportListFooter = (props) => {
    const setConstReportSave = useSetRecoilState(constReportSaveState)  // 工事日報の一時保存
    const setConstReportCheck = useSetRecoilState(constReportCheckState)  // 工事日報の提出
    const setConstReportPrint = useSetRecoilState(constReportPrintState) // 工事日報の印刷
    const setConstReport2Print = useSetRecoilState(constReport2PrintState) // 工事日報(Ver.2)の印刷
    const setKyReportPrint = useSetRecoilState(kyReportPrintState) // 本日のKY活動の印刷
    const setWorkReportSave = useSetRecoilState(workReportSaveState)  // 業務日報の一時保存
    const setWorkReportCheck = useSetRecoilState(workReportCheckState)  // 業務日報の提出
    const setWorkReportPrint = useSetRecoilState(workReportPrintState) // 業務日報の印刷

    const footerState = useRecoilValue(specialFooterState)
    const footers = {
        workReport: (
            <WorkReportFooter
                page={props.workReportPage}
                forceVisibleToday={true}
                onPdfWorkReport={(event) => {
                    setWorkReportPrint(true)
                }}
                onSaveTemporary={(event) => {
                    setWorkReportSave(true)
                }}
                onSent={(event) => {
                    setWorkReportCheck(true)
                }}
                onNext={() => {
                    props.onWorkReportNext()
                }}
                onToday={() => {
                    if (props.workReportPage === "today") {
                        props.onBackToList()
                    } else {
                        props.onWorkReportToday()
                    }
                }}
            >
            </WorkReportFooter>
        ),
        constReport: (
            footerState?.version !== 2 ?
                (
                    <ConstReportFooter
                        page={props.constReportPage}
                        forceVisibleToday={true}
                        onPdfConstReport={(event) => {
                            setConstReportPrint(true)
                        }}
                        onPdfKyReport={(event) => {
                            setKyReportPrint(true)
                        }}
                        onSaveTemporary={(event) => {
                            setConstReportSave(true)
                        }}
                        onSent={(event) => {
                            setConstReportCheck(true)
                        }}
                        onNext={() => {
                            props.onConstReportNext()
                        }}
                        onToday={() => {
                            if (props.constReportPage === "today") {
                                props.onBackToList()
                            } else {
                                props.onConstReportToday()
                            }
                        }}
                    >
                    </ConstReportFooter>
                ) : (
                    <ConstReportFooter2
                        forceVisibleToday={true}
                        onPdfConstReport={(event) => {
                            setConstReport2Print(true)
                        }}
                        onSaveTemporary={(event) => {
                            setConstReportSave(true)
                        }}
                        onSent={(event) => {
                            setConstReportCheck(true)
                        }}
                        onToday={() => {
                            props.onBackToList()
                        }}
                    />
                )
        )
    }

    return (
        <Footer>
            {footers[footerState?.mode]}
        </Footer>
    )
}
export default ReportList
