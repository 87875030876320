import './ConstIdDialog.css'

import { formatDate } from '../utils/Utils'
import { constListData } from '../hooks/ConstListData'
import { MediaPc, MediaSmartPhone } from '../common/Responsive'

import { errorState } from '../hooks/ErrorState'
import { sessionUserState } from '../hooks/SessionUserState'

import ProjectService from '../../services/ProjectService'

import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { FormControlLabel, Button, Checkbox } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'

const ConstIdDialog = (props) => {
    const constMap = useRecoilValue(constListData) // 案件一覧データ（マップ構造）
    const [filter, setFilter] = useState(null)
    const [middle, setMiddle] = useState(true)
    const [favoriteMode, setFavoriteMode] = useState(false)
    const [favorite, setFavorite] = useState([])
    const setError = useSetRecoilState(errorState)
    const userState = useRecoilValue(sessionUserState)

    useEffect(() => {
        if (props.open) {
            ProjectService.favoriteConstInfo()
                .then(
                    response => {
                        if (response.data) {
                            setFavoriteMode(true)
                            setFavorite(response.data.consts.map((row) => { return row.constId }))
                        }
                    },
                    error => {
                        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                    }
                ).catch(error => {
                    setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                })
        }
    }, [props.open])

    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    }, [])

    let timeoutId = null
    return (
        <Dialog
            className="ConstIdDialog"
            disableEscapeKeyDown={!props.canCancel}
            fullWidth={true}
            maxWidth={"lg"}
            open={props.open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && props.onClose) {
                    props.onClose()
                }
            }}
            ariaLabelledby="form-dialog-title" >
            <MediaPc>
                <DialogTitle id="form-dialog-title" >工事番号を入力</DialogTitle>
            </MediaPc>
            <DialogContent >
                <MediaPc>
                    <TextField style={{ display: "block" }} onChange={(event) => {
                        if (timeoutId) {
                            clearTimeout(timeoutId)
                        }

                        timeoutId = setTimeout(() => {
                            setFilter(event.target.value)
                        }, 500)
                    }} defaultValue={filter}
                        size="small"
                        label="検索" />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={(event) => {
                                setMiddle(event.target.checked)
                            }} checked={middle} color="secondary" />
                        }
                        label="進行中の工事のみ表示"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox onChange={(event) => {
                                setFavoriteMode(event.target.checked)
                            }} checked={favoriteMode} color="secondary" />
                        }
                        label="最近使った工番"
                    />
                    <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                </MediaPc>
                <MediaSmartPhone>
                    <div style={{ display: "flex" }}>
                        <TextField style={{ display: "block", width: "130px" }} onChange={(event) => {
                            if (timeoutId) {
                                clearTimeout(timeoutId)
                            }

                            timeoutId = setTimeout(() => {
                                setFilter(event.target.value)
                            }, 500)
                        }} defaultValue={filter}
                            size="small"
                            label="検索" />
                        <FormControlLabel
                            control={
                                <Checkbox onChange={(event) => {
                                    setMiddle(event.target.checked)
                                }} checked={middle} color="secondary" />
                            }
                            label="進行中"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox onChange={(event) => {
                                    setFavoriteMode(event.target.checked)
                                }} checked={favoriteMode} color="secondary" />
                            }
                            label="最近"
                        />
                    </div>
                </MediaSmartPhone>

                <table className={"dataList"}>
                    <thead className={"scrollHead"}>
                        <MediaPc>
                            <tr>
                                <th className={"choise"} style={{ textAlign: "center" }}>選択</th>
                                <th className={"constId"} style={{ textAlign: "center" }}>工事番号</th>
                                <th className={"name"} style={{ textAlign: "center" }}>工事名</th>
                                <th className={"city"} style={{ textAlign: "center" }}>住所</th>
                                <th className={"term"} style={{ textAlign: "center" }}>工期</th>
                                <th className={"process"} style={{ textAlign: "center" }}>状況区分</th>
                            </tr>
                        </MediaPc>
                    </thead>
                    <tbody className={"scrollBody"} >
                        {
                            Array.from(constMap.values()).filter(row => {
                                // 検索フィルター
                                return (
                                    (
                                        (!middle) || (middle && row.process === "工事進行中")
                                    )
                                    && (
                                        !filter
                                        || (row.constId && row.constId.indexOf(filter.toUpperCase(), 0) >= 0)
                                        || (row.name && row.name.indexOf(filter, 0) >= 0)
                                        || (row.city && row.city.indexOf(filter, 0) >= 0)
                                    )
                                    && (
                                        (userState.personalSetting.area === "大阪" && row.constId.startsWith("O"))
                                        || (userState.personalSetting.area === "東京" && row.constId.startsWith("T"))
                                        || userState.personalSetting.area === "指定無し"
                                        || !userState.personalSetting.area
                                    )
                                )
                            }).filter((row) => {
                                // 最近使った工番のフィルター
                                return (!favoriteMode || (favorite && favorite.includes(row.constId)))
                            }).map(row => (
                                <React.Fragment>
                                    <MediaPc>
                                        <tr key={row.constId}>
                                            <td className={"choise"}>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    color="inherit"
                                                    onClick={() => {
                                                        if (props.onChoice) {
                                                            props.onChoice(row)
                                                        }
                                                    }}>
                                                    選択
                                                </Button>
                                            </td>
                                            <td className={"constId"}>
                                                <div>{row.constId}</div>
                                            </td>
                                            <td className={"name"}>
                                                <div>{row.name}</div>
                                            </td>
                                            <td className={"city"}>
                                                <div>{row.city}</div>
                                            </td>
                                            <td className={"term"}>
                                                <div>{formatDate(row.termFrom)}〜{formatDate(row.termTo)}</div>
                                            </td>
                                            <td className={"process"}>
                                                <div>{row.process}</div>
                                            </td>
                                        </tr>
                                    </MediaPc>
                                    <MediaSmartPhone>
                                        <tr key={row.constId} onClick={() => {
                                            if (props.onChoice) {
                                                props.onChoice(row)
                                            }
                                        }}>
                                            <td style={{ display: "flex" }}>
                                                <div style={{ width: "70px", minWidth: "70px" }}>{row.constId}</div><div>{row.name}</div>
                                            </td>
                                        </tr>
                                    </MediaSmartPhone>
                                </React.Fragment>
                            ))}
                    </tbody>
                </table>
            </DialogContent>
            {
                (props.canCancel) ? (
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (props.onClose) {
                                    props.onClose()
                                }
                            }} >
                            キャンセル
                        </Button>
                    </DialogActions>
                ) : (
                    <React.Fragment />
                )
            }
        </Dialog >
    )

}

export default ConstIdDialog