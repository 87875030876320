import { normalize } from '@geolonia/normalize-japanese-addresses'

import React, { useEffect, useState } from "react"

const weatherIcons = {
  0: { icon: "☀️", description: "快晴" },
  1: { icon: "🌤️", description: "ほぼ快晴" },
  2: { icon: "⛅", description: "一部曇り" },
  3: { icon: "☁️", description: "曇り" },
  45: { icon: "🌫️", description: "霧" },
  48: { icon: "🌫️", description: "霧（霜付着）" },
  51: { icon: "🌦️", description: "小雨" },
  53: { icon: "🌦️", description: "やや強い雨" },
  55: { icon: "🌧️", description: "強い雨" },
  61: { icon: "🌧️", description: "弱い雨" },
  63: { icon: "🌧️", description: "適度な雨" },
  65: { icon: "🌧️", description: "激しい雨" },
  71: { icon: "❄️", description: "弱い雪" },
  73: { icon: "❄️", description: "適度な雪" },
  75: { icon: "❄️", description: "激しい雪" },
  95: { icon: "🌩️", description: "雷雨" },
  99: { icon: "🌩️", description: "ひょうを伴う雷雨" },
}

const getWindDirection = (degrees) => {
  const directions = ["北", "北北東", "北東", "東北東", "東", "東南東", "南東", "南南東", "南", "南南西", "南西", "西南西", "西", "西北西", "北西", "北北西"]
  const index = Math.round(degrees / 22.5) % 16
  return directions[index]
}
// km/h を m/s に変換
const convertToMetersPerSecond = (kmh) => {
  return (kmh * 0.27778).toFixed(1); // 小数点1桁に丸める
}

const getTemperatureColor = (temp) => {
  if (temp <= 0) return "#00BFFF"; // 青（寒い）
  if (temp <= 15) return "#87CEFA"; // 水色（涼しい）
  if (temp <= 25) return "#FFD700"; // 黄色（暖かい）
  return "#FF6347"; // 赤（暑い）
}
const Weather = (props) => {
  const [weatherData, setWeatherData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchWeather = async ({ latitude, longitude }) => {
      setLoading(true)
      try {
        const response = await fetch(
          `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current_weather=true&hourly=relative_humidity_2m&timezone=Asia/Tokyo`
        )

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json();
        const currentTime = new Date(data.current_weather.time);

        // 現在時刻に最も近い湿度データを取得
        let closestHumidity = null;
        let minTimeDifference = Infinity;

        data.hourly.time.forEach((hourTime, index) => {
          const hourlyTime = new Date(hourTime);
          const timeDifference = Math.abs(currentTime - hourlyTime);

          if (timeDifference < minTimeDifference) {
            minTimeDifference = timeDifference;
            closestHumidity = data.hourly.relative_humidity_2m[index];
          }
        });

        setWeatherData({ ...data.current_weather, humidity: closestHumidity })
      } catch (err) {
      } finally {
        setLoading(false)
      }
    };

    if (props.address) {
      normalize(props.address).then(result => {
        if (result && result.point) {
          fetchWeather({ latitude: result.point.lat, longitude: result.point.lng })
        }
      })
    }
  }, [props.address])

  if (loading) {
    return <p>Loading weather data...</p>
  }

  const weather = weatherIcons[weatherData?.weathercode] || { icon: "❓", description: "Unknown weather" }
  const windSpeedMetersPerSecond = convertToMetersPerSecond(weatherData?.windspeed)

  return (
    <div style={{ textAlign: "center", width: "100%", height: "100%", backgroundColor: getTemperatureColor(weatherData?.temperature) }}>
      <div style={{ fontSize: "48px", margin: "10px 0", width: "100%", display: "flex", justifyContent: "center", alignItems: "baseline" }}>{weather.icon}<p style={{ fontSize: "30px", margin: "5px 0" }}>{weather.description}</p></div>

      <p style={{ fontSize: "20px", margin: "0", fontWeight: "bold", width: "100%", display: "flex", justifyContent: "center", alignItems: "baseline" }}>
        <div style={{ display: "block" }}>気温: {weatherData?.temperature}°C</div>
        <div style={{ marginLeft: "10px", display: "block" }}>湿度: {weatherData?.humidity}%</div>
      </p>
      <p style={{ margin: "0" }}>
        <strong>風速:</strong> {getWindDirection(weatherData?.winddirection) + "の風" + windSpeedMetersPerSecond + "m/s"}
      </p>
      <p style={{ padding: "10px 5px 0 0", width: "100%", textAlign: "right", fontSize: "12px", color: "#808080" }}>
        <strong>最終更新:</strong> {new Date(weatherData?.time).toLocaleString("ja-JP")}
      </p>
    </div>
  )
}

export default Weather
