import './DailySafetyCheck.css'

import TimeInput from '../../common/TimeInput'
import UserChoicer from '../../common/UserChoicer'

import React, { useState, useEffect } from 'react'

const DailySafetyCheck = (props) => {
  const [value, setValue] = useState(props.value)
  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  return (
    <div className="DailySafetyCheck constRow" >
      <div className="constTitle">
        <span>毎日の安全点検</span>
      </div>
      <div className="constVertical">
        <div style={{ display: "flex" }}>
          <span className="DalySafetyCheckLabel">点検時間</span>
          <TimeInput value={value.time} readOnly={props.readOnly} onChange={(e) => {
            let tm = { ...value, time: e.target.value }
            setValue(tm)
            props.onChangeValue(tm)
          }} />
        </div>
        <div style={{ display: "flex", flexWrap: "nowrap" }}>
          <span className="DalySafetyCheckLabel" style={{ whiteSpace: "nowrap" }}>点検者</span>
          <UserChoicer style={{ width: "200px" }} value={value.inspector} readOnly={props.readOnly} onChoiceUser={(u) => {
            setValue({ ...value, inspector: u })
          }}
            onBlur={(e) => {
              props.onChangeValue(value)
            }} />
        </div>
      </div>
    </div>
  )
}

export default DailySafetyCheck