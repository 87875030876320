import { sessionUserState } from '../hooks/SessionUserState'

import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from 'recoil'

const CheckAuth = (props) => {
  const navigate = useNavigate()
  const userData = useRecoilValue(sessionUserState)
  const [msg, setMsg] = useState(null)

  useEffect(() => {
    if (userData.userId && props.role && !userData.roles.includes(props.role)) {
      setMsg("この画面にアクセスする権限がありません。")
    } else {
      setMsg(null)
    }
  }, [userData, props.role])

  return (
    <React.Fragment>
      <Dialog
        open={msg !== null}
        onClose={() => {
          navigate('/')
        }}
      >
        <DialogTitle style={{ padding: "3px" }} className={"label"}>{"権限エラー"}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: "red", ...props.textStyle }} component={"pre"} >
            {msg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" size="small"
            onClick={() => {
              navigate('/')
              setMsg(null)
            }} color="primary" autoFocus>
            ＯＫ
          </Button>
        </DialogActions>
      </Dialog>
      {(userData.userId && msg === null) ? props.children : null}
    </React.Fragment>
  )
}

export default CheckAuth