import './ListItemNumberInput.css'

import CellInput from '../../common/CellInput'

import React, { useEffect, useState } from 'react'

const ListItemNumberInput = (props) => {
  const [row, setRow] = useState(props.row)
  useEffect(() => {
    setRow(props.row)
  }, [props.row])
  let name = props.name
  return (
    <CellInput
      className={name + "Input ListItemNumberInput"}
      readOnly={props.readOnly}
      type="number"
      line={props.line}
      value={row[name]}
      style={{ textAlign: "right" }}
      onChange={(e) => {
        let v = Number(e.target.value)
        if (v > 100 || v < 0) {
          return
        }
        e.target.value = v
        let r = { ...row }
        r[name] = v
        setRow(r)
      }}
      onBlur={(e) => {
        props.onChangeRow(row, props.idx, props.normalize)
      }} />
  )
}

export default ListItemNumberInput
