import './ListTypeSelector.css'
import { ButtonGroup, Button } from '@mui/material'
import { ViewModule, ViewList } from '@mui/icons-material'

import React from 'react'

const ListTypeSelector = (props) => {

  return (
    <div className="ListTypeSelector">
      <ButtonGroup variant="contained" color="inherit" style={{ color: "gray" }}>
        <Button size="small" onClick={() => {
          props.onChangeListType("grid")
        }}><ViewModule /></Button>
        <Button size="small" onClick={() => {
          props.onChangeListType("list")
        }}><ViewList /></Button>
      </ButtonGroup>
    </div>
  )
}

export default ListTypeSelector