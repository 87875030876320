import React, { useState } from 'react'
import { Typography, Button, Popover, List, ListItem, } from '@mui/material'
import { ArrowDropDown } from '@mui/icons-material'

const MonthPicker = (props) => {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth() + 1
  const [selectedYear, setSelectedYear] = useState(currentYear)
  const [selectedMonth, setSelectedMonth] = useState(currentMonth)
  const [anchorEl, setAnchorEl] = useState(null)

  const years = Array.from({ length: 5 }, (_, i) => currentYear - 2 + i)
  const months = [
    '1月', '2月', '3月', '4月', '5月', '6月',
    '7月', '8月', '9月', '10月', '11月', '12月'
  ]

  const handleSelect = (year, month, close) => {
    setSelectedYear(year)
    setSelectedMonth(month)
    if (close && props.onChangeMonth) {
      props.onChangeMonth(year, month)
    }
    if (close) {
      setAnchorEl(null)
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={handleClick}
        endIcon={<ArrowDropDown />}
      >
        {selectedYear}年{selectedMonth}月
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            maxWidth: "500px",  // ポップオーバーの最大幅を設定
            padding: 8,      // 内側のパディングを設定
          },
        }}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ borderRight: '1px solid #ccc', padding: '3px', width: '120px', height: '200px', overflow: 'hidden' }}>
            <Typography style={{ textAlign: 'center' }} variant="subtitle1" gutterBottom>年</Typography>
            <List
              style={{ height: '160px', overflowY: 'scroll' }}
              useNativeScrolling={true}
              dense>
              {years.map((year) => (
                <ListItem key={year} style={{ padding: "3px" }} onClick={() => handleSelect(year, selectedMonth, false)}>
                  <Button size="small" variant="outlined" fullWidth>{year}年</Button>
                </ListItem>
              ))}
            </List>
          </div>
          <div style={{ padding: '3px', }}>
            <Typography style={{ textAlign: 'center' }} variant="subtitle1" gutterBottom>月</Typography>
            <List dense style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
              {months.map((month, index) => (
                <ListItem style={{ paddingLeft: '5px', paddingRight: '5px' }} key={index} onClick={() => handleSelect(selectedYear, index + 1, true)}>
                  <Button size="small" variant="outlined" fullWidth >{month}</Button>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </Popover>
    </>
  )
}

export default MonthPicker