import './LaborCosts.css'

import DateInput from '../../common/DateInput'
import NumberInput, { addSeparator } from '../../common/NumberInput'
import LimitedTextarea from '../../common/LimitedTextarea'

import UserDialog from '../../common/UserDialog'
import ConfirmationDialog from '../../common/ConfirmationDialog'
import { parseDate, truncateTime } from '../../utils/Utils'
import { constListData } from '../../hooks/ConstListData'
import { getCurrentFiscalYear, getFiscalPeriodDates, getFiscalYears } from '../../utils/FiscalYearUtils'
import { createSVGBands } from '../../common/SVGBand'

import { errorState } from '../../hooks/ErrorState'
import { messageState } from '../../hooks/MessageState'
import { laborCostsSaveState } from '../../hooks/LaborCostsSaveState'
import LaborCostsService from '../../../services/LaborCostsService'

import React, { useState, useEffect } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { Menu, MenuItem, Select } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'


import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from "@mui/material"

const monthList1st = [8, 9, 10, 11, 12, 1]
const monthList2nd = [2, 3, 4, 5, 6, 7]

const currentFiscalYear = getCurrentFiscalYear()

const LaborCosts = (props) => {
  const [key, setKey] = useState({ constId: props.constId })

  const constMap = useRecoilValue(constListData) // 工事情報データ（マップ構造）
  const [laborCosts, setLaborCosts] = useState({ data: new Map(), fiscalYear: 0 }) // 人件費データ本体　会計年度をキーとするマップ構造
  const [open, setOpen] = useState(false)
  const [save, setSave] = useRecoilState(laborCostsSaveState)
  const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
  const setMessage = useSetRecoilState(messageState) // メッセージ表示用

  useEffect(() => {
    if (key.constId) {
      loadLaborCosts(key.constId)
    }
  }, [key])

  const calcSummaryPrice = (laborCosts) => {
    let forecastPrice = 0
    let now = (new Date()).getFullYear() * 100 + (new Date()).getMonth() + 1

    let planSummaryParUser = new Map()
    let resultSummaryParUser = new Map()

    Array.from(laborCosts.data.values()).forEach(laborCost => {
      laborCost.details.forEach(detail => {
        detail.earlyPeriodPlanPrice = planSummaryParUser.get(detail.userId) ? planSummaryParUser.get(detail.userId) : 0
        detail.earlyPeriodResultPrice = resultSummaryParUser.get(detail.userId) ? resultSummaryParUser.get(detail.userId) : 0

        detail.months.forEach(month => {
          if (month.workResultPrice) {
            forecastPrice += month.workResultPrice
          } else if (month.year * 100 + month.month >= now && month.workPlanPrice) {
            forecastPrice += month.workPlanPrice
          }

          // 累計値の計算
          planSummaryParUser.set(detail.userId, planSummaryParUser.get(detail.userId) ? planSummaryParUser.get(detail.userId) + month.workPlanPrice : month.workPlanPrice)
          resultSummaryParUser.set(detail.userId, resultSummaryParUser.get(detail.userId) ? resultSummaryParUser.get(detail.userId) + month.workResultPrice : month.workResultPrice)
        })
      })
    })

    Array.from(laborCosts.data.values()).forEach(laborCost => {
      laborCost.forecastPrice = forecastPrice
    })
  }

  const loadLaborCosts = (constId) => {
    LaborCostsService.get(constId)
      .then(res => {
        let data = new Map()

        if (!res.data) {
          // データが全く無かった場合、工事情報から期間を取得
          const c = constMap.get(key.constId)

          let termFrom = c.termFrom ? new Date(c.termFrom) : new Date(props.termFrom)
          let termTo = c.termTo ? new Date(c.termTo) : new Date(props.termTo)

          let newData = []

          getFiscalYears(termFrom, termTo).forEach(fiscalYear => {
            newData.push({
              fiscalYear: fiscalYear,
              constId: key.constId,
              termFrom: termFrom,
              termTo: termTo,
              budgetPrice: 0,
              defferencePrice: 0,
              details: []
            })
          })
          res.data = newData
        }

        let fiscalYear = 0
        if (res.data) {
          fiscalYear = res.data[0].fiscalYear
          let currentFiscalYear = getCurrentFiscalYear()
          res.data.forEach(row => {
            if (row.fiscalYear <= currentFiscalYear) {
              fiscalYear = row.fiscalYear
            }
            row.details.forEach(detail => {
              detail.startDate = new Date(detail.startDate ? detail.startDate : props.termFrom)
              detail.endDate = new Date(detail.endDate ? detail.endDate : props.termTo)
            })
            data.set(row.fiscalYear, row)
          })
        }

        let l = { fiscalYear: fiscalYear, data: data }
        calcSummaryPrice(l)

        setLaborCosts(l)
      })
      .catch(error => {
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      })
  }

  const saveLaborCosts = (laborCosts, constId) => {
    let data = Array.from(laborCosts.data.values())
    LaborCostsService.save(data, constId)
      .then(res => {
        setMessage({ msg: `保存を完了しました。`, open: true, title: '保存完了' })
        loadLaborCosts(props.constId)
      })
      .catch(error => {
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      })
  }
  if (!laborCosts.data) {
    return null
  }

  // 表示中の会計年度の人件費データ
  let laborCostsData = laborCosts.data.get(laborCosts.fiscalYear)

  return (
    <div className="LaborCosts">
      <div className="control">
        <ConfirmationDialog
          dialogTitle="保存"
          bodyText={"このデータを保存します。よろしいですか？"}
          open={save}
          onOk={() => {
            saveLaborCosts(laborCosts, key.constId, key.fiscalYear)
            setSave(false)
          }}
          onCancel={() => { setSave(false) }}
          onClose={() => { setSave(false) }}
        />
        <UserDialog
          open={open}
          canCancel={true}
          onClose={() => setOpen(false)}
          onChoice={(user) => {
            const c = constMap.get(key.constId)
            const p = getFiscalPeriodDates(laborCosts.fiscalYear)

            let d = p.startDate

            const months = [...Array(12)].map((_, i) => {
              let m = {
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                workPlanPrice: 0,
                workResultPrice: 0
              }
              d = new Date(d.getFullYear(), d.getMonth() + 1, 1)
              return m
            })

            let termFrom = c.termFrom ? new Date(c.termFrom) : new Date(props.termFrom)
            let termTo = c.termTo ? new Date(c.termTo) : new Date(props.termTo)
            let row = {
              userId: user.userId,
              userName: user.userName,
              employeeNumber: user.employeeNumber,
              title: user.title,
              earlyPeriodPlanPrice: 0,
              earlyPeriodResultPrice: 0,
              startDate: termFrom,
              endDate: termTo,
              svgBand: createSVGBands(termFrom, termTo),
              months: months
            }

            // laborCosts.dataのMapオブジェクトをコピー
            let data = new Map(laborCosts.data)

            if (data) {
              let details = data.get(laborCosts.fiscalYear).details.concat()
              details.push(row)
              data.get(laborCosts.fiscalYear).details = details
              setLaborCosts({ ...laborCosts, data: data })
              setOpen(false)
            }
          }}
        />
        <Select
          value={laborCosts.fiscalYear}
          style={{ position: "absolute", display: "block", top: "5px", left: "10px", width: "320px" }}
          label="Age"
          onChange={(e) => {
            setLaborCosts({ ...laborCosts, fiscalYear: e.target.value })
          }}
        >
          {
            Array.from(laborCosts.data.keys()).map((v) => {
              return (<MenuItem value={v}>第{v}期（{getFiscalPeriodDates(v).toString()}）</MenuItem>)
            })
          }
        </Select>

        <Button style={{ position: "absolute", display: "block", top: "5px", right: "50px" }} variant="contained" color="primary" onClick={() => {
          setOpen(true)
        }}>担当者の追加</Button>
      </div>

      <TableContainer style={{ maxHeight: "calc(100% - 20px)", maxWidth: "calc(100% - 30px)" }}>
        <Table stickyHeader aria-label="a dense table">
          <TableHead>
            <TableRow >
              <TableCell rowSpan={2} className="actions">操作</TableCell>
              <TableCell rowSpan={2} className="jobTitle">役職</TableCell>
              <TableCell rowSpan={2} className="user">担当者</TableCell>
              <TableCell rowSpan={2} className="budgetPrice">予算額</TableCell>
              <TableCell rowSpan={2} className="differencePrice">予算額と実績差</TableCell>
              <TableCell rowSpan={2} className="term">担当期間</TableCell>
              <TableCell rowSpan={2} className="totalOfYear">累計</TableCell>
              <TableCell rowSpan={2} className="totalOEearlyPeriod">前期までの計</TableCell>
              <TableCell rowSpan={2} className="rowTitle"></TableCell>
              <TableCell colspan={7} className="month1st">上　期</TableCell>
              <TableCell colspan={7} className="month2nd">下　期</TableCell>
              <TableCell rowSpan={2} className="memo">備考</TableCell>
            </TableRow>
            <TableRow >
              {monthList1st.map((value, index) => (
                <TableCell className="month1st" key={index}>{value}月</TableCell>
              ))}
              <TableCell className="total1st" key={"total1st"}>上期計</TableCell>
              {monthList2nd.map((value, index) => (
                <TableCell className="month2nd" key={index}>{value}月</TableCell>
              ))}
              <TableCell className="total2nd" key={"total2nd"}>下期計</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {laborCostsData?.details?.map((value, index) => (
              <LaborCostsRow key={laborCosts.fiscalYear + "-" + index} value={value}
                fiscalYear={laborCosts.fiscalYear}
                index={index}
                budgetPrice={index === 0 ? laborCostsData.budgetPrice : null}
                differencePrice={index === 0 ? laborCostsData.differencePrice : null}
                forecastPrice={index === 0 ? laborCostsData.forecastPrice : null}
                enableUp={index > 0}
                enableDown={index < (laborCostsData.details.length - 1)}
                onChangeRow={(row) => {
                  laborCostsData.details[index] = row
                  let tmp = { ...laborCosts }
                  tmp.data.set(laborCostsData.fiscalYear, laborCostsData)
                  calcSummaryPrice(tmp)
                  setLaborCosts(tmp)
                }}
                onDelete={() => {
                  let details = laborCostsData.details.concat()
                  details.splice(index, 1)
                  let tmp = { ...laborCosts }
                  tmp.data.set(laborCostsData.fiscalYear, { ...laborCostsData, details: details })
                  calcSummaryPrice(tmp)
                  setLaborCosts(tmp)
                }}
                onUp={() => {
                  let details = laborCostsData.details.concat()
                  let tmp = details[index]
                  details[index] = details[index - 1]
                  details[index - 1] = tmp
                  let tmp2 = { ...laborCosts }
                  tmp2.data.set(laborCostsData.fiscalYear, { ...laborCostsData, details: details })
                  calcSummaryPrice(tmp2)
                  setLaborCosts(tmp2)
                }}
                onDown={() => {
                  let details = laborCostsData.details.concat()
                  let tmp = details[index]
                  details[index] = details[index + 1]
                  details[index + 1] = tmp
                  let tmp2 = { ...laborCosts }
                  tmp2.data.set(laborCostsData.fiscalYear, { ...laborCostsData, details: details })
                  calcSummaryPrice(tmp2)
                  setLaborCosts(tmp2)
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}


const LaborCostsRow = (props) => {
  const [row, setRow] = useState({ ...props.value, svgBand: createSVGBands(props.value.startDate, props.value.endDate) })

  let monthFirstHalf = row.months.slice(0, 6)
  let monthSecondHalf = row.months.slice(6, 12)

  let totalPlanFirstHalf = monthFirstHalf.reduce((a, b) => { return { workPlanPrice: a.workPlanPrice + b.workPlanPrice } }, { workPlanPrice: 0 }).workPlanPrice
  let totalPlanSecondHalf = monthSecondHalf.reduce((a, b) => { return { workPlanPrice: a.workPlanPrice + b.workPlanPrice } }, { workPlanPrice: 0 }).workPlanPrice
  let totalResultFirstHalf = monthFirstHalf.reduce((a, b) => { return { workResultPrice: a.workResultPrice + b.workResultPrice } }, { workResultPrice: 0 }).workResultPrice
  let totalResultSecondHalf = monthSecondHalf.reduce((a, b) => { return { workResultPrice: a.workResultPrice + b.workResultPrice } }, { workResultPrice: 0 }).workResultPrice

  const fmt = (dt) => {
    dt = parseDate(dt)
    let y = dt.getFullYear()
    let m = ("00" + (dt.getMonth() + 1)).slice(-2)
    let d = ("00" + dt.getDate()).slice(-2)
    return `${y}-${m}-${d}`
  }

  const changeRow = (row) => {
    setRow(row)
    props.onChangeRow(row)
  }

  useEffect(() => {
    setRow({ ...props.value, svgBand: createSVGBands(props.value.startDate, props.value.endDate) })
  }, [props.value])

  useEffect(() => {
    const p = getFiscalPeriodDates(props.fiscalYear)

    let startDate = p.startDate.getTime() > row.startDate.getTime() ? p.startDate : row.startDate
    let endDate = p.endDate.getTime() < row.endDate.getTime() ? p.endDate : row.endDate

    setRow({ ...row, svgBand: createSVGBands(startDate, endDate) })
  }, [props.fiscalYear, row.startDate, row.endDate])

  let caution = props.budgetPrice < props.forecastPrice

  return (
    <React.Fragment>
      <TableRow className="LaborCostsRow LaborCostsRow1st">
        <TableCell rowSpan={3} className="actions">
          <Actions
            enableUp={props.enableUp}
            enableDown={props.enableDown}
            enableDelete={totalResultFirstHalf + totalResultSecondHalf === 0}
            onDelete={() => {
              props.onDelete()
            }}
            onUp={() => {
              props.onUp()
            }}
            onDown={() => {
              props.onDown()
            }}
          />
        </TableCell>
        <TableCell rowSpan={3} className="jobTitle">{row.title}</TableCell>
        <TableCell rowSpan={3} className="user">{row.userName}</TableCell>
        <TableCell rowSpan={3} className="budgetPrice">
          <div className="budgetPriceInput">{addSeparator(props.budgetPrice)}</div>
        </TableCell>
        <TableCell rowSpan={3}
          className="differencePrice"
          style={{ backgroundColor: caution ? "#fdd" : "" }}>
          <div className="differencePriceInput" style={{ color: caution ? "red" : "" }}>{addSeparator(props.differencePrice)}</div>
        </TableCell>
        <TableCell rowSpan={3} className="terms">
          <DateInput className="termFrom" type="date" value={fmt(row.startDate)}
            onChange={(e) => {
              let d = new Date(e.target.value)
              if (d) {
                changeRow({ ...row, startDate: truncateTime(d) })
              }
            }} />
          〜
          <DateInput className="termTo" type="date" value={fmt(row.endDate)}
            onChange={(e) => {
              let d = new Date(e.target.value)
              if (d) {
                changeRow({ ...row, endDate: truncateTime(d) })
              }
            }} />
        </TableCell>

        <TableCell className="totalOfYear"></TableCell>
        <TableCell className="totalOEearlyPeriod"></TableCell>
        <TableCell className="rowTitle"></TableCell>
        {monthFirstHalf.map((value, index) => {
          let ym = value.year + "-" + value.month.toString().padStart(2, '0')
          let img = row.svgBand.get(ym)
          return (
            <TableCell className="month1st" key={index}>
              {img ? img : <span></span>}
            </TableCell>
          )
        })}
        <TableCell className="total1st" key={"total1st"}></TableCell>
        {monthSecondHalf.map((value, index) => {
          let ym = value.year + "-" + value.month.toString().padStart(2, '0')
          let img = row.svgBand.get(ym)
          return (
            <TableCell className="month2nd" key={index}>
              {img ? img : <span></span>}
            </TableCell>
          )
        })}
        <TableCell className="total2nd" key={"total2nd"}></TableCell>
        <TableCell className="memo">        </TableCell>
      </TableRow>

      <TableRow className="LaborCostsRow">
        <TableCell className="totalOfYear"><span>{addSeparator(row.earlyPeriodPlanPrice + totalPlanFirstHalf + totalPlanSecondHalf)}</span></TableCell>
        <TableCell className="totalOEearlyPeriod"><span>{addSeparator(row.earlyPeriodPlanPrice)}</span></TableCell>
        <TableCell className="rowTitle">予定</TableCell>
        {monthFirstHalf.map((value, index) => (
          <TableCell className="month1st" key={index}>
            <NumberInput className="VolInput"
              style={{ backgroundColor: "transparent" }}
              value={value.workPlanPrice}
              onChangeValue={(v) => {
                let l = row.months.concat()
                l[index].workPlanPrice = v
                changeRow({ ...row, months: l })
              }}
            />
          </TableCell>
        ))}
        <TableCell className="total1st" key={"total1st"}><span>{addSeparator(totalPlanFirstHalf)}</span></TableCell>
        {monthSecondHalf.map((value, index) => (
          <TableCell className="month2nd" key={index}>
            <NumberInput className="VolInput"
              style={{ backgroundColor: "transparent" }}
              value={value.workPlanPrice}
              onChangeValue={(v) => {
                let l = row.months.concat()
                l[index + 6].workPlanPrice = v
                changeRow({ ...row, months: l })
              }}
            />
          </TableCell>
        ))}
        <TableCell className="total2nd" key={"total2nd"}><span>{addSeparator(totalPlanSecondHalf)}</span></TableCell>
        <TableCell rowSpan={2} className="memo">
          <LimitedTextarea
            maxLines={2}
            maxCharsPerLine={40}
            initialText={row.description}
            onChange={(text) => {
              changeRow({ ...row, description: text })
            }}
          />

        </TableCell>
      </TableRow>
      <TableRow className="LaborCostsRow">
        <TableCell className="totalOfYear"><span>{addSeparator(row.earlyPeriodResultPrice + totalResultFirstHalf + totalResultSecondHalf)}</span></TableCell>
        <TableCell className="totalOEearlyPeriod"><span>{addSeparator(row.earlyPeriodResultPrice)}</span></TableCell>
        <TableCell className="rowTitle">実績</TableCell>
        {monthFirstHalf.map((value, index) => (
          <TableCell className="month1st" key={index}><span>{addSeparator(value.workResultPrice)}</span></TableCell>
        ))}
        <TableCell className="total1st" key={"total1st"}><span>{addSeparator(totalResultFirstHalf)}</span></TableCell>
        {monthSecondHalf.map((value, index) => (
          <TableCell className="month2nd" key={index}><span>{addSeparator(value.workResultPrice)}</span></TableCell>
        ))}
        <TableCell className="total2nd" key={"total2nd"}><span>{addSeparator(totalResultSecondHalf)}</span></TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const Actions = (props) => {
  let [open, setOpen] = useState(false)
  return (
    <div className="LaborCostsActions">
      <ConfirmationDialog
        dialogTitle="行削除確認"
        bodyText={"行を削除します。よろしいですか？"}
        open={open}
        onOk={() => {
          props.onDelete()
          setOpen(false)
        }}
        onCancel={() => { setOpen(false) }}
        onClose={() => { setOpen(false) }}
      />

      <PopupState variant="popover" popupId="popup-menu">
        {(popupState) => (
          <React.Fragment>
            <button {...bindTrigger(popupState)} ><MoreHorizIcon /></button>

            <Menu {...bindMenu(popupState)}>
              <MenuItem disabled={!props.enableUp} style={{ fontSize: "14px", padding: "5px" }} onClick={() => {
                props.onUp()
                popupState.close()
              }} ><KeyboardArrowUpIcon style={{ fontSize: "14px" }} />一つ上に移動</MenuItem>
              <MenuItem disabled={!props.enableDown} style={{ fontSize: "14px", padding: "5px" }} onClick={() => {
                props.onDown()
                popupState.close()
              }}  ><KeyboardArrowDownIcon style={{ fontSize: "14px" }} />一つ下に移動</MenuItem>
              <MenuItem disabled={!props.enableDelete} style={{ fontSize: "14px", padding: "5px", color: "red" }} onClick={() => {
                setOpen(true)
                popupState.close()
              }} ><DeleteIcon style={{ fontSize: "14px" }} />削除</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState >
    </div>
  )
}

export default LaborCosts
