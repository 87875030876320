import "./ConstStagePlan.css"

import { summary } from './SummaryLogic'
import ExpandControl from './ExpandControl'
import ConfirmationDialog from '../../common/ConfirmationDialog'
import NumberInput from '../../common/NumberInput'
import { calcMonthRange, getInitialVols, addMonth, getDaysInMonthWithinRange } from '../common/ResultManageUtil'

import React, { useState, useEffect } from 'react'

import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material"
import { Chart } from "react-google-charts"

const ConstStagePlan = (props) => {
  const [summaryRows, setSummaryRows] = useState([])

  const [ymRange, setYmRange] = useState([])
  const [graphData, setGraphData] = useState([])

  /*
  * 工程表が更新された際、その工程表に合わせて出来高表をリセットする
  */
  const resetPlan = (constId) => {
    const planDatails = props.value.quote.details.concat()
    let ymRange = calcMonthRange(props.value.quote) // この工事が行われる月の範囲を取得する
    let initialVols = getInitialVols(props.value.quote)
    let planMap = new Map()

    // 各タスクの月別マップを初期化
    planDatails.forEach((task, idx) => {
      if (!task.leaf) {
        task.price = 0
      }
      task = { ...task, idx: idx, vols: new Map(initialVols) }
      planMap.set(task.guid, task)
    })

    // 子タスクのスケジュールから、月別の出来高比率を計算する
    planMap.values().filter((task) => task.leaf)
      .forEach((task) => {
        let total = 0
        let vols = new Map()
        // 月毎にその工程が何日含まれているかをマップし、同時に合計日数を計算する
        ymRange.forEach((ym) => {
          let d = getDaysInMonthWithinRange(task.startDate, task.endDate, ym.year, ym.month)
          total += d
          vols.set(ym.year + "-" + ym.month, d)
        })
        // 月別日数／合計日数でパーセンテージに直す
        let ptotal = 0
        vols.forEach((v, k) => {
          v = Math.round(v / total * 100)
          ptotal += v
          vols.set(k, v)
        })

        // 月別のパーセンテージが100％になるよう、微調整を行う
        adjustPercent(vols, ptotal)

        // 出来高％を積算にし、その％を元に金額を求める
        let s = 0
        vols.forEach((v, k) => {
          s += v
          vols.set(k, { vol: s, price: Math.floor(task.price * s / 100) })
        })

        task.vols = vols

        // 子タスクの金額を、親タスクに足し込む
        addParent(planMap, task.parent, task)

      })

    let newPlan = Array.from(planMap.values()).sort((a, b) => a.idx - b.idx)
    handleChangeStagePlan(newPlan)
  }

  /*
  * 月別のパーセンテージの合計が１００％になるよう、微調整を行う
  */
  const adjustPercent = (vols, ptotal) => {
    let diff = Math.round(100 - ptotal)
    let cnt = 0
    while (diff !== 0 && cnt < 10) {
      vols.forEach((v, k) => {
        if (diff > 0 && v > 0 && v !== 100) {
          vols.set(k, v + 1)
          diff--
        } else if (diff < 0 && v !== 0) {
          vols.set(k, v - 1)
          diff++
        }
      })
      cnt++
    }
  }

  const addParent = (taskMap, parentGuid, task) => {
    if (parentGuid) {
      let parentTask = taskMap.get(parentGuid)
      parentTask.price += task.price
      parentTask.vols.forEach((v, k) => {
        parentTask.vols.set(k, { vol: v.vol, price: v.price + task.vols.get(k).price })
      })
      calcPercent(parentTask)
      taskMap.set(parentGuid, parentTask)

      if (parentTask.parent) {
        addParent(taskMap, parentTask.parent, task)
      }
    }
  }

  const calcPercent = (task) => {
    task.vols.forEach((v, k) => {
      task.vols.set(k, { vol: Math.round(v.price / task.price * 100), price: v.price })
    })
  }

  const getParcent = (row, ym) => {
    let v = { v: 0, p: 0 }
    if (row.vols) {
      v = row.vols.get(ym.year + "-" + ym.month)
    }
    return v
  }

  /*
  * 月別の出来高比率が変更された際、関連する数値を再計算する
  */
  const changePercent = (row, ym, v) => {
    let tasks = props.value.quote.details.concat()

    let key = ym.year + "-" + ym.month
    let val = { vol: v, price: Math.floor(row.price * v / 100) }
    tasks[row.idx].vols.set(key, val)

    handleChangeStagePlan(recalc(tasks, row.parent))
  }

  const changePrice = (row, value) => {
    let tasks = props.value.quote.details.concat()

    tasks[row.idx].price = value

    tasks[row.idx].vols.forEach((v, k) => {
      tasks[row.idx].vols.set(k, { vol: v.vol, price: Math.floor(value * v.vol / 100) })
    })
    handleChangeStagePlan(recalc(tasks, row.parent))
  }

  const displayControl = (tasks) => {
    // 一旦全て表示するモードに
    tasks.forEach((t) => {
      t.display = "table-row"
    })

    // expandChildに従って、子供の行の表示を切り替える
    tasks.forEach((t) => {
      tasks.forEach((t2) => {
        if (t.guid === t2.parent) {
          t2.display = t.expandChild ? "table-row" : "none"
          if (!t.expandChild) {
            t2.expandChild = false
          }
        }
      })
    })
    return tasks.filter((t) => {
      return t.display === "table-row" && !t.discount
    })
  }

  const recalc = (tasks, parentGuid) => {
    let taskMap = new Map()
    let initialVols = getInitialVols(props.value.quote)

    // 各タスクの月別マップを初期化
    tasks.forEach((task, idx) => {
      taskMap.set(task.guid, task)
    })

    let g = parentGuid

    // 親を遡って、金額及び出来高比率をクリア
    while (g) {
      let p = tasks.find((t) => t.guid === g)
      p.price = 0
      p.vols = new Map(initialVols)
      g = p.parent
    }

    tasks.filter((t) => t.parent === parentGuid).forEach((t) => {
      addParent(taskMap, parentGuid, t)
    })

    return Array.from(taskMap.values()).sort((a, b) => a.idx - b.idx)
  }

  const options = {
    hAxis: {
      // 列幅を 50 ピクセルに設定
      width: 50,
    },
    backgroundColor: 'transparent',
    intervals: { style: "points", pointSize: 4 },
    legend: "none",
  }

  const calcGraphData = (constId, sum) => {
    if (!constId) {
      return
    }
    let ymRange = calcMonthRange(props.value.quote) // この工事が行われる月の範囲を取得する
    if (!ymRange || ymRange.length === 0) {
      return
    }

    let prices = new Map()
    let totalPrice = 0

    props.value.quote.details.filter((task) => task.leaf).forEach((task) => {
      if (task.vols) {
        task.vols.forEach((v, k) => {
          if (!prices.has(k)) {
            prices.set(k, 0)
          }

          prices.set(k, prices.get(k) + (v.price ? v.price : 0))
        })
      }
      totalPrice += task.price
    })
    console.log("props.value.quote.result", props.value.quote.result)
    let costPrice = props.value.quote.result.price
    let costVols = new Map()
    let cumulativePrice = 0

    props.value.quote.result.vols.forEach((v, k) => {
      let price = v.price ? v.price : 0
      cumulativePrice += price
      costVols.set(k, { vol: v.vol, price: cumulativePrice })
    })

    let g = []
    g.push([ymRange[0].month + "月", 0, 0])
    prices.forEach((v, k) => {
      let s = costVols.get(k)
      let ym = k.split("-")
      let yearMonth = addMonth({ year: Number(ym[0]), month: Number(ym[1]) }, 1)
      g.push([yearMonth.month + "月", Math.round(v / totalPrice * 100), !s ? null : Math.round(s.price / costPrice * 100)])
    })
    console.log(g)
    setGraphData(g)
  }

  const handleChangeStagePlan = (plan) => {
    const newPlan = { ...props.value.quote, details: plan }
    props.onChangeStagePlan(newPlan)
  }

  useEffect(() => {
    if (props.value.quote) {
      setYmRange(calcMonthRange(props.value.quote))
      if (props.value.quote.details && props.value.quote.details.length > 0) {
        let s = summary(props.value.quote)
        setSummaryRows(s)
        calcGraphData(props.constId, s)
      }
    }
  }, [props.value.quote])

  return (
    <div className="ConstStagePlan">
      <div className="Chart">
        <h4 style={{ textAlign: "center" }}>出来高表</h4>
        {graphData.length > 0 ?
          <Chart
            chartType="LineChart"
            data={[["月", "出来高", "ご請求額"], ...graphData]}
            height="300px"
            options={options}
          />
          : null}
      </div>
      <TableContainer style={{ height: "calc(100vh - 240px)" }}>
        <Table border={1} stickyHeader className="StagePlanTable">
          <colgroup>
            <col />
            <col />
            {ymRange.map((ym, idx) => {
              return (
                <React.Fragment>
                  <col width="60px" />
                  <col width="100px" />
                </React.Fragment>
              )
            })}
          </colgroup>

          <TableHead style={{ position: "sticky", top: 0, zIndex: 4, backgroundColor: "white" }}>
            <TableRow className="HeaderRow" >
              <TableCell className="NameHeader"></TableCell>
              <TableCell className="PriceHeader"></TableCell>
              {ymRange.map((ym, idx) => {
                if (idx !== 0 && ym.month !== 1) {  // 最初の列か年が変わった時のみ年を表示する
                  return (<React.Fragment />)
                }

                // 同一年のカラム数を数える
                let monthCount = ymRange.filter((ym2) => ym2.year === ym.year).length

                return (<TableCell className="Year" colSpan={monthCount * 2} style={{ width: "" + (monthCount * (60 * 100)) + "px" }}>{ym.year}年</TableCell>)
              })}
            </TableRow>
            <TableRow className="HeaderRow" >
              <TableCell className="NameHeader" >
                <ExpandControl style={{ position: "absolute", left: "5px", top: "10px" }} value={props.value.quote.details.find((t) => {
                  return t.expandChild === true
                })} onChangeState={(v) => {
                  let tasks = props.value.quote.details.concat()
                  tasks.filter((t) => !t.leaf).forEach((t) => {
                    t.expandChild = v
                  })
                  handleChangeStagePlan(tasks)
                }} />
                工事分類
              </TableCell>
              <TableCell className="PriceHeader" >見積金額</TableCell>
              {ymRange.map((ym) => (
                <TableCell className="Month" colSpan={2}>{ym.month}月</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              displayControl(props.value.quote.details).map((row) => (
                <TableRow className="BodyRow" key={row.id} id={row.guid} style={{ display: row.display }}>
                  <TableCell className="Name">
                    <div style={{ display: "inline-block", width: "20px", marginLeft: "" + ((row.level - 1) * 10 + 5) + "px" }}>
                      {(!row.leaf) ? <ExpandControl value={row.expandChild} onChangeState={(v) => {
                        let tasks = props.value.quote.details.concat()
                        tasks[row.idx].expandChild = v
                        handleChangeStagePlan(tasks)
                      }} /> : null}
                    </div>
                    {row.name}</TableCell>
                  <TableCell className="Price">
                    <NumberInput className="PriceInput"
                      style={{ backgroundColor: row.leaf ? "#ffc" : "transparent" }}
                      value={row.price}
                      onChangeValue={(v) => { changePrice(row, v) }}
                      readOnly={!row.leaf} />
                  </TableCell>
                  {ymRange.map((ym) => {
                    let vol = getParcent(row, ym)
                    return (
                      <React.Fragment>
                        <TableCell className="Percent">
                          <div style={{ display: "flex", alignItems: "baseline", }}>
                            <NumberInput className="VolInput"
                              style={{ backgroundColor: row.leaf ? "#ffc" : "transparent" }}
                              value={vol ? vol.vol : ""}
                              onChangeValue={(v) => { changePercent(row, ym, v) }}
                              readOnly={!row.leaf}
                            />
                            <div>{vol && Number.isInteger(vol.vol) ? "%" : ""}</div>
                          </div>
                        </TableCell>
                        <TableCell className="PriceParMonth">
                          {vol && vol.price ? vol.price.toLocaleString("ja-JP") : ""}
                        </TableCell>
                      </React.Fragment>
                    )
                  }
                  )}
                </TableRow>
              ))
            }
            {summaryRows.map((row) => (
              <TableRow className="SummaryRow" key={row.name} id={row.name} style={{ display: "table-row" }}>
                <TableCell className="Name">
                  {row.name}</TableCell>
                <TableCell className="Price">
                  <NumberInput className="PriceInput"
                    style={{ backgroundColor: !row.readOnly ? "#ffc" : "transparent" }}
                    value={row.price}
                    readOnly={row.readOnly}
                    onChangeValue={(value) => {
                      let result = { ...props.value.quote.result, price: value }
                      calcPercent(result)
                      let v = { ...props.value.quote, result: result }
                      props.onChangeStagePlan(v)
                    }}
                  />
                </TableCell>
                {ymRange.map((ym) => {
                  let vol = getParcent(row, ym)
                  return (
                    <React.Fragment>
                      <TableCell className="Percent">
                        <div style={{ display: "flex", alignItems: "baseline", }}>
                          <NumberInput className="VolInput"
                            style={{ backgroundColor: "transparent" }}
                            value={vol ? vol.vol : ""}
                            readOnly={true}
                          />
                          <div>{vol && Number.isInteger(vol.vol) ? "%" : ""}</div>
                        </div>
                      </TableCell>
                      <TableCell className="PriceParMonth">
                        <NumberInput className="PriceInput"
                          style={{ backgroundColor: !row.readOnly ? "#ffc" : "transparent" }}
                          value={vol && vol.price ? vol.price : ""}
                          readOnly={row.readOnly}
                          onChangeValue={(value) => {
                            let result = { ...props.value.quote.result }

                            result.vols.set(ym.year + "-" + ym.month, { price: value })
                            calcPercent(result)

                            let v = { ...props.value.quote, result: result }
                            props.onChangeStagePlan(v)
                          }}
                        />
                      </TableCell>
                    </React.Fragment>
                  )
                }
                )}
              </TableRow>
            ))
            }
          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmationDialog
        dialogTitle="自動割り当て"
        bodyText={"工程表が更新されました。出来高率の自動割り当てを行いますか？"}
        open={props.resetPlan}
        onOk={() => {
          resetPlan(props.constId)
          props.onResetPlan()
        }}
        onCancel={() => { props.onResetPlan() }}
        onClose={() => { props.onResetPlan() }}
      />

    </div>
  )
}

export default ConstStagePlan
