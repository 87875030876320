import './AllPhotos.css'

import DragItem from './DragItem'
import Thumbnail from '../common/Thumbnail'
import { formatDate } from '../utils/Utils'
import PrintSetting from './PrintSetting'
import { selectPhotos } from './SelectControl'

import { errorState } from '../hooks/ErrorState'
import { constListData } from '../hooks/ConstListData'
import { constPhotoStateFamily } from '../hooks/ConstPhotoState'
import { photoSelectState } from '../hooks/PhotoSelectState'

import PdfService from '../../services/PdfService'
import PhotoDownloader from '../common/PhotoDownloader'

import React, { useState, useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Rnd } from 'react-rnd'
import { Card, CardHeader, CardContent, Avatar, IconButton } from '@mui/material'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import CloseIcon from '@mui/icons-material/Close'
import DownloadIcon from '@mui/icons-material/Download'

const MSEC_OF_DAY = 24 * 60 * 60 * 1000

const AllPhotos = (props) => {
    const atom = constPhotoStateFamily(props.data.key)
    const [state, setState] = useRecoilState(atom)
    const [detailDatas, setDetailDatas] = useState([])
    const [printSetting, setPrintSetting] = useState(false)
    const [windowState, setWindowState] = useState({ x: 20, y: 40, width: 600, height: 400 })
    const [photoSelect, setPhotoSelect] = useRecoilState(photoSelectState)
    const constList = useRecoilValue(constListData) // 案件一覧データ（マップ構造）
    const setError = useSetRecoilState(errorState) // エラーメッセージ表示用

    const getData = () => {
        return state
    }

    const moveFront = () => {
        props.onClick()
    }

    const removeData = (photos) => {
        const photoKeys = [...photos.keys()]

        let newPhotos = state.photos.map((photo) => {
            // 削除対象に含まれていれば
            return (photoKeys.includes(photo.photoId)) ? { ...photo, deleted: true } : { ...photo }
        })

        setState({ ...state, photos: newPhotos })
    }

    const restoreData = (trashPhotos) => {
        setState({
            ...state, photos: state.photos.map((photo) => { return (trashPhotos.has(photo.photoId) ? trashPhotos.get(photo.photoId) : photo) })
        })
    }
    const downloadPhotoIds = photoSelect.photos.size > 0 ?
        Array.from(photoSelect.photos.keys()) :
        state.photos.filter((p) => { return !p.deleted }).map((p) => { return p.photoId })

    const selectPhotoByDate = (date) => {
        let photos = detailDatas.filter((row) => {
            return row.date === date
        }).map((p) => { return p.value })

        setPhotoSelect(selectPhotos(photoSelect, "allPhotos", photos))
    }

    let onDragPhoto = (dragState, photo) => {
        // TODO:選択された写真以外でドラッグが開始された場合の処理。なぜかここでのphotoSelectが空になり、うまく動かない。
        // let ps = selectPhoto(photoSelect, "allPhotos", photo)
        // setPhotoSelect(ps)
    }

    useEffect(() => {
        let objects = []
        state.photos
            .filter((v) => {
                return !v.deleted
            })
            .map(v => {
                let tm = Date.parse(v.day)
                return { ...v, key: (tm / (MSEC_OF_DAY) | 0) * MSEC_OF_DAY }
            })
            .sort((p1, p2) => { return p2.key - p1.key }) // 降順
            .reduce((prev, current) => {    // 日付が前の写真と違う場合、日付を挿入する為 reduceを使用
                if (prev.key !== current.key) {
                    objects.push({ type: "date", value: current.day })
                }
                objects.push({ type: "photo", value: current, date: current.day })
                return current
            }, { day: null })

        setDetailDatas(objects)

        props.onWindowStateChanges()
    }, [state.photos])

    useEffect(() => {
        setWindowState({ x: 20, y: 40, width: 600, height: 400 })
    }, [props.data])

    useEffect(() => {
        props.connector.allPhotos = {
            getData: getData,
            removeData: removeData,
            restoreData: restoreData,
            moveFront: moveFront,
        }
    }, [props, state, detailDatas])

    return (
        <span className="AllPhotos">
            <PrintSetting open={printSetting}
                value={state}
                onClose={(event) => {
                    setPrintSetting(false)
                }}
                onUpdateSetting={(setting) => {
                    setState(setting)
                    setPrintSetting(false)
                }} />
            <Rnd size={{ width: windowState?.width, height: windowState?.height }}
                position={{ x: windowState?.x, y: windowState?.y }}
                onDragStop={(e, d) => {
                    setWindowState({ ...windowState, x: d.x, y: d.y })
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setWindowState({
                        ...windowState, width: ref.style.width,
                        height: ref.style.height, ...position
                    })
                }}
                onClick={(e) => {
                    props.onClick(e)
                }}
                style={{
                    visibility: state.window ? 'visible' : 'hidden', position: "absolute", border: "1px solid #888", overflow: "hidden"
                }} >
                <Card style={{ position: "relative", maxHeight: "100%", minHeight: "100%", display: "flex", flexDirection: "column" }}>
                    <CardHeader
                        avatar={
                            <Avatar>
                                全
                            </Avatar>
                        }
                        action={
                            <div>
                                <IconButton
                                    onClick={() => {
                                        let project = constList.get(props.constId)
                                        let data = { ...state, photos: state.photos.filter((p) => { return !p.deleted }) }  // 削除データを除く
                                        let printData = { ...data, constId: project.constId, constName: project.name, termFrom: new Date(project.termFrom), termTo: new Date(project.termTo) }
                                        PdfService.download("constphoto", printData)
                                            .catch(error => {
                                                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                                            })
                                    }}
                                    onTouchEnd={() => {
                                        let project = constList.get(props.constId)
                                        let data = { ...state, photos: state.photos.filter((p) => { return !p.deleted }) }  // 削除データを除く
                                        let printData = { ...data, constId: project.constId, constName: project.name, termFrom: new Date(project.termFrom), termTo: new Date(project.termTo) }
                                        PdfService.download("constphoto", printData)
                                            .catch(error => {
                                                setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
                                            })
                                    }}
                                >
                                    <PictureAsPdfIcon />
                                </IconButton>
                                <PhotoDownloader constId={props.constId} photoIds={downloadPhotoIds} />
                                <IconButton ariaLabel="settings"
                                    onClick={(event) => {
                                        setPrintSetting({ ...printSetting, open: true })
                                        event.stopPropagation()
                                    }}
                                    onTouchEnd={(event) => {
                                        setPrintSetting({ ...printSetting, open: true })
                                        event.stopPropagation()
                                    }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <IconButton ariaLabel="settings"
                                    onClick={() => {
                                        setState({ ...state, window: false })
                                    }}
                                    onTouchEnd={() => {
                                        setState({ ...state, window: false })
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        }
                        title={state.title}
                    />
                    <CardContent style={{ overflowY: "auto", bottom: 0 }}
                        onDrag={(event) => { event.stopPropagation() }}
                        onMouseDown={(event) => { event.stopPropagation() }}
                        onMouseMove={(event) => { event.stopPropagation() }}
                        onMouseUp={(event) => { event.stopPropagation() }}
                    >
                        <div id="AllPhotos" style={{ display: "flex", flexWrap: "wrap", width: "100%", }}>
                            {
                                detailDatas.map((row) => {
                                    if (row.type === "date") {
                                        return (
                                            <div class="SelectPhotoByDate" style={{ textAlign: "left", width: "100%", borderBottom: "3px solid #888" }}
                                                onClick={(event) => {
                                                    selectPhotoByDate(row.value)
                                                    event.stopPropagation()
                                                }}
                                            >
                                                {formatDate(row.value)}
                                                <span class="HoverText">日付の写真を全て選択する</span>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <DragItem
                                                connector={props.connector}
                                                onDragPhoto={(dragState) => {
                                                    onDragPhoto(dragState, row.value)
                                                }}
                                            >
                                                <Thumbnail key={row.value.photoId} cancelPopup={true} value={row.value} selectable={true} type={"allPhotos"} size="s" popperSize="m" />
                                            </DragItem>
                                        )
                                    }
                                })
                            }
                            <div id="allPhotosEnd" style={{ width: "100%" }} />
                        </div>
                    </CardContent>
                </Card>
            </Rnd>
        </span >
    )
}

export default AllPhotos