import './ConstList.css'

import FinancialStatementService from '../../services/FinancialStatementService'
import ExcelService from '../../services/ExcelService'

import ConfirmationDialog from '../common/ConfirmationDialog'
import MonthPicker from '../common/MonthPicker'
import ConstListRow from './ConstListRow'
import { calcRate } from '../utils/Utils'

import { financialStatementPrintState } from '../hooks/FinancialStatementPrintState'
import { financialStatementSaveState } from '../hooks/FinancialStatementSaveState'
import { messageState } from '../hooks/MessageState'
import { errorState } from '../hooks/ErrorState'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

const headCells = [
  { id: 'constId', numeric: false, disablePadding: true, className: "rh0", label: "工番" },
  { id: 'name', numeric: false, disablePadding: true, className: "rh1", label: "工事名称" },
  { id: 'term', numeric: false, disablePadding: true, className: "constTerm", label: "工期" },
  { id: 'profit', numeric: false, disablePadding: true, className: "price", label: "報告粗利" },
  { id: 'profitRate', numeric: false, disablePadding: true, className: "rate", label: "予定粗利率" },
  { id: 'profitWithoutGroupCorp', numeric: false, disablePadding: true, className: "price", label: "報告粗利(ｴﾑﾀｲﾄ除)" },
  { id: 'profitRateWithoutGroupCorp', numeric: false, disablePadding: true, className: "rate", label: "予定粗利率" },
  { id: 'salesOrderPriceMain', numeric: true, disablePadding: true, className: "price", label: "本体受注" },
  { id: 'salesOrderPrice01', numeric: true, disablePadding: true, className: "price", label: "追加①受注" },
  { id: 'salesOrderPrice02', numeric: true, disablePadding: true, className: "price", label: "追加②受注" },
  { id: 'salesOrderPrice03', numeric: true, disablePadding: true, className: "price", label: "追加③受注" },
  { id: 'salesOrderPrice04', numeric: true, disablePadding: true, className: "price", label: "追加④受注" },
  { id: 'salesOrderPrice05', numeric: true, disablePadding: true, className: "price", label: "追加⑤以降受注" },
  { id: 'salesOrderPrice', numeric: true, disablePadding: true, className: "price", label: "総受注額" },
  { id: 'budgetPrice', numeric: true, disablePadding: true, className: "price", label: "実行予算" },
  { id: 'paidPrice', numeric: true, disablePadding: true, className: "price", label: "既払" },
  { id: 'paidPriceRate', numeric: true, disablePadding: true, className: "rate", label: "出来高" },
  { id: 'balancePaymentPrice', numeric: true, disablePadding: true, className: "price", label: "予算残高" },
  { id: 'change01', numeric: true, disablePadding: true, className: "price", label: "増減①" },
  { id: 'change02', numeric: true, disablePadding: true, className: "price", label: "増減②" },
  { id: 'totalPayablePrice', numeric: true, disablePadding: true, className: "price", label: "未払総額" },
  { id: 'totalPaymentPrice', numeric: true, disablePadding: true, className: "price", label: "(予定)支払総額" },
  { id: 'thisMonthRate', numeric: true, disablePadding: true, className: "rate", label: "当月出来高" },
  { id: 'thisMonthPayment', numeric: true, disablePadding: true, className: "price", label: "支払予定額" },
  { id: 'balancePrice', numeric: true, disablePadding: true, className: "price", label: "残高" },
  { id: 'totalProfitResult', numeric: true, disablePadding: true, className: "price", label: "最終粗利" },
  { id: 'totalProfitResultRate', numeric: true, disablePadding: true, className: "rate", label: "最終粗利率" },
  { id: 'upDown', numeric: true, disablePadding: true, className: "rate", label: "" },
]

const setToEndOfDay = (date) => {
  date.setHours(23, 59, 59, 999)
  return date
}

const getInitialCondition = () => {
  let today = new Date()
  let targetDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  return { today: setToEndOfDay(today), targetDate: setToEndOfDay(targetDate) }
}

const ConstList = (props) => {
  const [list, setList] = useState([])
  const [condition, setCondition] = useState(getInitialCondition)
  const [filteredList, setFilteredList] = useState([])
  const [outputExcel, setOutputExcel] = useRecoilState(financialStatementPrintState)
  const [save, setSave] = useRecoilState(financialStatementSaveState)
  const [focusRow, setFocusRow] = useState(-1)

  const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
  const setMessage = useSetRecoilState(messageState) // メッセージ表示用

  useEffect(() => {
    FinancialStatementService.find({
      process: props.searchCondition.process,
      salesStaff: props.searchCondition.salesStaff,
      constStaff: props.searchCondition.constStaff,
      targetDate: condition.targetDate,
      today: condition.today
    })
      .then(
        response => {
          // state更新
          setList(response.data.objects)
        },
        error => {
          setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        }
      ).catch(error => {
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      })
  }, [condition, props.searchCondition])

  useEffect(() => {
    let consts = props.constList.map((v) => v.constId)

    let filteredList = list.filter((v) => {
      return consts.includes(v.constId)
    }).map((v) => {
      return calcRow(v)
    })

    setFilteredList(filteredList)
  }, [props.constList, list])

  useEffect(() => {
    if (outputExcel) {
      if (filteredList.length === 0) {
        setError({ msg: `出力対象のデータはありません。`, open: true, title: 'エラー' })
      } else {
        exportExcel()
      }
      setOutputExcel(false)
    }
  }, [outputExcel])

  const hanbleSave = (list) => {
    FinancialStatementService.saveProfits(list.map((v) => {
      return {
        constId: v.constId,
        salesOrderPrice: v.salesOrderPrice,
        profit: v.profit,
        profitRate: v.profitRate,
        profitWithoutGroupCorp: v.profitWithoutGroupCorp,
        profitRateWithoutGroupCorp: v.profitRateWithoutGroupCorp,
      }
    }))
      .then(
        response => {
          setMessage({ msg: `保存を完了しました。`, open: true, title: '出来高表' })
          setSave(false)
        },
        error => {
          setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        }
      ).catch(error => {
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      })
  }

  const exportExcel = () => {
    ExcelService.download("financialStatement", { date: condition.targetDate, data: filteredList })
      .catch(error => {
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      })
  }

  const handleChangeMonth = (year, month) => {
    let targetDate = setToEndOfDay(new Date(year, month, 0))
    let today = setToEndOfDay(new Date())

    // 過去日がターゲットなら、”当日”も同一日を指定
    if (targetDate < new Date()) {
      today = setToEndOfDay(new Date(year, month - 1, 0))
    }

    setCondition({ today: today, targetDate: targetDate })
  }

  const handleChange = (row) => {

    let newList = list.map((v) => {
      if (v.constId === row.constId) {
        return { ...row }
      } else {
        return { ...v }
      }
    })
    setList(newList)
  }

  const calcRow = (v) => {
    let totalPayablePrice = (v.budgetPrice - v.paidPrice) + v.change01 + v.change02 // 未払総計

    // 予定粗利率は本体工事のみを見る？
    // let profitRate = calcRate(v.salesOrderPriceMain - v.budgetPrice, v.salesOrderPriceMain) // 予定粗利率
    // let profitRateWithoutGroupCorp = calcRate(v.salesOrderPriceMain - v.budgetPriceWithoutGroupCorp, v.salesOrderPriceMain) // 予定粗利率（エムタイト外）
    //    let profitRate = calcRate(v.salesOrderPrice - v.budgetPrice, v.salesOrderPrice) // 予定粗利率
    //    let profitRateWithoutGroupCorp = calcRate(v.salesOrderPrice - v.budgetPriceWithoutGroupCorp, v.salesOrderPrice) // 予定粗利率（エムタイト外）


    let totalPaymentPrice = v.paidPrice + totalPayablePrice // (予定)総支払額

    let paidPriceRate = calcRate(v.paidPrice, totalPaymentPrice)  // 出来高

    //      let thisMonthPayment = v.thisMonthRate === null ? null : Math.round(totalPaymentPrice * (v.thisMonthRate - paidPriceRate))  // 支払予定額
    let thisMonthPayment = v.paymentDueThisMonth  // 支払予定額
    let thisMonthRate = calcRate(thisMonthPayment, totalPaymentPrice) // 当月出来高
    let balancePrice = totalPaymentPrice - (thisMonthPayment + v.paidPrice) // 残高

    let totalProfitResult = v.salesOrderPrice - totalPaymentPrice // 最終粗利
    let totalProfitResultRate = calcRate(totalProfitResult, v.salesOrderPrice) // 最終粗利率

    return {
      constId: v.constId,
      constName: v.constName,
      constructionStartDate: v.constructionStartDate,
      scheduledCompletionDate: v.scheduledCompletionDate,
      actualCompletionDate: v.actualCompletionDate,

      // 報告当初粗利
      profit: v.profit,
      profitRate: v.profitRate,
      profitWithoutGroupCorp: v.profitWithoutGroupCorp,
      profitRateWithoutGroupCorp: v.profitRateWithoutGroupCorp,

      salesOrderPriceMain: v.salesOrderPriceMain,
      salesOrderPrice01: v.salesOrderPrice01,
      salesOrderPrice02: v.salesOrderPrice02,
      salesOrderPrice03: v.salesOrderPrice03,
      salesOrderPrice04: v.salesOrderPrice04,
      salesOrderPrice05: v.salesOrderPrice05,

      salesOrderPrice: v.salesOrderPrice, // 受注総額
      budgetPrice: v.budgetPrice,// 実行予算
      paidPrice: v.paidPrice, // 既払い
      paidPriceRate: paidPriceRate,  // 出来高
      balancePaymentPrice: v.budgetPrice - v.paidPrice, // 予算残高

      // ここに増減が入る
      change01: v.change01,
      change02: v.change02,

      totalPayablePrice: totalPayablePrice, // 未払総計
      totalPaymentPrice: totalPaymentPrice, // （予定）支払総額
      thisMonthRate: thisMonthRate,  // 当月出来高
      thisMonthPayment: thisMonthPayment,  // 支払予定額

      balancePrice: balancePrice,  // 残高

      totalProfitResult: totalProfitResult, // 最終粗利
      totalProfitResultRate: totalProfitResultRate, // 最終粗利率

      upDown: getUpDown({ profitRate: v.profitRate, totalProfitResultRate: totalProfitResultRate })
    }
  }

  const getUpDown = (v) => {
    if (!v.profitRate || !v.totalProfitResultRate) {
      return 0
    }

    const profitRate = (Number(v.profitRate) * 100).toFixed(1)
    const totalProfitResultRate = (Number(v.totalProfitResultRate) * 100).toFixed(1)

    console.log(profitRate, totalProfitResultRate)

    if (Number(profitRate) > Number(totalProfitResultRate)) {
      return -1
    } else if (Number(profitRate) < Number(totalProfitResultRate)) {
      return 1
    }
    return 0
  }

  return (
    <div className="ConstList">
      <ConfirmationDialog
        dialogTitle="当初粗利情報の保存"
        bodyText={"編集結果を保存します。よろしいですか？"}
        open={save}
        onOk={() => {
          hanbleSave(filteredList)
          setSave(false)
        }}
        onCancel={() => { setSave(false) }}
        onClose={() => { setSave(false) }}
      />
      <div style={{ display: "block", marginLeft: "10px" }}>
        対象月：<MonthPicker onChangeMonth={(year, month) => {
          // year, monthから、その月の月末日をDateとして取得
          handleChangeMonth(year, month, setCondition)
        }} />

      </div>
      <TableContainer style={{ maxHeight: "calc(100% - 70px)" }}>
        <Table stickyHeader aria-label="a dense table">
          <TableHead>
            <TableRow style={{ backgroundColor: "white" }}>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  className={headCell.className}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              filteredList.map((v, i) => {
                return (
                  <ConstListRow
                    key={i}
                    focus={i == focusRow}
                    value={v}
                    onChangeRow={(row) => {
                      handleChange(row)
                    }}
                    onClick={(e) => {
                      setFocusRow(i)
                    }}
                    onFocus={(e) => {
                      setFocusRow(i)
                    }}
                  />
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ConstList
