import './App.css';

import SessionHandler from './components/common/SessionHandler'
import Tasks from './components/Tasks'
import Report from './components/Report'
import ReportList from './components/ReportList'
import ConstPhoto from './components/ConstPhoto'
import WorkReportApprove from './components/WorkReportApprove'
import ConstReportDiscussion from './components/ConstReportDiscussion'
import ConstReportList from './components/ConstReportList'
import WorkReportList from './components/WorkReportList'
import ResultManage from './components/ResultManage'
import FinancialStatements from './components/FinancialStatements'
import LaborCostList from './components/LaborCostList'

import { VERSION } from './Version'

import SystemSetting from './components/SystemSetting'
import SideMenu from './components/common/SideMenu'
import Error from './components/common/Error'
import Message from './components/common/Message'

import SystemService from './services/SystemService'

import { errorState } from './components/hooks/ErrorState'
import { messageState } from './components/hooks/MessageState'
import { taxInfoState } from './components/hooks/TaxInfoState'
import { themeState } from './components/hooks/ThemeState'
import { versionState } from './components/hooks/VersionState'

import React, { useCallback, useState, useEffect } from 'react'
import { createTheme, ThemeProvider } from "@mui/material"
import * as colors from "@mui/material/colors"
import { useRecoilState, useSetRecoilState } from 'recoil'
import { Routes, HashRouter, Route, Navigate } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: colors.indigo[500],
    },
    secondary: {
      //main: colors.yellow[900],
      main: colors.pink[500],
    },
    typography: {
      htmlFontSize: 12,
      fontSize: 12,
      button: {
        textTransform: "none", // ボタンのラベルが小文字から大文字に変換されてしまうのを防ぐ
      },
    },
  },
  components: {
    // セレクトボックス
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: "5px",
          paddingBottom: "5px",
        },
      },
    },
    // input
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingTop: "5px !important",
          paddingBottom: "5px !important",
        }
      },
    },
    // button
    MuiButton: {
      // variant: 'contained',
      defaultProps: {
        // Some CSS
        variant: 'contained',
      },
    },
  },
});

const App = () => {
  const useWindowDimensions = () => {
    const isClient = typeof window === 'object'
    const getWindowDimensions = useCallback(() => {
      return {
        width: isClient ? window?.innerWidth : 0,
        height: isClient ? window?.innerHeight : 0
      }
    }, [isClient])
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    useEffect(() => {
      const onResize = () => {
        setWindowDimensions(getWindowDimensions())
      }
      const onOrientationChange = () => {
        setWindowDimensions(getWindowDimensions())
      }
      window.addEventListener('resize', onResize)
      window.addEventListener('orientationchange', onOrientationChange)
      return () => {
        window.removeEventListener('resize', onResize)
        window.removeEventListener('orientationchange', onOrientationChange)
      }
    }, [getWindowDimensions])
    return windowDimensions
  }

  const { height, width } = useWindowDimensions()

  return (
    <div className="App" id="App">
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <SessionHandler >
            <MessageHandler />
            <SharedDataLoader />
            <div style={{ height: height + "px", width: width + "px", position: "relative" }}>
              <HashRouter>
                <SideMenu />
                <Routes>
                  <Route exact path="/" element={<Navigate to="/task" />}></Route>
                  <Route exact path="/task" element={<Tasks key="task" />}></Route>
                  <Route exact path="/report" element={<Report key="report" />}></Route>
                  <Route exact path="/photo" element={<ConstPhoto key="constPhoto" />}></Route>
                  <Route exact path="/reportlist" element={<ReportList key="reportList" />} ></Route>
                  <Route exact path="/workapprove" element={<WorkReportApprove key="workReportApprove" />} ></Route>
                  <Route exact path="/constdiscus" element={<ConstReportDiscussion key="constReportDiscussion" />}></Route>
                  <Route exact path="/constlist" element={<ConstReportList key="ConstReportList" />}></Route>
                  <Route exact path="/worklist" element={<WorkReportList key="WorkReportList" />}></Route>
                  <Route exact path="/resultmanage" element={<ResultManage key="ResultManage" />}></Route>
                  <Route exact path="/setting" element={<SystemSetting key="setting" />}></Route>
                  <Route exact path="/financial" element={<FinancialStatements key="financial" />}></Route>
                  <Route exact path="/laborcosts" element={<LaborCostList key="laborcosts" />}></Route>
                </Routes>
              </HashRouter>
            </div>
          </SessionHandler>
        </RecoilRoot>
      </ThemeProvider>
    </div >
  )
}

const MessageHandler = () => {
  const [error, setError] = useRecoilState(errorState) // エラーメッセージ表示用
  const [message, setMessage] = useRecoilState(messageState) // メッセージ表示用
  return (
    <div>
      <Message
        title={message.title}
        msg={message.msg}
        open={message.open}
        style={message.style}
        textStyle={message.textStyle}
        onOk={() => {
          setMessage({ msg: null, open: false })
        }} />
      <Error
        title={error.title}
        msg={error.msg}
        open={error.open}
        style={error.style}
        textStyle={error.textStyle}
        onOk={() => {
          setError({ msg: null, open: false })
        }} />
    </div>
  )
}

const SharedDataLoader = () => {
  const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
  const setTaxInfo = useSetRecoilState(taxInfoState) // 税率情報のロード
  const setTheme = useSetRecoilState(themeState) // テーマのロード
  const setVersion = useSetRecoilState(versionState) // サーバーのバージョン

  const getTaxInfo = () => {
    SystemService.getTaxs()
      .then(
        response => {
          response.data.forEach(row => {
            row.date = new Date(row.date)
          })
          setTaxInfo(response.data)
        },
        error => {
          setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        }
      )
  }

  const getVersion = () => {
    SystemService.getVersion()
      .then(
        response => {
          setVersion({ server: response.data, client: VERSION })
        },
        error => {
          setVersion({ server: "-", client: VERSION })
        }
      )
      .catch((error) => {
        setVersion({ server: "-", client: VERSION })
      })
  }

  const getTheme = () => {
    SystemService.getTheme()
      .then(
        response => {
          setTheme(response.data)
        },
        error => {
          setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        }
      )
  }

  useEffect(() => {
    getTaxInfo()
    getTheme()
    getVersion()
  }, [])
  return (
    <React.Fragment />
  )
}

export default App
