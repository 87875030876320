import './ConstManagePhotoList.css'

import { imageUrl, thumbUrl } from '../../../services/Config'
import Thumbnail from '../../common/Thumbnail'
import { formatDate } from '../../utils/Utils'
import ConstPhotoService from '../../../services/ConstPhotoService'
import { errorState } from '../../hooks/ErrorState'
import { messageState } from '../../hooks/MessageState'

import React, { useState, useEffect } from 'react'
import { Menu, MenuItem } from '@mui/material'

import { useSetRecoilState } from 'recoil'
import Viewer from 'react-viewer'

const ConstManagePhotoList = (props) => {
  const setError = useSetRecoilState(errorState) // エラーメッセージ表示用
  const setMessage = useSetRecoilState(messageState) // メッセージ表示用

  const [contextMenu, setContextMenu] = useState(null)
  const [photos, setPhotos] = useState([])
  const [view, setView] = useState({ index: null, images: [] })
  const [projectPhotoId, setProjectPhotoId] = useState(null)

  const displayMessage = (title, msg) => {
    setMessage({ msg: msg, open: true, title: title })
  }
  const loadPhotos = (constId) => {
    ConstPhotoService.load(constId).then(
      response => {
        response.data.allPhotos.photos.sort((a, b) => {
          // day, dateTimeOriginalにより並び替え
          if (a.day < b.day) return -1
          if (a.day > b.day) return 1
          if (a.dateTimeOriginal < b.dateTimeOriginal) return -1
          if (a.dateTimeOriginal > b.dateTimeOriginal) return 1
          return 0
        })
        setPhotos(response.data.allPhotos.photos)
      },
      error => {
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      }
    ).catch(error => {
      setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
    })
  }

  const saveConstPhoto = (constId, photoId) => {
    ConstPhotoService.setProjectPhoto(constId, photoId)
      .then(
        response => {
          displayMessage('工事写真', `景観写真の変更を完了しました。`)
        },
        error => {
          setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
        }
      ).catch(error => {
        setError({ msg: "通信エラー：" + error.message, open: true, title: "エラーが発生しました。" })
      })
  }
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : null,
    )
  }
  const handleClose = () => {
    setContextMenu(null)
  }

  useEffect(() => {
    if (props.constId == null) return
    loadPhotos(props.constId)
  }, [props.constId])

  let day = null

  return (
    <div className="ConstManagePhotoList">
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={() => {
          saveConstPhoto(props.constId, projectPhotoId)
          handleClose()
        }}>景観写真に設定</MenuItem>
      </Menu>

      <Viewer
        visible={view.index !== null}
        downloadable={true}
        loop={false}
        onClose={() => { setView({ images: [], index: null }) }}
        images={view.images}
        activeIndex={view.index}
      />
      {
        photos.map((photo, idx) => {
          let dateLabel = photo.day !== day ? (
            <div style={{ marginTop: "5px" }}>
              {formatDate(photo.day)}
              <hr style={{ margin: "0px" }} />
            </div>
          ) : null

          day = photo.day
          return (
            <React.Fragment key={photo.photoId}>
              {dateLabel}
              <span onContextMenu={(e) => {
                setProjectPhotoId(photo.photoId)
                handleContextMenu(e)
              }

              } style={{ cursor: 'context-menu' }}>
                <Thumbnail style={{ display: "inline-block" }} index={idx} value={photo} size="s" onClickPicture={(photoIndex) => {
                  let index = 0
                  const imgs = photos.filter((p) => {
                    return p.day === photo.day
                  }).map((p, idx) => {
                    if (p.photoId === photo.photoId) {
                      index = idx
                    }
                    return { src: thumbUrl + "/l/?photoId=" + p.photoId, alt: '', downloadUrl: imageUrl + "?photoId=" + p.photoId }
                  })
                  setView({ index: index, images: imgs })
                }} />
              </span>
            </React.Fragment>
          )
        })
      }
    </div>
  )
}

export default ConstManagePhotoList
